import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';
import { WarCustomModule } from 'src/app/shared/war-custom/war-custom.module';

// Pipes
import { ReplacePipe } from '../../pipes/replace.pipe';

import { ListMachinesPageComponent } from './components/list-machines-page/list-machines-page.component';
import { ListMachineEventsPageComponent } from './components/list-machine-events-page/list-machine-events-page.component';
import { MachinePageComponent } from './components/machine-page/machine-page.component';
import { MachineEventComponent } from './components/machine-event/machine-event.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
        MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        FormsModule, ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule,
        WarCustomModule
    ],
    declarations: [
        ReplacePipe,
        ListMachinesPageComponent,
        ListMachineEventsPageComponent,
        MachinePageComponent,
        MachineEventComponent
    ],
    exports: [
        ListMachinesPageComponent,
        ListMachineEventsPageComponent,
        MachinePageComponent,
        MachineEventComponent
    ]
})
export class MachinesModule {
}
