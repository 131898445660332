import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigService } from './app-config.service';
import { LocaleService } from './locale.service';

import { environment } from '../../environments/environment';

import { UserProfile } from '../models/user';

@Injectable()
export class AuthService implements CanActivate {
    BACKENDURL: string;

    public userProfilePromise: any;

    private _authenticated;
    private _userProfile: UserProfile;

    constructor(
        private router: Router,
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private localeService: LocaleService
    ) {
        this.BACKENDURL = this.appConfigService.config.machineryBackendUrl;
        this.loginWithToken(this.accessToken);
    }

    // Add this service to router as the canActivate gate.
    // Router will call this function for each route thus protected.
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AuthService::canActivate()', route.routeConfig.path);
        await this.userProfilePromise; // Wait for get profile request to be finished
        if (this.authenticated()) {
            if (route.routeConfig.path === '') {
                return true;
            }
            if (route.routeConfig.path === 'machines' || route.routeConfig.path === 'machines/:id' || route.routeConfig.path === 'machine-event/:id' || route.routeConfig.path === 'machine-events') {
                if (this.userProfile.permissions.modules['machines']) {
                    return true;
                }
            } else if (route.routeConfig.path === 'customers' || route.routeConfig.path === 'customers/:id') {
                if (this.userProfile.permissions.modules['customers']) {
                    return true;
                }
            } else if (route.routeConfig.path === 'parts' || route.routeConfig.path === 'parts/:id') {
                if (this.userProfile.permissions.modules['parts']) {
                    return true;
                }
            } else if (route.routeConfig.path === 'dashboard' || route.routeConfig.path === 'testseries' || route.routeConfig.path === 'testseries/:id') {
                if (this.userProfile.permissions.modules['reports']) {
                    return true;
                }
            }
            // console.log('canActivate, route', route);
            // console.log('canActivate, state', state);
            return false;
        } else {
            this.login();
            return false;
        }
    }

    get accessToken(): string {
        try {
            return localStorage.getItem('access_token');
        } catch (e) {
            return null;
        }
    }
    set accessToken(access_token: string) {
        if (access_token) {
            localStorage.setItem('access_token', access_token);
        }
    }

    get userProfile() {
        return this._userProfile;
    }

    async loginWithToken(token) {
        console.log('AuthService::loginWithToken()');
        if (token) {
            // check that the token exists and it is not expired
            const jwtHelperService = new JwtHelperService();
            const payload = jwtHelperService.decodeToken(token);
            let userProfile = payload.user;
            console.log('AuthService::loginWithToken() userProfile', userProfile);

            // Check is token expired
            const unixEpochTimeNow = new Date().getTime() / 1000;
            if (payload && payload.exp && unixEpochTimeNow > payload.exp) {
                userProfile = null;
                console.log('AuthService::loginWithToken() token is expired');
            }

            if (userProfile) {
                // Get user profile (backend IAM appended user with permissions)
                this.userProfilePromise = this.http.get(`${this.BACKENDURL}/auth/profile`, {
                    headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` })
                }).toPromise().then((authProfileData: any) => {
                    console.log('AuthService::loginWithToken() authProfileData', authProfileData);

                    userProfile.permissions = authProfileData.permissions;

                    // Store token into local storage
                    this.accessToken = token;
                    this._userProfile = userProfile;
                    this._authenticated = true;
                    // Change language
                    if (this._userProfile.language) {
                        // await this.localeService.language = this._userProfile.language;
                        this.localeService.language = this._userProfile.language;
                    }
                }).catch((error) => {
                    console.log('AuthService::loginWithToken() authProfileData error');
                    this.accessToken = null;
                    this._userProfile = null;
                    this._authenticated = false;
                });
            }
        } else {
            this.accessToken = null;
            this._userProfile = null;
            this._authenticated = false;
        }
    }

    async handleLogin(token) {
        if (token) {
            // Store accessToken
            this.accessToken = token;
            // Login with it (same as refreshing page)
            await this.loginWithToken(token);
            // Redirect to main
            // window.location.replace('/');
            this.router.navigate(['']);
        }
    }

    authenticated() {
        return (this._authenticated);
    }

    login() {
        console.log('auth.service login()');
        // this.router.navigate(['selectlogin']);
        // window.location.replace('/login');

        // MyKemppi Salesforce
        // const kemppiIamPortal = this.appConfigService.config.kemppiIamUrl;
        // const clientId = this.appConfigService.config.kemppiIamClientId;
        // const redirectUri = this.appConfigService.config.frontendUrl + '/callback';
        // window.location.replace(`${kemppiIamPortal}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`);

        // Response should be something like this:
        // http://localhost:4200/callback
        //   ?code=aPrxdbJqxvRUUKm15bsjfGBwM4xDryLQibptQECAfynZRYzCQHln9wWLUZhGEi9xoRP38D7btA%3D%3D
        //   &sfdc_community_url=https%3A%2F%2Fproddev-kemppi.cs81.force.com%2Fiam
        //   &sfdc_community_id=0DB26000000016KGAQ

        // MyKemppi Auth0
        const auth0Url = `https://${this.appConfigService.config.oauth2Domain}`;
        const clientId = this.appConfigService.config.oauth2ClientId;
        const redirectUri = this.appConfigService.config.frontendUrl + '/callback';
        window.location.replace(`${auth0Url}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20profile%20email`);

        // Response should be something like this:
        // http://localhost:4200/callback
        //   ?code=aPrxdbJqxvRUUKm15bsjfGBwM4xDryLQibptQECAfynZRYzCQHln9wWLUZhGEi9xoRP38D7btA%3D%3D
    }

    logout() {
        this._authenticated = false;
        this._userProfile = null;
        localStorage.clear();
        // Redirect to main
        // window.location.replace('/login');
        // const kemppiIamPortal = this.appConfigService.config.kemppiIamUrl;
        const kemppiIamPortal = this.appConfigService.config.kemppiIamPortal;
        window.location.replace(kemppiIamPortal);
    }

    isAllowedTo(action, company = null, partnerId = null) {
        const user = this.userProfile;
        company = company || this.userProfile.company;
        partnerId = partnerId || this.userProfile.partnerId;
        let scopeAllowed = true;
        let actionAllowed = true;

        // System admin always allowed
        if (user.role === 'sys_admin') {
            return true;
        }

        // Check user scope
        if (user.permissions.scope === 'global') {
            // All company and partnerId's allowed
        } else if (user.permissions.scope === 'company') {
            if (user.company !== company) {
                scopeAllowed = false;
            }
        } else if (user.permissions.scope === 'partner') {
            if (user.company !== company || user.partnerId !== partnerId) {
                scopeAllowed = false;
            }
        } else {
            // Just in case, all other scopes are not allowed
            scopeAllowed = false;
        }

        // Check user action
        if (user.permissions.actions[action]) {
            // Action found from user permissions
        } else {
            actionAllowed = false;
        }

        return (scopeAllowed && actionAllowed);
    }

}
