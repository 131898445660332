import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

declare var require: any;

// Warranty claims service

@Injectable()
export class ClaimsService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.warrantyBackendUrl;
        this.token = this.authService.accessToken;
    }

    getClaim(company, partnerId, claimId) {
        console.log('getClaim', company, partnerId, claimId);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, { headers: this._getHeaders() });
    }

    getClaimById(claimId) {
        console.log('getClaimById', claimId);
        return this.http.get(`${this.BACKENDURL}/claims/${claimId}`, { headers: this._getHeaders() });
    }

    saveClaim(company, partnerId, claimId, claim, action = null, type = null) {
        if (!claimId) {
            // Create new (POST)
            return this.http.post(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/`, JSON.stringify(claim), { headers: this._getHeaders() });
        } else {
            // Update existing one (PUT)
            if (action && type) {
                // Add / Remove approvals
                return this.http.put(
                    `${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}?action=${action}&type=${type}`,
                    JSON.stringify(claim),
                    { headers: this._getHeaders() }
                );
            } else {
                // Save
                return this.http.put(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, JSON.stringify(claim), { headers: this._getHeaders() });
            }
        }
    }

    deleteClaim(company, partnerId, claimId) {
        return this.http.delete(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, { headers: this._getHeaders() });
    }

    getAttachment(company, partnerId, claimId, attachmentId) {
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, { headers: this._getHeaders() });
    }

    saveAttachment(company, partnerId, claimId, attachmentId, file) {
        return this.http.post(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, JSON.stringify(file), { headers: this._getHeaders() });
    }

    deleteAttachment(company, partnerId, claimId, attachmentId) {
        return this.http.delete(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, { headers: this._getHeaders() });
    }

    // Returns search params ?search=key:value;key2:value2
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        if (queryStringParams) {
            return `?search=${queryStringParams}`;
        } else {
            return '';
        }
    }

    searchAll(params) {
        console.log('search', params);
        return this.http.get(`${this.BACKENDURL}/claims${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    searchByCompany(company, params) {
        console.log('searchByCompany', company, params);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    searchByPartner(company, partnerId, params) {
        console.log('searchByPartner', company, partnerId, params);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    getClaimsBySerialNumber(serialNumber) {
        console.log('getClaimsBySerialNumber', serialNumber);
        return this.http.get(`${this.BACKENDURL}/claims/serial/${serialNumber}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

}
