import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable()
export class CustomersService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
        this.token = authService.accessToken;
    }

    getCustomerInfo(company, partnerId) {
        console.log('getCustomerInfo', company, partnerId);
        return this.http.get(`${this.BACKENDURL}/customers/company/${company}/partner/${partnerId}`, { headers: this._getHeaders() });
    }

    searchCustomers(params) {
        console.log('searchCustomers', params);
        return this.http.get(`${this.BACKENDURL}/customers${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

    // Returns search params ?search=key:value;key2:value2
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        if (queryStringParams) {
            return `?search=${queryStringParams}`;
        } else {
            return '';
        }
    }

}

export interface ICustomerInfo {
    id: string;
    company: string;
    custId: string;
    name: string;
    country: string;
    groupCode: string;
    groupDesc: string;
    currencyCode: string;
    langNameId: string;
    address?: {
        address?: string;
        address1?: string;
        address2?: string;
        address3?: string;
        zip?: string;
        city?: string;
        state?: string;
        country?: string;
    };
}
