import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { AppConfigService } from '../../../../services/app-config.service';
import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state.service';
import { LoginService } from 'src/app/services/login.service';

import { CompanyCodes } from 'src/app/models/company';

import * as _ from 'lodash';

/**
 * Dummy test login
 */
@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public loading: boolean;
    public loginError: any;
    private BACKENDURL: string;
    public loginParams: any;
    public publicLogin: boolean;

    public companyCodes: Array<string>;
    public roleTypes: Array<string>;

    constructor(
        private route: ActivatedRoute,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        public stateService: StateService,
        private http: HttpClient,
        private loginService: LoginService
    ) {
        this.loading = false;
        console.log('constructor()');
        this.BACKENDURL = this.appConfigService.config.machineryBackendUrl;

        this.companyCodes = CompanyCodes;
        this.roleTypes = [
            'sys_admin', 'koy_user', 'sub_user'
        ];
        this.loginError = '';
    }

    ngOnInit() {
        console.log('ngOnInit()');
        this.loginParams = {
            id: 'USERID0001',
            name: '',
            email: 'noreply@kemppi.com',
            company: '100',
            partnerId: '100',
            role: 'koy_user',
            userType: 'kemppioy',
            password: ''
        };
        setTimeout(async () => {
            this.stateService.state.hasMenu = false;
        });
    }

    login() {
        console.log('login()');
        if (this.loginParams.name === null || this.loginParams.name === '') {
            this.loginError = 'Name cannot be empty';
            return;
        }
        this.loading = true;
        // Call /login endpoint, returns token.
        this.loginService.login(this.loginParams).subscribe((response: any) => {
            console.log('login response', response);
            if (response) {
                this.authService.handleLogin(response);
            } else {
                this.loading = false;
            }
        }, (error) => {
            this.loginError = _.get(error, 'error.errorMessage') || 'Login error';
            console.log('ERROR during login', this.loginError);
            this.loading = false;
        });
    }

    validateAndParseUserType() {
        this.loginParams.partnerId = this.loginParams.partnerId.toUpperCase();
        if (this.loginParams.company === '' || this.loginParams.partnerId === '') {
            this.loginParams.userType = '';
        } else if (this.loginParams.company === '100' && this.loginParams.partnerId === '100') {
            this.loginParams.userType = 'kemppioy';
        } else if (this.loginParams.company === this.loginParams.partnerId ) {
            this.loginParams.userType = 'subsidiary';
        // } else if (this.loginParams.company === '100' && this.loginParams.partnerId.startsWith('FI')) {
        //     this.loginParams.userType = 'dealer (domestic)';
        } else if (this.loginParams.company === '100') {
            this.loginParams.userType = 'distributor';
        } else {
            this.loginParams.userType = 'dealer';
        }
    }

}
