import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DateAdapter } from '@angular/material';

const DateFormats = {
    default: {
        longDateFormat: {
            LT: 'HH:mm', // 21:15
            LTS: 'HH:mm:ss', // 21:15:50
            L: 'DD.MM.YY', // 24.12.17
            LL: 'DD.MM.YYYY', // 24.12.2017
            LLL: 'D MMMM, YYYY', // 24 December, 2017
            LLLL: 'dddd, D MMMM, YYYY' // Friday, 24 December, 2017
        },
        week: {
            dow: 1, // Monday is the first day of the week,
            doy: 4
        }
    },
    ja: { // overrides for Japanese
        longDateFormat: {
            L: 'YY.MM.DD',
            LL: 'YYYY.MM.DD'
        }
    }
};

@Injectable()
export class LocaleService {
    private _locale: string;
    private _language: string;

    constructor(
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<any>
    ) {
        this.translateService.addLangs(['en', 'fi']);
        this.translateService.setDefaultLang('en');
        this._language = 'en';
        this._locale = 'en-GB';
    }


    set language(language) {
        language = language.substring(0, 2);
        // STAGE, FORCE LANGUAGE 'en' for testing
        language = 'en';
        this._language = language;
        this.changeLanguage();
    }
    get language() {
        return this._language;
    }

    set locale(locale) {
        // Force en-GB
        if (locale === 'en' || locale === 'en-US') {
            locale = 'en-GB'; // en-GB has dd/mm/yyyy instead of default en-US mm/dd/yyyy
        }
        this._locale = locale;
        this.changeLocale();
    }
    get locale() {
        return this._locale;
    }

    changeLanguage() {
        console.log('LocaleService:changeLanguage()', this._language);

        // first load moment's language for use
        moment.locale(this._language);

        // then change to use the weldeye gloval date format settings for this locale
        moment.updateLocale(this._language, this.getMomentFormatSettings(this._language));

        // set user interface language
        this.translateService.use(this._language);

        this.locale = this._language;
    }

    changeLocale() {
        console.log('LocaleService:changeLocale()', this._locale);

        // set angular material language (for example: date picker)
        this.dateAdapter.setLocale(this._locale);
    }

    private getMomentFormatSettings(language: string) {
        // get the default date format
        const dateFormat = _.cloneDeep(DateFormats.default);

        // apply locale specific settings
        _.merge(dateFormat, DateFormats[language]);

        return dateFormat;
    }
}

