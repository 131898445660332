import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable()
export class PartsService {

    BACKENDURL: string;
    APIROOT: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
        this.token = authService.accessToken;
    }

    getAll() {
        console.log('getAll');
        return this.http.get(`${this.BACKENDURL}/parts`, { headers: this._getHeaders() });
    }

    getPartInfo(partNumber) {
        console.log('getPartInfo', partNumber);
        return this.http.get(`${this.BACKENDURL}/parts/${partNumber}`, { headers: this._getHeaders() });
    }

    searchParts(params) {
        console.log('searchParts', params);
        return this.http.get(`${this.BACKENDURL}/parts${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

    // Returns search params ?search=key:value;key2:value2
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        if (queryStringParams) {
            return `?search=${queryStringParams}`;
        } else {
            return '';
        }
    }

}
