import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { StateService } from './services/state.service';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './services/app-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    appInfo = {
        icon: 's',
        title: 'Machinery'
    };

    constructor(
        private router: Router,
        private translate: TranslateService,
        public stateService: StateService,
        private auth: AuthService,
        private appConfigService: AppConfigService
    ) {
        // Clear loading state after navigation end
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.stateService.state.loading = false;
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
    }

    back() {
        if (this.router.url.split('/')[1] === 'machines') {
            this.router.navigate(['machines']);
        } else if (this.router.url.split('/')[1] === 'machine-event') {
            this.router.navigate(['machines']);
        } else if (this.router.url.split('/')[1] === 'customers') {
            this.router.navigate(['customers']);
        } else if (this.router.url.split('/')[1] === 'parts') {
            this.router.navigate(['parts']);
        }
    }
}
