import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

import * as _ from 'lodash';

declare var require: any;

@Injectable()
export class LoginService {

    BACKENDURL: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
    }

    login(loginParams) {
        console.log('login', loginParams);
        return this.http.post(`${this.BACKENDURL}/auth/login`, JSON.stringify(loginParams), { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
}
