import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

// Dummy prices service
// To get customer and vendor prices from Epicor

@Injectable()
export class PricesService {
    BACKENDURL: string;
    token: string;

    LOCAL_BACKENDURL: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.warrantyBackendUrl;
        this.token = authService.accessToken;

        // LOCAL
        this.LOCAL_BACKENDURL = 'http://192.168.2.121:8089';
    }

    getRepairLabourPrice(company, partnerId) {
        console.log('getRepairLabourPrice', company, partnerId);
        return this.http.get(`${this.BACKENDURL}/prices/labour?company=${company}&partnerId=${partnerId}`, { headers: this._getHeaders() });
    }

    getLabourCosts() {
        console.log('getLabourCosts');
        return this.http.get(`${this.BACKENDURL}/prices/labour/costs`, { headers: this._getHeaders() });
    }

    saveLabourCosts(company, costs) {
        console.log('saveLabourCosts', company, costs);
        const params = {
            company: company,
            costs: costs
        };
        return this.http.post(`${this.BACKENDURL}/prices/labour/costs`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    getCustomerPartPrices(company, partnerId, partNumbers) {
        console.log('getCustomerPartPrices', company, partnerId, partNumbers);
        const params = {
            company: company,
            partnerId: partnerId,
            partNumbers: partNumbers
        };
        return this.http.post(`${this.BACKENDURL}/prices/customer`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    getVendorPartPrices(company, partnerId, partNumbers) {
        console.log('getVendorPartPrices', company, partnerId, partNumbers);
        const params = {
            company: company,
            partnerId: partnerId,
            partNumbers: partNumbers
        };
        return this.http.post(`${this.BACKENDURL}/prices/vendor`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

    // LOCAL TEST VERSIONS

    getCustomerPartPrices_LOCAL(company, partnerId, partNumbers) {
        console.log('getCustomerPartPrices', company, partnerId, partNumbers);
        const params = {
            company: company,
            partnerId: partnerId,
            partNumbers: partNumbers
        };
        const queryString = this._getQueryString(params);
        return this.http.get(`${this.LOCAL_BACKENDURL}/getcustomerpartprice?${queryString}`, { headers: this._getHeaders() });
    }

    getVendorPartPrices_LOCAL(company, partNumbers) {
        console.log('getVendorPartPrices', company, partNumbers);
        const params = {
            company: company,
            partNumbers: partNumbers
        };
        const queryString = this._getQueryString(params);
        return this.http.get(`${this.LOCAL_BACKENDURL}/getvendorpartprice?${queryString}`, { headers: this._getHeaders() });
    }

    _getQueryString(params) {
        let queryString;
        if (params.partnerId) {
            queryString = `company=${params.company}&partnerId=${params.partnerId}&partNumbers=${params.partNumbers.join(',')}`;
        } else {
            queryString = `company=${params.company}&partNumbers=${params.partNumbers.join(',')}`;
        }
        return queryString;
    }
}
