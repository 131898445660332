import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/services/auth.service';
import { CustomersService } from 'src/app/services/customers.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MachinesService } from 'src/app/services/machines.service';
import { StateService } from 'src/app/services/state.service';

import { Company, Companies } from 'src/app/models/company';
import { IMachineEvent } from 'src/app/models/IMachineEvent';

import * as _ from 'lodash';

@Component({
    selector: 'app-list-machine-events-page',
    templateUrl: './list-machine-events-page.component.html',
    styleUrls: ['./list-machine-events-page.component.scss']
})
export class ListMachineEventsPageComponent implements OnInit {

    state: any;

    /**
     * Events
     */
    machineEvents: Array<IMachineEvent> = [];

    /**
     * Claims count
     */
    resultHits: number;

    /**
     * Claims total count
     */
    totalHits: number;

    serialNumber: string;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    searchPartnerId: string;
    searchCompany: string;

    searchSerialNumber: string;
    searchEventType: string;
    searchEventSource: string;
    searchTransactionId: string;

    searchSize: number;
    searchFrom: number;
    searchType: string;

    sortParams: any = {
        key: 'version.modified.timestamp',
        direction: 'desc'
    };

    /**
     * Current search bar value
     */
    searchText = '';

    authUserScope = 'partner';
    authUserRole = '';

    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    dateFilterField: string;
    dateFilterStart: string;
    dateFilterEnd: string;

    eventsLoading: boolean;

    now: string;

    /**
     * All machine rows selected
     */
    allEventsChecked: boolean;

    /**
     * Is any of machines selected
     */
    anyEventSelected: boolean;

    /**
     * Collection of machine event ids that are checked
     */
    selectedEvents: object;

    showAdminTools = false;


    public _: any = _;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private machinesService: MachinesService,
        private customersService: CustomersService,
        private snackBar: MatSnackBar,
        private excelService: ExcelService
    ) {
        this.companies = Companies;
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };

        this.now = new Date().toISOString();

        this.searchPartnerId = '';
        this.searchCompany = '';
        this.searchSerialNumber = '';
        this.searchEventType = '';
        this.searchEventSource = '';
        this.searchTransactionId = '';
        this.searchPartner = '';
        this.dateFilterField = '';

        this.searchSize = 50;
        this.searchFrom = 0;

        this.allEventsChecked = false;
        this.anyEventSelected = false;
        this.selectedEvents = {};
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;

            this.authUserScope = this.authService.userProfile.permissions.scope;
            this.authUserRole = this.authService.userProfile.role;

            if (this.authUserScope === 'global') {
                // ?
            } else if (this.authUserScope === 'company') {
                this.searchCompany = this.authService.userProfile.company;
                this.setFilter('company', this.searchCompany);
            }
            this.search();
        });

        this.searchType = 'serial';
    }

    openSerialInfo(serialNumber) {
        this.router.navigate([`/machines/${serialNumber.trim()}`]);
    }

    searchMachineEvents() {
        this.eventsLoading = true;
        console.log('search machine events', this.searchParams, this.sortParams);
        this.machinesService.searchMachineEvents(this.searchParams, [this.sortParams], this.searchSize, this.searchFrom).subscribe((response: any) => {
            if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                this.machineEvents.push(...response.items);
                this.totalHits = response.totalCount;
            } else {
                this.machineEvents = response;
            }
            this.resultHits = this.machineEvents.length;

            this.eventsLoading = false;
            this.resetSelectedEvents();
        }, (error) => {
            console.log('Error, searchMachineEvents');
            this.eventsLoading = false;
            this.resetSelectedEvents();
        });
    }

    loadMore() {
        this.searchFrom = this.searchFrom + this.searchSize;
        this.searchSize = 50;
        this.searchMachineEvents();
    }

    search() {
        // Reset hits and start new searching from begining
        this.totalHits = 0;
        this.resultHits = 0;
        this.machineEvents = [] as Array<IMachineEvent>;
        this.searchSize = 50;
        this.searchFrom = 0;

        // Partner id
        if (this.searchPartnerId === '') {
            this.removeFilter('partnerId');
        } else {
            this.searchPartnerId = this.searchPartnerId.toLocaleUpperCase();
            this.setFilter('partnerId', '*' + this.searchPartnerId + '*');
        }
        // Company
        this.setFilter('company', this.searchCompany);
        // Serial number
        if (this.searchSerialNumber === '') {
            this.removeFilter('id');
        } else {
            this.searchSerialNumber = this.searchSerialNumber.toLocaleUpperCase();
            this.setFilter('id', '*' + this.searchSerialNumber + '*');
        }
        // Event type
        if (this.searchEventType === '') {
            this.removeFilter('eventType');
        } else {
            this.searchEventType = this.searchEventType.toLocaleUpperCase();
            this.setFilter('eventType', '*' + this.searchEventType + '*');
        }
        // Event source
        if (this.searchEventSource === '') {
            this.removeFilter('eventSource');
        } else {
            this.searchEventSource = this.searchEventSource.toLocaleUpperCase();
            this.setFilter('eventSource', '*' + this.searchEventSource + '*');
        }
        // Event transaction id
        if (this.searchTransactionId === '') {
            this.removeFilter('data.ERP_TRANSACTION.transactionId');
        } else {
            this.searchTransactionId = this.searchTransactionId.toLocaleUpperCase();
            this.setFilter('data.ERP_TRANSACTION.transactionId', '*' + this.searchTransactionId + '*');
        }

        this.removeFilter('eventTimestamp');
        this.removeFilter('systemTimestamp');
        this.removeFilter('version.modified.timestamp');
        if (this.dateFilterField !== '') {
            if (!this.dateFilterStart && this.dateFilterEnd !== '') {
                this.dateFilterStart = '1997-01-01T00:00:00.000Z';
            }
            if (!this.dateFilterEnd) {
                this.dateFilterEnd = new Date().toISOString();
            }
            // Date range in format 2019-01-01T00:00:00.000Z#2019-01-02T23:59:59.999Z
            this.setFilter(this.dateFilterField, `${this.dateFilterStart}#${this.dateFilterEnd}`);
        }

        this.searchMachineEvents();
    }

    setFilter(key, value) {
        if (value === '') {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
        }
    }
    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        if (index !== -1) {
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
    }

    sort(sortKey) {
        console.log('sort', sortKey);
        const currentSortObj = this.sortParams || null;
        let sortDirection = 'desc';
        if (currentSortObj && currentSortObj.key && currentSortObj.key === sortKey) {
            sortDirection = this.sortParams.direction === 'desc' ? 'asc' : 'desc';
        }
        this.sortParams = {
            key: sortKey,
            direction: sortDirection
        };
        this.search();
    }

    openMachine(serialNumber) {
        this.router.navigate(['machines', serialNumber]);
    }

    openMachineEvent(id) {
        this.router.navigate(['machine-event', id]);
    }

    // SELECTIONS
    /**
     * Reset selections (on search)
     */
    resetSelectedEvents() {
        this.selectedEvents = {};
        for (const machineEvent of this.machineEvents) {
            this.selectedEvents[machineEvent.id] = false;
        }
        this.allEventsChecked = false;
        this.checkSelectedEvents();
    }

    /**
     * Check or uncheck allEventsChecked
     */
    selectAllEvents(checked) {
        _.map(this.machineEvents, (machineEvent) => {
            this.selectedEvents[machineEvent.id] = checked;
        });
        this.checkSelectedEvents();
    }

    /**
     * Check is there any selected machines. (Show buttons)
     */
    checkSelectedEvents() {
        this.anyEventSelected = _.some(_.values(this.selectedEvents), (value) => value === true);
    }

    /**
     * Call machine-info handler for serials.
     */
    recalculateSelectedMachines() {
        let serialNumbers = [];
        for (const id in this.selectedEvents) {
            if (this.selectedEvents.hasOwnProperty(id)) {
                if (this.selectedEvents[id] === true) {
                    const mEvent = _.find(this.machineEvents, { id: id });
                    serialNumbers.push(mEvent.serialNumber);
                }
            }
        }
        serialNumbers = _.uniq(serialNumbers);
        // Use SQS queue!
        this.machinesService.postMachineInfoCalculationRequest(serialNumbers, true).subscribe((response) => {
            this.notify('Selected machines send to machine-info recalculation');
            this.resetSelectedEvents();
        });
    }

    setSelectedEventActive(active = true) {
        let eventIds = [];
        for (const id in this.selectedEvents) {
            if (this.selectedEvents.hasOwnProperty(id)) {
                if (this.selectedEvents[id] === true) {
                    const mEvent = _.find(this.machineEvents, { id: id });
                    eventIds.push(mEvent.id);
                }
            }
        }
        eventIds = _.uniq(eventIds);
        for (const eventId of eventIds) {
            this.setEventActive(eventId, active);
        }
        setTimeout(() => {
            this.notify('Selected machine-events send to machine-info recalculation');
            this.resetSelectedEvents();
            this.search();
        }, 2000);
    }

    setEventActive(eventId, active = true) {
        // Get event
        this.machinesService.getMachineEvent(eventId).subscribe((machineEvent: IMachineEvent) => {
            if (machineEvent) {
                // Set active
                _.set(machineEvent, 'active', active);
                // Save
                this.machinesService.saveMachineEvent(machineEvent).subscribe((response) => {
                    console.log('Machine-event saved', machineEvent.id);
                }, (error) => {
                    console.log('Error saving machine-event');
                });
            }
        }, (error) => {
            console.log('Error loading machine-event');
        });
    }

    exportSelectedToXLSX() {
        const data = this._getSelectedForExport();
        this.excelService.exportAsExcelFile(data, 'machine-events');
    }

    _getSelectedForExport() {
        const items = _.map(
            _.filter(this.machineEvents, (mEvent) => {
                return this.selectedEvents[mEvent.id] ? true : false;
            }),
            (mEvent) => {
                return {
                    'Serial number': mEvent.serialNumber,
                    'Type': mEvent.eventType,
                    'Source': mEvent.eventSource,
                    'Description': mEvent.eventDescription,
                    'Time': mEvent.eventTimestamp,
                    'Updated': _.get(mEvent, 'version.modified.timestamp')
                };

            }
        );
        return items;
    }

    /**
     * Toggle admin tools
     */
    toggleAdmin() {
        if (this.authUserRole === 'sys_admin') {
            this.showAdminTools = !this.showAdminTools;
        }
    }

    notify(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 2000
        });
    }

}
