import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

declare var require: any;

// Partner registrations service
// Stores registrations to warranty S3 userdata, sends machine-events to machinery
// Searches registered machines from machinery machines

@Injectable()
export class RegistrationsService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.warrantyBackendUrl;
        this.token = this.authService.accessToken;
    }

    getRegistration(company, partnerId, serialNumber) {
        console.log('getRegistration', company, partnerId, serialNumber);
        return this.http.get(`${this.BACKENDURL}/registrations/company/${company}/partner/${partnerId}/${serialNumber}`, { headers: this._getHeaders() });
    }

    getRegistrationById(serialNumber) {
        console.log('getRegistrationById', serialNumber);
        return this.http.get(`${this.BACKENDURL}/registrations/${serialNumber}`, { headers: this._getHeaders() });
    }

    saveRegistration(company, partnerId, serialNumber, registration) {
        console.log('saveRegistration', company, partnerId, serialNumber, registration);
        if (!serialNumber) {
            // Create new (POST)
            return this.http.post(`${this.BACKENDURL}/registrations/company/${company}/partner/${partnerId}/`, JSON.stringify(registration), { headers: this._getHeaders() });
        } else {
            // Update existing one (PUT)
            return this.http.put(`${this.BACKENDURL}/registrations/company/${company}/partner/${partnerId}/${serialNumber}`, JSON.stringify(registration), { headers: this._getHeaders() });
        }
    }

    deleteRegistration(company, partnerId, serialNumber) {
        return this.http.delete(`${this.BACKENDURL}/registrations/company/${company}/partner/${partnerId}/${serialNumber}`, { headers: this._getHeaders() });
    }

    // Returns search params ?search=key:value;key2:value2
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        if (queryStringParams) {
            return `?search=${queryStringParams}`;
        } else {
            return '';
        }
    }

    searchRegistrationsAll(params) {
        console.log('searchRegistrationsAll', params);
        return this.http.get(`${this.BACKENDURL}/registrations${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    searchRegistrationsByCompany(company, params) {
        console.log('searchRegistrationsByCompany', company, params);
        return this.http.get(`${this.BACKENDURL}/registrations/company/${company}${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    searchRegistrationsByPartner(company, partnerId, params) {
        console.log('searchRegistrationsByPartner', company, partnerId, params);
        return this.http.get(`${this.BACKENDURL}/registrations/company/${company}/partner/${partnerId}${this._getSearchString(params)}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

}
