<div class="main">
    <div class="full page-container" fxLayout="column">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Ë</mat-icon>
            <div fxLayout="column">
                <h2>Dashboard</h2>
                <div>Create simple histogram reports from machine events</div>
            </div>
        </div>

        <div>
            <h2 class="mat-subhead">Report selections</h2>
            <div class="full" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="250px">
                        <mat-label>Date field</mat-label>
                        <mat-select [(value)]="report.histogramField">
                            <mat-option value="eventTimestamp">EVENT - Event date</mat-option>
                            <mat-option value="creationDate">VERSION - Creation date</mat-option>
                            <mat-option value="modifiedDate">VERSION - Modified date</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="250px">
                        <mat-label>Time interval</mat-label>
                        <mat-select [(value)]="report.histogramInterval">
                            <mat-option value="day">Day</mat-option>
                            <mat-option value="week">Week</mat-option>
                            <mat-option value="month">Month</mat-option>
                            <mat-option value="year">Year</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <h2 class="mat-subhead">Filters</h2>
                <div fxLayout="row" *ngFor="let filter of report.filters; let i = index">
                    <div class="filter-row" fxFlex>
                        <div fxFlex="300px">
                            {{ filter.name }}<br>
                            <div class="weak">{{ filter.field}}</div>
                        </div>
                        <div fxFlex="20px">=</div>
                        <div *ngIf="filter.type === 'match'" fxFlex="320px">
                            {{ filter.matchValue }}
                        </div>
                        <div *ngIf="filter.type === 'should'" fxFlex="320px">
                            (<span *ngFor="let shouldValue of filter.shouldValues; let i = index"><span *ngIf="i > 0"> / </span>{{ shouldValue }}</span>)
                        </div>
                        <div *ngIf="filter.type === 'range'" fxFlex="320px">
                            {{ filter.rangeStart | date:'yyyy-MM-dd HH:mm' }} - {{ filter.rangeEnd | date:'yyyy-MM-dd HH:mm' }}
                        </div>
                        <div fxFlex></div>
                        <button mat-icon-button mat-raised-button color="accent" (click)="editReportFilter(i)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button mat-raised-button color="warn" (click)="removeReportFilter(i)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </div>
                
                <br>
                <div fxFlex fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex="250px">
                        <mat-label>Add filter - Select field</mat-label>
                        <mat-select [(value)]="selectFilter">
                            <mat-option *ngFor="let filter of filters" [value]="filter">
                                <div fxFlex>{{ filter.name }}</div>
                                <small *ngIf="filter.type === 'range'"><i>{{ filter.type }}</i></small>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectFilter && selectFilter.type === 'match'">
                        <mat-form-field fxFlex>
                            <mat-label>Set value and click (+)</mat-label>
                            <input *ngIf="!selectFilter.values" matInput [(ngModel)]="selectFilterValue">
                            <mat-select *ngIf="selectFilter.values" [(value)]="selectFilterValue">
                                <mat-option *ngFor="let value of selectFilter.values" [value]="value">{{ value }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="selectFilter && selectFilter.type === 'range'">
                        <war-datepicker
                        [(ngModel)]="selectFilterStart"
                        [label]="'Start date'"></war-datepicker>
                        <war-datepicker
                        [(ngModel)]="selectFilterEnd"
                        [label]="'End date'"></war-datepicker>
                        <div fxFlex></div>
                    </ng-container>

                    <button *ngIf="selectFilter" mat-icon-button mat-raised-button color="accent" (click)="addReportFilter()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <div fxLayout="row">
                <button mat-button mat-raised-button color="accent" (click)="getEventsHistogram()">
                    Update
                </button>
                <div fxFlex></div>
            </div>
        </div>

        <div>
            <h2 class="mat-subhead">Histogram</h2>
            <div *ngIf="chart" class="container">
                <div [chart]="chart" class="chart"></div>
            </div>
        </div>
    </div>
    <!--
    <pre>{{ resultsHistogram | json }}</pre>
    -->
</div>
<!-- ACTIONBAR -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
