<div class="main">
    <div *ngIf="machineEvent" class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon>change_history</mat-icon>
            <div fxLayout="column">
                <h2>Machine event</h2>
                <div>Create / edit machine event manually. <i>(Do not mess with the automatic events!)</i></div>
            </div>
        </div>

        <fieldset fxFlex>
            <legend class="mat-subhead">Event</legend>
            <div fxLayout="column">
                <div fxLayout="row">
                    <mat-form-field readonly="true" fxFlex>
                        <mat-label>Id</mat-label>
                        <input matInput [(ngModel)]="machineEvent.id" [disabled]="!canEdit">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex>
                        <mat-form-field [attr.readonly]="id !== 'new'">
                            <mat-label>Serial number</mat-label>
                            <input matInput [(ngModel)]="machineEvent.serialNumber" [disabled]="!canEdit">
                        </mat-form-field>
                    </div>
                    <div fxFlex>
                        <mat-checkbox [(ngModel)]="machineEvent.active">Active</mat-checkbox>
                    </div>
                </div>

                <div fxLayout="row">
                    <mat-form-field readonly="true">
                        <mat-label>Event source</mat-label>
                        <input matInput [(ngModel)]="machineEvent.eventSource" [disabled]="!canEdit">
                    </mat-form-field>
                    <mat-form-field [attr.readonly]="id !== 'new'">
                        <mat-label>Event type</mat-label>
                        <mat-select [(value)]="machineEvent.eventType" (selectionChange)="changeEventType()" [disabled]="!canEdit">
                            <mat-option value=""></mat-option>
                            <mat-option value="ERP_TRANSACTION">ERP_TRANSACTION</mat-option>
                            <mat-option value="PARTNER_REGISTRATION">PARTNER_REGISTRATION</mat-option>
                            <mat-option value="END_CUSTOMER_REGISTRATION">END_CUSTOMER_REGISTRATION</mat-option>
                            <mat-option value="ATTACH_TO_ORGANIZATION">ATTACH_TO_ORGANIZATION</mat-option>
                            <mat-option value="STOLEN">STOLEN</mat-option>
                            <mat-option value="DESTROYED">DESTROYED</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field [attr.readonly]="id !== 'new'" fxFlex="300px">
                        <mat-label>Event timestamp</mat-label>
                        <input matInput [(ngModel)]="machineEvent.eventTimestamp" [disabled]="!canEdit">
                    </mat-form-field>
                    <mat-form-field [attr.readonly]="id !== 'new'" fxFlex="300px">
                        <mat-label>System timestamp</mat-label>
                        <input matInput [(ngModel)]="machineEvent.systemTimestamp" [disabled]="!canEdit">
                    </mat-form-field>
                </div>

            </div>
        </fieldset>

        <div *ngIf="machineEvent.eventType === 'ERP_TRANSACTION'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - ERP TRANSACTION</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field>
                            <mat-label>Transaction Id</mat-label>
                            <mat-select [(value)]="machineEvent.data[machineEvent.eventType].transactionId" (selectionChange)="changeTransactionId()">
                                <mat-option value=""></mat-option>
                                <mat-option value="DRP-CUS">DRP-CUS</mat-option>
                                <mat-option value="INS-REJ">INS-REJ</mat-option>
                                <mat-option value="KIT-CUS">KIT-CUS</mat-option>
                                <mat-option value="MFG-CUS">MFG-CUS</mat-option>
                                <mat-option value="MFG-STK">MFG-STK</mat-option>
                                <mat-option value="PUR-DRP">PUR-DRP</mat-option>
                                <mat-option value="PUR-STK">PUR-STK</mat-option>
                                <mat-option value="RMA-INS">RMA-INS</mat-option>
                                <mat-option value="STK-CUS">STK-CUS</mat-option>
                                <mat-option value="STK-STK">STK-STK</mat-option>
                                <mat-option value="MAINT">MAINT</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Serial number status</mat-label>
                            <mat-select [(value)]="machineEvent.data[machineEvent.eventType].serialNumberStatus">
                                <mat-option value=""></mat-option>
                                <mat-option value="DMR">DMR</mat-option>
                                <mat-option value="INSPECTION">INSPECTION</mat-option>
                                <mat-option value="INVENTORY">INVENTORY</mat-option>
                                <mat-option value="SHIPPED">SHIPPED</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field fxFlex="120px">
                            <mat-label>Product id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].productId">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Product name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].productName">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="80px">
                            <mat-label>Company</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].company">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px">
                            <mat-label>Partner id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].partnerId">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Partner name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].partnerName">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field fxFlex="80px">
                            <mat-label>Secure id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].secureId"  maxlength="4">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field fxFlex="120px">
                            <mat-label>Order number</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].orderNumber">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px">
                            <mat-label>Ship number</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].shipNumber">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px">
                            <mat-label>Job number</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].jobNumber">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="80px">
                            <mat-label>Stock</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].stock">
                        </mat-form-field>
                        <!--
                        <mat-form-field fxFlex="80px">
                            <mat-label>Customer group code</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].customerGroupCode">
                        </mat-form-field>
                        -->
                        <mat-form-field>
                            <mat-label>SysRowId</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].sysRowId">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machineEvent.eventType === 'PARTNER_REGISTRATION'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - PARTNER REGISTRATION</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="80px">
                            <mat-label>Company</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].company">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px">
                            <mat-label>Partner id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].partnerId">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Partner name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].partnerName">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-form-field>
                            <mat-label>Sales date</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].salesDate">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <mat-checkbox [(ngModel)]="showSalesDateApproved" (change)="toggleSalesDateApproment()">Sales date approvement</mat-checkbox>
                    </div>
                    <div fxLayout="row" *ngIf="showSalesDateApproved && machineEvent.data[machineEvent.eventType].salesDateApproved">
                        <mat-form-field>
                            <mat-label>Timestamp</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].salesDateApproved.timestamp">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>User id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].salesDateApproved.user.id">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>User name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].salesDateApproved.user.name">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machineEvent.eventType === 'END_CUSTOMER_REGISTRATION'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - END CUSTOMER REGISTRATION</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Organization id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].organizationId">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Organization name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].organizationName">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machineEvent.eventType === 'ATTACH_TO_ORGANIZATION'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - ATTACH TO ORGANIZATION</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Organization id</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].organizationId">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Organization name</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].organizationName">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machineEvent.eventType === 'STOLEN'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - STOLEN</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Reported by</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].reportedBy">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Description</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].description">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machineEvent.eventType === 'DESTROYED'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">EVENT DETAILS - DESTROYED</legend>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Reported by</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].reportedBy">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="380px">
                            <mat-label>Description</mat-label>
                            <input matInput [(ngModel)]="machineEvent.data[machineEvent.eventType].description">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="userRole === 'sys_admin'" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Meta</legend>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="machineEvent.schema" class="user-stamp">
                        <b>Schema</b><br>
                        {{machineEvent.schema.type}}<br>
                        {{machineEvent.schema.version}}
                    </div>
                    <div *ngIf="machineEvent.user" class="user-stamp">
                        <b>User</b><br>
                        {{machineEvent.user.id}}<br>
                        {{machineEvent.user.name}}
                    </div>
                    <div *ngIf="machineEvent.version && machineEvent.version.created" class="user-stamp">
                        <b>Created</b><br>
                        {{machineEvent.version.created.timestamp | date:'dd.MM.yyyy HH:mm'}}<br>
                        {{machineEvent.version.created.user?.name}}
                    </div>
                    <div *ngIf="machineEvent.version && machineEvent.version.modified" class="user-stamp">
                        <b>Modified</b><br>
                        {{machineEvent.version.modified.timestamp | date:'dd.MM.yyyy HH:mm'}}<br>
                        {{machineEvent.version.modified.user?.name}}
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>

<pre>
    {{ machineEvent | json }}
</pre>

<ks-actionbar
    [hasCancel]="true"
    [hasSave]="canSave"
    [isSaving]="state.busy"
    [canSave]="!state.busy"
    (save)="save()"
    (cancel)="cancel()">
    <div fxLayout="row" fxFlex>
        <ng-container *ngIf="userRole === 'sys_admin'">
            <button mat-button color="warn" (click)="toggleAdmin()">
                <mat-icon class="kemppi-symbols">s</mat-icon>
            </button>

            <ng-container *ngIf="showAdminTools">
                Examples:
                <button mat-button mat-raised-button color="accent" (click)="loadExampleData('manufacture')">Manufacture</button>
                <button mat-button mat-raised-button color="accent" (click)="loadExampleData('subsidiary')">To subsidiary</button>
                <button mat-button mat-raised-button color="accent" (click)="loadExampleData('dealer')">To dealer</button>
                <button mat-button mat-raised-button color="warn" (click)="loadExampleData('stolen')">Stolen</button>
                <button mat-button mat-raised-button color="warn" (click)="loadExampleData('destroyed')">Destroyed</button>
            </ng-container>
        </ng-container>

        <div fxFlex></div>
    </div>

</ks-actionbar>