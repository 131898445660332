import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { HistogramQueryParams } from '../models/report';

declare var require: any;

/**
 * Reports service
 * Collects machinery aggregations
 */
@Injectable()
export class ReportsService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.machineryBackendUrl;
        this.token = this.authService.accessToken;
    }

    getEventsHistogram(params: HistogramQueryParams) {
        console.log('getHistogram', params);
        return this.http.post(`${this.BACKENDURL}/reports/events/histogram`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    getTestSerie(testSerieId: string) {
        console.log('getTestSerie', testSerieId);
        return this.http.get(`${this.BACKENDURL}/reports/testserie/${testSerieId}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

}
