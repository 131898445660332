
<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">-</mat-icon>
            <div fxLayout="column">
                <h2>Machines</h2>
                <div>Search serial numbered welding machines and equipments</div>
            </div>
        </div>
    
        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Open machine info (global)</h2>
            <div class="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="serialNumber" (keydown.enter)="openSerialInfo(serialNumber)">
                </mat-form-field>
                <div>
                    <button mat-raised-button color="accent" (click)="openSerialInfo(serialNumber)">{{ 'common.open' | translate }}</button>
                </div>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Search and list machines
                <ng-container *ngIf="searchCompany === ''">(global)</ng-container>
                <ng-container *ngIf="searchCompany !== ''">({{ searchCompany === '' ? 'global' : searchCompany}} - {{_.find(companies, { id: searchCompany })?.name }})</ng-container>
            </h2>

            <div class="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">

                <form>
                    <mat-form-field fxFlex="300px">
                        <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                        <input type="text" matInput [formControl]="customersFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPartner" (keyup)="searchCustomers()">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeCustomer($event.option)">
                            <mat-option *ngFor="let customer of customersList" [value]="customer">
                                <small fxFlex>{{ customer.name }}</small>
                                <small fxFlex="10px"></small>
                                <small fxFlex="40px"><b>{{ customer.company }}</b></small>
                                <small fxFlex="80px"><b>{{ customer.custId }}</b></small>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>

                <mat-form-field *ngIf="authUserScope === 'global'">
                    <mat-label>{{ 'common.company' | translate }}</mat-label>
                    <mat-select [(value)]="searchCompany" (selectionChange)="search()">
                        <mat-option value="">Any</mat-option>
                        <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="140px">
                    <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchPartnerId" (change)="search()">
                </mat-form-field>
            </div>

            <!--
            <button mat-button color="accent" (click)="toggleAdvancedFilters()" fxLayoutAlign="center center">
                <div *ngIf="showAdvancedFilters" fxLayoutAlign="center center"><mat-icon>arrow_drop_up</mat-icon> <div style="line-height: 16px;">Less<br>filters</div></div>
                <div *ngIf="!showAdvancedFilters" fxLayoutAlign="center center"><mat-icon>arrow_drop_down</mat-icon> <div style="line-height: 16px;">More<br>filters</div></div>
            </button>
            -->
            <div *ngIf="showAdvancedFilters" class="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field fxFlex="140px">
                    <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchSerialNumber" (change)="search()">
                </mat-form-field>
                <mat-form-field fxFlex="140px">
                    <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchProductId" (change)="search()">
                </mat-form-field>
                <mat-form-field fxFlex="140px">
                    <mat-label>{{ 'common.order_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchOrderNumber" (change)="search()">
                </mat-form-field>
                <mat-form-field fxFlex="140px">
                    <mat-label>{{ 'common.job_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchJobNumber" (change)="search()">
                </mat-form-field>
            </div>
            <div *ngIf="showAdvancedFilters" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field fxFlex="140px">
                    <mat-label>Date type</mat-label>
                    <mat-select [(value)]="dateFilterField" (selectionChange)="search()">
                        <mat-option value="">-</mat-option>
                        <mat-option value="manufactureDate">Manufacture date</mat-option>
                        <mat-option value="deliveryDate">Delivery date</mat-option>
                        <mat-option value="partnerRegistrationDate">Registration date</mat-option>
                        <mat-option value="salesDate">Sales date</mat-option>
                        <mat-option value="endCustomerRegistrationDate">End customer date</mat-option>
                        <mat-option value="warrantyExpireDate">Warranty expire date</mat-option>
                    </mat-select>
                </mat-form-field>
                <div fxLayoutAlign="start center" fxLayoutGap="20px">
                    <war-datepicker
                    fxFlex="140px"
                    [(ngModel)]="dateFilterStart"
                    [label]="'Start date'"
                    (changeEvent)="search()"
                    [required]="dateFilterField !== ''"></war-datepicker>
                    <war-datepicker
                    fxFlex="140px"
                    [(ngModel)]="dateFilterEnd"
                    [endOfDay]="true"
                    [label]="'End date'"
                    (changeEvent)="search()"
                    [required]="dateFilterField !== ''"></war-datepicker>
                </div>

                <div>
                    <button mat-raised-button color="accent" (click)="search()">{{ 'common.search' | translate }}</button>
                    <button mat-button color="warn" (click)="resetSearchParams()">{{ 'common.reset' | translate }}</button>
                </div>
            </div>

        </div>

        <!--
        <div class="tighten-row" fxLayout="row">
                <mat-radio-group [(ngModel)]="searchType" disabled>
                <mat-radio-button value="serial" [checked]="searchType === 'serial'">Serial</mat-radio-button>
                <mat-radio-button value="partner">Partner</mat-radio-button>
                <mat-form-field>
                    <input matInput placeholder="Date from" [(ngModel)]="searchFrom" [disabled]="searchType !== 'partner'">
                </mat-form-field>
                -
                <mat-form-field>
                    <input matInput placeholder="Date to" [(ngModel)]="searchTo" [disabled]="searchType !== 'partner'">
                </mat-form-field>
                <mat-radio-button value="order">Order</mat-radio-button>
                <mat-radio-button value="job">Job</mat-radio-button>
            </mat-radio-group>
        </div>
        -->

        <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="40px">
                    <mat-checkbox [(ngModel)]="allMachinesChecked" (change)="selectAllMachines(allMachinesChecked)"></mat-checkbox>
                </div>
                <div fxFlex="80px">
                    <small (click)="sort('serialNumber')">Serial number</small>
                    <mat-icon *ngIf="sortParams.key === 'serialNumber'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-sm="false" fxFlex="40px">
                    <small (click)="sort('secureId')">SecID</small>
                    <mat-icon *ngIf="sortParams.key === 'secureId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="40px">
                    <small (click)="sort('company')">Comp</small>
                    <mat-icon *ngIf="sortParams.key === 'company'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="80px">
                    <small (click)="sort('partnerId')">Partner id</small>
                    <mat-icon *ngIf="sortParams.key === 'partnerId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-sm="false" fxFlex>
                    <small (click)="sort('partnerName')">Partner name</small>
                    <mat-icon *ngIf="sortParams.key === 'partnerName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="100px">
                    <small (click)="sort('productId')">Product number</small>
                    <mat-icon *ngIf="sortParams.key === 'productId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-sm="false" fxFlex>
                    <small (click)="sort('productName')">Product name</small>
                    <mat-icon *ngIf="sortParams.key === 'productName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-md="false" fxFlex="80px">
                    <small (click)="sort('orderNumber')">Order number</small>
                    <mat-icon *ngIf="sortParams.key === 'orderNumber'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <!--
                <div fxFlex="80px">
                    <small (click)="sort('jobNumber')">Job number</small>
                    <mat-icon *ngIf="sortParams.key === 'jobNumber'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                -->
                <div fxShow.lt-md="false" fxFlex="80px">
                    <small (click)="sort('manufactureDate')">Manufactured</small>
                    <mat-icon *ngIf="sortParams.key === 'manufactureDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-md="false" fxFlex="80px">
                    <small (click)="sort('deliveryDate')">Delivered</small>
                    <mat-icon *ngIf="sortParams.key === 'deliveryDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-lg="false" fxFlex="80px">
                    <small (click)="sort('partnerRegistrationDate')">Registered (partner)</small>
                    <mat-icon *ngIf="sortParams.key === 'partnerRegistrationDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-lg="false" fxFlex="80px">
                    <small (click)="sort('endCustomerRegistrationDate')">Registered (customer)</small>
                    <mat-icon *ngIf="sortParams.key === 'endCustomerRegistrationDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-lg="false" fxFlex="80px">
                    <small (click)="sort('warrantyExpireDate')">Warranty expires</small>
                    <mat-icon *ngIf="sortParams.key === 'warrantyExpireDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-lg="false" fxFlex="80px">
                    <small (click)="sort('version.modified.timestamp')">Updated</small>
                    <mat-icon *ngIf="sortParams.key === 'version.modified.timestamp'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngFor="let machine of machines">
                <div class="list-row" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="40px" class="select-checkbox">
                        <mat-checkbox [(ngModel)]="selectedMachines[machine.serialNumber]" (change)="checkSelectedMachines()"></mat-checkbox>
                    </div>
                    <div fxFlex (click)="openMachine(machine.serialNumber)">
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.serialNumber}}
                        </div>
                        <div fxShow.lt-sm="false" fxFlex="40px" fxLayoutAlign="start center">
                            {{machine.secureId}}
                        </div>
                        <div fxFlex="40px" fxLayoutAlign="start center">
                            {{machine.company}}
                        </div>
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.partnerId}}
                        </div>
                        <div fxShow.lt-sm="false" fxFlex fxLayoutAlign="start center">
                            <small>{{machine.partnerName}}</small>
                        </div>
                        <div fxFlex="100px" fxLayoutAlign="start center">
                            {{machine.productId}}
                        </div>
                        <div fxShow.lt-sm="false" fxFlex fxLayoutAlign="start center">
                            <small>{{machine.productInfo?.partDescription}}</small>
                        </div>
                        <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.orderNumber}}
                        </div>
                        <!--
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.jobNumber}}
                        </div>
                        -->
                        <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.manufactureDate | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.deliveryDate | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.partnerRegistrationDate | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="start center">
                            {{machine.endCustomerRegistrationDate | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" [ngClass]="{'warn': machine.warrantyExpireDate < now}" fxLayoutAlign="start center">
                            {{machine.warrantyExpireDate | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" class="date" fxLayoutAlign="start center">
                            {{machine.version?.modified.timestamp | date:'dd.MM.yyyy'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div fxLayout="row" class="width100">
                <div fxFlex>
                    <small><i>{{ 'common.results' | translate }}: {{resultHits}} / {{totalHits}}</i></small>
                </div>
                <div fxFlex fxLayoutAlign="center center">
                    <mat-progress-spinner matSuffix *ngIf="machinesLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                    <button *ngIf="!machinesLoading && totalHits > resultHits" mat-button color="primary" (click)="loadMore()">
                        <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'common.load_more' | translate }}
                    </button>
                </div>
                <div fxFlex></div>
            </div>
        </div>
    </div>

    <!--
    <pre>
        {{ customersList | json }}
    </pre>
    -->
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
    <!--
    <ng-container *ngIf="authUserScope === 'global'">
        <button mat-button mat-raised-button color="primary" routerLinkActive="active" routerLink="." [queryParams]="{latest: true}">
            <mat-icon>update</mat-icon> Latest updates
        </button>
    </ng-container>
    -->

    <ng-container *ngIf="authUserRole === 'sys_admin'">
        <button mat-button color="warn" (click)="toggleAdmin()">
            <mat-icon class="kemppi-symbols">s</mat-icon>
        </button>

        <ng-container *ngIf="showAdminTools">
            <button mat-button mat-raised-button color="accent" (click)="recalculateSelectedMachines()" [disabled]="!anyMachineSelected">
                <mat-icon>fast_forward</mat-icon> Recalculate machine-infos
            </button>

            <button mat-button mat-raised-button color="warn" routerLinkActive="active" routerLink="/machine-event/new">
                <mat-icon>add</mat-icon> Create machine-event
            </button>
        </ng-container>
    </ng-container>

    <div fxFlex></div>

    <div>
        <button mat-button mat-raised-button color="accent" class="export-to-xlsx-button" (click)="exportSelectedMachinesToXLSX()" [disabled]="!anyMachineSelected">
            <mat-icon>open_in_new</mat-icon> XLSX
        </button>
    </div>

    <div fxFlex></div>
</ks-actionbar>
