import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from '../../../../services/state.service';
import { MachinesService } from '../../../../services/machines.service';

import { Machine } from '../../../../models/machine';
import { IMachineEvent, EpicorTransactionTypes, IEpicorTransactionType } from 'src/app/models/IMachineEvent';
import { UserProfile } from 'src/app/models/user';

import * as _ from 'lodash';

@Component({
    selector: 'app-machine-event',
    templateUrl: './machine-event.component.html',
    styleUrls: ['./machine-event.component.scss']
})
export class MachineEventComponent implements OnInit {

    machineEvent: IMachineEvent;
    id: string;

    state: any;

    user: UserProfile;
    userRole: string;

    showSalesDateApproved: boolean;

    showAdminTools = false;

    canEdit = false;
    canSave = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private authService: AuthService,
        public stateService: StateService,
        private machinesService: MachinesService
    ) {

        this.state = {
            busy: true
        };
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.hasBack = true;

            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;

            this.user = this.authService.userProfile;
            this.userRole = this.authService.userProfile.role;

            if (this.id === 'new') {
                this.canEdit = true;
                this.canSave = true;
                this.machineEvent = this.getNewMachineEvent();
                // Query params
                this.activatedRoute.queryParams.subscribe(params => {
                    if (params['serial']) {
                        this.machineEvent.serialNumber = params['serial'];
                        this.canEdit = false;
                    }
                    if (params['type']) {
                        this.machineEvent.eventType = params['type'];
                        this.canEdit = false;
                    }
                    this.changeEventType();
                });
            } else {
                this.getMachineEvent();
                if (this.userRole === 'sys_admin') {
                    this.canSave = true;
                }
            }
            this.state.busy = false;
        });
    }

    save() {
        this.machinesService.saveMachineEvent(this.machineEvent).subscribe((response) => {
            console.log('saveMachineEvent response', response);
            this.notify('Machine-event sent');
        }, (error) => {
            console.log('Error saving machine-event');
        });
    }

    cancel() {
        if (this.machineEvent && this.machineEvent.serialNumber && this.machineEvent.serialNumber.length > 0) {
            this.router.navigate(['machines', this.machineEvent.serialNumber]);
        } else {
            this.router.navigate(['machines']);
        }
    }

    getMachineEvent() {
        this.machinesService.getMachineEvent(this.id).subscribe((machineEvent: IMachineEvent) => {
            this.machineEvent = machineEvent;
            if (this.machineEvent.eventType === 'PARTNER_REGISTRATION') {
                this.showSalesDateApproved = this.machineEvent.data['PARTNER_REGISTRATION'].salesDateApproved ? true : false;
            }
        }, (error) => {
            console.log('Error loading machine-event');
        });
    }

    getNewMachineEvent() {
        const timestamp = new Date().toISOString();
        const machineEvent: IMachineEvent = {
            id: null,
            active: true,
            eventType: null,
            eventSource: 'MACHINERY',
            eventDescription: null,
            eventTimestamp: timestamp,
            serialNumber: null,
            systemTimestamp: timestamp,
            user: {
                id: this.user.id,
                name: this.user.name
            },
            data: {},
            schema: {
                type: 'machine-event',
                version: '1.0'
            },
            version: {}
        };
        console.log('new', machineEvent);
        return machineEvent;
    }

    changeEventType() {
        const timestamp = new Date().toISOString();
        delete this.machineEvent.data;
        this.machineEvent.data = {};
        const type = this.machineEvent.eventType;

        if (type === 'ERP_TRANSACTION') {
            this.machineEvent.data[type] = {
                productId: null,
                productName: null,
                company: null,
                partnerId: null,
                partnerName: null,
                transactionId: null,
                serialNumberStatus: null,
                secureId: null,
                orderNumber: null,
                shipNumber: null,
                jobNumber: null,
                stock: null,
                // customerGroupCode: null,
                sysRowId: null
            };
        } else if (type === 'PARTNER_REGISTRATION') {
            this.machineEvent.data[type] = {
                company: null,
                partnerId: null,
                partnerName: null,
                salesDate: timestamp
            };
        } else if (type === 'END_CUSTOMER_REGISTRATION') {
            this.machineEvent.data[type] = {
                organizationId: null,
                organizationName: null
            };
        } else if (type === 'ATTACH_TO_ORGANIZATION') {
            this.machineEvent.data[type] = {
                organizationId: null,
                organizationName: null
            };
        } else if (type === 'STOLEN') {
            this.machineEvent.data[type] = {
                reportedBy: null,
                description: null,
            };
        } else if (type === 'DESTROYED') {
            this.machineEvent.data[type] = {
                reportedBy: null,
                description: null,
            };
        }
    }

    changeTransactionId() {
        const type = this.machineEvent.eventType;
        const tranactionId = _.get(this.machineEvent, 'data[' + type + '].transactionId');
        if (tranactionId) {
            const epicorTransactionType = _.find(EpicorTransactionTypes, { transactionId: tranactionId }) as IEpicorTransactionType;
            if (epicorTransactionType) {
                _.set(this.machineEvent, 'data[' + type + '].serialNumberStatus', epicorTransactionType.serialNumberStatus);
            }
        }
    }

    toggleSalesDateApproment() {
        if (this.machineEvent.eventType === 'PARTNER_REGISTRATION') {
            if (this.showSalesDateApproved) {
                this.machineEvent.data['PARTNER_REGISTRATION'].salesDateApproved = {
                    timestamp: new Date().toISOString(),
                    user: {
                        id: this.user.id,
                        name: this.user.name
                    }
                };
            } else {
                this.machineEvent.data['PARTNER_REGISTRATION'].salesDateApproved = null;
            }
        }
    }

    notify(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 2000
        });
    }


    loadExampleData(type) {
        const timestamp = new Date().toISOString();
        if (type === 'manufacture') {
            this.machineEvent = {
                active: true,
                serialNumber: '7771001',
                eventType: 'ERP_TRANSACTION',
                eventSource: 'MACHINERY',
                eventTimestamp: timestamp,
                systemTimestamp: timestamp,
                user: {
                    id: 'test-script',
                    name: 'MachineRegistry'
                },
                data: {
                    ERP_TRANSACTION: {
                        productId: '6132420',
                        productName: null,
                        company: '100',
                        partnerId: null,
                        partnerName: null,
                        transactionId: 'MFG-STK',
                        serialNumberStatus: 'INVENTORY',
                        secureId: '1234',
                        orderNumber: null,
                        shipNumber: null,
                        jobNumber: 'K771001',
                        stock: 'HUB',
                        // customerGroupCode: null,
                        sysRowId: null
                    }
                },
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
        } else if (type === 'subsidiary') {
            this.machineEvent = {
                active: true,
                serialNumber: '7771001',
                eventType: 'ERP_TRANSACTION',
                eventSource: 'MACHINERY',
                eventTimestamp: timestamp,
                systemTimestamp: timestamp,
                user: {
                    id: 'test-script',
                    name: 'MachineRegistry'
                },
                data: {
                    ERP_TRANSACTION: {
                        productId: '6132420',
                        productName: null,
                        company: '100',
                        partnerId: '140',
                        partnerName: 'Kemppi Norge',
                        transactionId: 'STK-CUS',
                        serialNumberStatus: 'SHIPPED',
                        secureId: '1234',
                        orderNumber: 'K771001',
                        shipNumber: null,
                        jobNumber: 'K771001',
                        stock: 'HUB',
                        // customerGroupCode: 'E',
                        sysRowId: null
                    }
                },
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
        } else if (type === 'dealer') {
            this.machineEvent = {
                active: true,
                serialNumber: '7771001',
                eventType: 'ERP_TRANSACTION',
                eventSource: 'MACHINERY',
                eventTimestamp: timestamp,
                systemTimestamp: timestamp,
                user: {
                    id: 'test-script',
                    name: 'MachineRegistry'
                },
                data: {
                    ERP_TRANSACTION: {
                        productId: '6132420',
                        productName: null,
                        company: '140',
                        partnerId: 'NO123456',
                        partnerName: 'Example partner',
                        transactionId: 'STK-CUS',
                        serialNumberStatus: 'SHIPPED',
                        secureId: '1234',
                        orderNumber: 'K771001',
                        shipNumber: null,
                        jobNumber: 'K771001',
                        stock: 'HUB',
                        // customerGroupCode: 'B2',
                        sysRowId: null
                    }
                },
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
        } else if (type === 'stolen') {
            this.machineEvent = {
                active: true,
                serialNumber: '7771001',
                eventType: 'STOLEN',
                eventSource: 'MACHINERY',
                eventTimestamp: timestamp,
                systemTimestamp: timestamp,
                user: {
                    id: this.user.id,
                    name: this.user.name
                },
                data: {
                    STOLEN: {
                        reportedBy: '',
                        description: ''
                    }
                },
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
        } else if (type === 'destroyed') {
            this.machineEvent = {
                active: true,
                serialNumber: '7771001',
                eventType: 'DESTROYED',
                eventSource: 'MACHINERY',
                eventTimestamp: timestamp,
                systemTimestamp: timestamp,
                user: {
                    id: this.user.id,
                    name: this.user.name
                },
                data: {
                    DESTROYED: {
                        reportedBy: '',
                        description: ''
                    }
                },
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
        }
    }

    /**
     * Toggle admin tools
     */
    toggleAdmin() {
        if (this.userRole === 'sys_admin') {
            this.showAdminTools = !this.showAdminTools;
        }
    }

}
