import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';

import { ReportsService } from 'src/app/services/reports.service';

import { HistogramQueryParams } from 'src/app/models/report';
import { CompanyCodes } from 'src/app/models/company';

import { Chart } from 'angular-highcharts';

import * as _ from 'lodash';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

    state: any;
    public _: any = _;

    report = {
        filters: [],
        // field: 'productId',
        // aggs: ['count'],
        size: 100,
        // nested
        sumField: 'count',
        histogramField: 'eventTimestamp',
        histogramInterval: 'day'
    };

    filters = [
        { type: 'range', name: 'EVENT - Time', field: 'eventTimestamp' },
        { type: 'match', name: 'EVENT - Type', field: 'eventType',
            values: [
                'ERP_TRANSACTION',
                'PARTNER_REGISTRATION',
                'END_CUSTOMER_REGISTRATION',
                'STOLEN',
                'DESTROYED'
            ]
        },
        { type: 'match', name: 'EVENT - Source', field: 'eventSource',
            values: [
                'EPICOR',
                'IFS',
                'Z',
                'EWARRANTY',
                'MYFLEET'
            ]
        },
        { type: 'match', name: 'ERP - Transaction id', field: 'data.ERP_TRANSACTION.transactionId',
            values: [
                'DRP-CUS',
                'INS-REJ',
                'KIT-CUS',
                'MFG-CUS',
                'MFG-STK',
                'PUR-DRP',
                'PUR-STK',
                'RMA-INS',
                'STK-CUS',
                'STK-STK',
                'MAINT'
            ]
        },
        { type: 'match', name: 'ERP - Company', field: 'data.ERP_TRANSACTION.company', values: CompanyCodes },
        { type: 'match', name: 'ERP - Partner id', field: 'data.ERP_TRANSACTION.partnerId' },
        { type: 'match', name: 'ERP - Product id', field: 'data.ERP_TRANSACTION.productId' }
    ];

    fields = {
        eventTimestamp: { name: 'EVENT - time', field: 'eventTimestamp' },
        creationDate:   { name: 'VERSION - Creation date', field: 'version.created.timestamp' },
        modifiedDate:   { name: 'VERSION - Modified date', field: 'version.modified.timestamp' }
    };

    resultsHistogram: any;

    selectFilter: any;
    selectFilterValue: any;
    selectFilterStart: any;
    selectFilterEnd: any;

    public chart: any;
    public totalSum: number;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private reportsService: ReportsService,
        private snackBar: MatSnackBar
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;

            // Add default filter
            const now = new Date();
            const monthAgo = new Date(new Date().setMonth(now.getMonth() - 1));
            const yearAgo = new Date(new Date().setFullYear(now.getFullYear() - 1));
            this.report.filters.push({ type: 'range', name: 'EVENT - Time', field: 'eventTimestamp', rangeStart: monthAgo.toISOString(), rangeEnd: now.toISOString() });
            // Get histogram
            this.getEventsHistogram();
        });
    }

    getEventsHistogram() {
        console.log('report', this.report);

        const params: HistogramQueryParams = {
            filters: this.report.filters,
            // sumField: this.aggs[this.report.sumField] ? this.aggs[this.report.sumField].field : null,
            histogramField: this.fields[this.report.histogramField].field,
            histogramInterval: <any>this.report.histogramInterval
        };

        this.reportsService.getEventsHistogram(params).subscribe((response: any) => {
            this.resultsHistogram = response;
            console.log('resultsHistogram', this.resultsHistogram);
            this.totalSum = this.resultsHistogram.reduce(function (a, b) {
                return a + (params.sumField ? _.get(b, 'value.value') : _.get(b, 'doc_count'));
            }, 0);
            this.createChart();
        }, (error) => {
            this.showError(error);
        });
    }

    createChart() {
        const titleText = 'Machine-events: ' + this.totalSum;
        const subTitleText = this.report.filters.map(f => {
            if (f.type === 'range') {
                return '<div>' + f.name + ': ' + f.rangeStart.slice(0, 10) + ' - ' + f.rangeEnd.slice(0, 10) + '</div>';
            } else if (f.shouldValues) {
                return '<div>' + f.name + ': ' + f.shouldValues.join(' / ') + '</div>';
            } else {
                return '<div>' + f.name + ': ' + f.matchValue + '</div>';
            }
        }).join('');
        this.chart = new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: titleText,
            },
            subtitle: {
                text: subTitleText,
                useHTML: true
            },
            colors: ['#009dde'],
            credits: {
                enabled: false
            },
            xAxis: {
                categories: this.resultsHistogram.map(h => h.key_as_string.slice(0, 10))
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: ''
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            series: [
                {
                    type: 'column',
                    // name: 'SUM ' + this.params.field,
                    name: 'SUM',
                    data: this.resultsHistogram.map(h => Math.round(_.get(h, 'value.value', h.doc_count) * 100) / 100)
                }
            ]
        });
    }

    addReportFilter() {
        if (this.selectFilter && this.selectFilter.type === 'match') {
            if (Array.isArray(this.selectFilterValue)) {
                // Add SHOULD filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'should', field: this.selectFilter.field, shouldValues: this.selectFilterValue });
            } else if (this.selectFilterValue.indexOf(',') > -1) {
                // Array of values, convert to should filter
                let shouldValues = this.selectFilterValue.split(',');
                // Trim all values
                shouldValues = shouldValues.map(s => s.trim());
                // Add SHOULD filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'should', field: this.selectFilter.field, shouldValues: shouldValues });
            } else {
                // Add MATCH filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'match', field: this.selectFilter.field, matchValue: this.selectFilterValue });
            }
            this.selectFilter = null;
            this.selectFilterValue = null;
        } else if (this.selectFilter && this.selectFilter.type === 'range') {
            // Add RANGE filter
            this.report.filters.push({ name: this.selectFilter.name, type: 'range', field: this.selectFilter.field, rangeStart: this.selectFilterStart, rangeEnd: this.selectFilterEnd });
            this.selectFilter = null;
            this.selectFilterStart = null;
            this.selectFilterEnd = null;
        }
    }

    editReportFilter(index) {
        this.selectFilter = null;
        this.selectFilterValue = null;
        this.selectFilterStart = null;
        this.selectFilterEnd = null;
        const filter = this.report.filters[index];
        this.selectFilter = _.find(this.filters, { field: filter.field });
        console.log('FILTER', this.selectFilter);
        if (filter.type === 'should') {
            this.selectFilterValue = filter.shouldValues;
        } else if (filter.type === 'match') {
            this.selectFilterValue = filter.matchValue;
        } else if (filter.type === 'range') {
            this.selectFilterStart = filter.rangeStart;
            this.selectFilterEnd = filter.rangeEnd;
        }
        this.report.filters.splice(index, 1);
    }

    removeReportFilter(index) {
        this.report.filters.splice(index, 1);
    }


    showError(error) {
        const message = _.get(error, 'message');
        const status = _.get(error, 'status');
        const type = _.get(error, 'type') || _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'reason') || _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        if (status === 401) {
            this.snackBar.open('Authorization error (no permission)', 'OK', {
                duration: 5000,
                panelClass: 'error-message'
            });
        } else {
            this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
                duration: 5000,
                panelClass: 'error-message'
            });
        }
    }

}
