
<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Ź</mat-icon>
            <div fxLayout="column">
                <h2>Customers</h2>
                <div>Get customer details, find by partner name or id</div>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Search customers
                <ng-container *ngIf="searchCompany === ''">(global)</ng-container>
                <ng-container *ngIf="searchCompany !== ''">({{ searchCompany === '' ? 'global' : searchCompany}} - {{_.find(companies, { id: searchCompany })?.name }})</ng-container>
            </h2>
            <div class="full" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">

                <form class="full">
                    <mat-form-field class="full" fxFlex.gt-xs="380px">
                        <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                        <input type="text" matInput [formControl]="customersFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPartner" (keyup)="searchCustomers()">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeCustomer($event.option)">
                        <mat-option *ngFor="let customer of customersList" [value]="customer">
                            <small fxFlex>{{ customer.name }}</small>
                            <small fxFlex="10px"></small>
                            <small fxFlex="40px"><b>{{ customer.company }}</b></small>
                            <small fxFlex="80px"><b>{{ customer.custId }}</b></small>
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>

                <mat-form-field *ngIf="authUserScope === 'global'" fxFlex.gt-xs="200px">
                    <mat-label>{{ 'common.company' | translate }}</mat-label>
                    <mat-select [(value)]="searchCompany">
                        <mat-option value="">Any</mat-option>
                        <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchPartnerId" (keydown.enter)="getCustomer()">
                </mat-form-field>
                <div fxFlex.gt-xs="100px">
                    <button mat-raised-button *ngIf="!loadingInfo" color="accent" (click)="getCustomer()" [disabled]="!searchCompany || searchCompany === '' || !searchPartnerId || searchPartnerId === ''">{{ 'common.search' | translate }}</button>
                    <mat-progress-spinner matSuffix *ngIf="loadingInfo" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                </div>
            </div>
        </div>

        <div *ngIf="customerInfo" class="full container">
            <h2 class="mat-subhead">Customer / partner info</h2>
            <div class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <mat-form-field fxFlex.gt-xs="380px">
                    <input matInput placeholder="Partner name" [(ngModel)]="customerInfo.name" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="80px">
                    <input matInput placeholder="Company" [(ngModel)]="customerInfo.company" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="100px">
                    <input matInput placeholder="Partner ID" [(ngModel)]="customerInfo.custId" [readonly]="true">
                </mat-form-field>
            </div>
            <div class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <mat-form-field fxFlex.gt-xs="80px">
                    <input matInput placeholder="Group code" [(ngModel)]="customerInfo.groupCode" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="280px">
                    <input matInput placeholder="Group description" [(ngModel)]="customerInfo.groupDesc" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="80px">
                    <input matInput placeholder="Lang" [(ngModel)]="customerInfo.langNameId" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="80px">
                    <input matInput placeholder="Currency" [(ngModel)]="customerInfo.currencyCode" [readonly]="true">
                </mat-form-field>
            </div>
            <div *ngIf="customerInfo.address" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <mat-form-field fxFlex.gt-xs="380px">
                    <input matInput placeholder="Address" [(ngModel)]="customerInfo.address.address" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="80px">
                    <input matInput placeholder="Zip" [(ngModel)]="customerInfo.address.zip" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="120px">
                    <input matInput placeholder="City" [(ngModel)]="customerInfo.address.city" [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="120px">
                    <input matInput placeholder="Country" [(ngModel)]="customerInfo.address.country" [readonly]="true">
                </mat-form-field>
            </div>
            <div>
                <h2 class="mat-subhead">Meta</h2>
                <small class="weak"><i>(This information is maintained in ERP and replicated to Machinery)</i></small><br>
                <small class="weak"><i>Updated: {{customerInfo.updated | date:'dd.MM.yyyy HH:mm'}}</i></small>
            </div>
        </div>

    </div>

    <!--
    <pre>
        customerInfo:
        {{ customerInfo | json }}

        customersList:
        {{ customersList | json }}
    </pre>
    -->
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
</ks-actionbar>
