import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CallbackComponent } from './modules/base/components/callback/callback.component';
import { CustomersPageComponent } from './modules/customers/components/customers-page/customers-page.component';
import { LandingPageComponent } from './modules/base/components/landing-page/landing-page.component';
import { ListMachinesPageComponent } from './modules/machines/components/list-machines-page/list-machines-page.component';
import { ListMachineEventsPageComponent } from './modules/machines/components/list-machine-events-page/list-machine-events-page.component';
import { LoginPageComponent } from './modules/base/components/login-page/login-page.component';
import { LoginSelectPageComponent } from './modules/base/components/login-select-page/login-select-page.component';
import { MachineEventComponent } from './modules/machines/components/machine-event/machine-event.component';
import { MachinePageComponent } from './modules/machines/components/machine-page/machine-page.component';
import { PartsPageComponent } from './modules/parts/components/parts-page/parts-page.component';
import { TestSeriePageComponent } from './modules/reports/components/test-serie-page/test-serie-page.component';
import { DashboardPageComponent } from './modules/reports/components/dashboard-page/dashboard-page.component';

import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';


// TODO: remove imports and actual implementations of editorpage and rulespage
const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'customers',
        component: CustomersPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'machines',
        component: ListMachinesPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'machines/:id',
        component: MachinePageComponent,
        canActivate: [AuthService],
        runGuardsAndResolvers: 'paramsChange'
    },
    {
        path: 'machine-event/:id',
        component: MachineEventComponent,
        canActivate: [AuthService],
        runGuardsAndResolvers: 'paramsChange'
    },
    {
        path: 'machine-events',
        component: ListMachineEventsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'parts',
        component: PartsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testlogin',
        component: LoginPageComponent
    },
    {
        path: 'selectlogin',
        component: LoginSelectPageComponent
    },
    {
        path: 'dashboard',
        component: DashboardPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testseries',
        component: TestSeriePageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testseries/:id',
        component: TestSeriePageComponent,
        canActivate: [AuthService]
    },
    /*
    {
        path: 'configurator/configurations/:id',
        component: ConfOrderPageComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuard]
    },
    */
    {
        path: '**', redirectTo: ''
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })], // Force component reload when navigating to same component
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
