import { Injectable } from '@angular/core';

export class ApplicationConfiguration {
    /**
     * Environment dev | stage | prod
     */
    environment: string;

    /**
     * Warranty application frontend URL
     */
    frontendUrl: string;

    /**
     * Machinery backend URL
     */
    machineryBackendUrl: string;

    /**
     * Warranty application frontend URL
     */
    warrantyFrontendUrl: string;

    /**
     * Warranty application backend URL
     */
    warrantyBackendUrl: string;

    /**
     * Kemppi IAM URL (Salesforce)
     * @deprecated Replaced with oauth2Domain
     */
    kemppiIamUrl: string;

    /**
     * Kemppi IAM Client ID (Salesforce)
     * @deprecated Replaced with oauth2ClientId
     */
    kemppiIamClientId: string;

    /**
     * Kemppi IAM portal
     */
    kemppiIamPortal: string;

    /**
     * Kemppi IAM Domain (Auth0)
     */
    oauth2Domain: string;

    /**
     * Kemppi IAM Client ID (Auth0)
     */
    oauth2ClientId: string;
}

@Injectable()
export class AppConfigService {
    constructor() { }

    get config(): ApplicationConfiguration {
        // this is set in main.ts which loads /config.json with AJAX
        return window['config'];
    }
}
