<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon>change_history</mat-icon>
            <div fxLayout="column">
                <h2>Machine events</h2>
                <div>Search serial numbered welding machine events</div>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Search machine events</h2>
            <div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchSerialNumber" (keydown.enter)="search()">
                </mat-form-field>
                <!--
                <mat-form-field>
                    <mat-label>{{ 'common.type' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchEventType" (keydown.enter)="search()">
                </mat-form-field>
                -->
                <mat-form-field fxFlex="320px">
                    <mat-label>Event type</mat-label>
                    <mat-select [(value)]="searchEventType" (selectionChange)="search()">
                        <mat-option value="">ALL</mat-option>
                        <mat-option value="ATTACH_TO_ORGANIZATION">ATTACH TO ORGANIZATION</mat-option>
                        <mat-option value="DESTROYED">DESTROYED</mat-option>
                        <mat-option value="END_CUSTOMER_REGISTRATION">END CUSTOMER REGISTRATION</mat-option>
                        <mat-option value="ERP_TRANSACTION">ERP TRANSACTION</mat-option>
                        <mat-option value="PARTNER_REGISTRATION">PARTNER REGISTRATION</mat-option>
                        <mat-option value="STOLEN">STOLEN</mat-option>
                    </mat-select>
                </mat-form-field>
                <!--
                <mat-form-field>
                    <mat-label>{{ 'common.source' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchEventSource" (keydown.enter)="search()">
                </mat-form-field>
                -->
                <mat-form-field fxFlex="320px">
                    <mat-label>Event source</mat-label>
                    <mat-select [(value)]="searchEventSource" (selectionChange)="search()">
                        <mat-option value="">ALL</mat-option>
                        <mat-option value="EPICOR">EPICOR</mat-option>
                        <mat-option value="EWARRANTY">EWARRANTY</mat-option>
                        <mat-option value="MACHINERY">MACHINERY</mat-option>
                        <mat-option value="MACHINEREGISTRY">MACHINEREGISTRY</mat-option>
                        <mat-option value="MANUAL">MANUAL</mat-option>
                        <mat-option value="MOBILEMAINTENANCE">MOBILEMAINTENANCE</mat-option>
                        <mat-option value="MYFLEET">MYFLEET</mat-option>
                        <mat-option value="WELDEYE">WELDEYE</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="320px">
                    <mat-label>Transaction id</mat-label>
                    <mat-select [(value)]="searchTransactionId" (selectionChange)="search()">
                        <mat-option value="">ALL</mat-option>
                        <mat-option value="DRP-CUS">DRP-CUS (Drop shipment to a customer)</mat-option>
                        <mat-option value="INS-REJ">INS-REJ (Inspection to reject)</mat-option>
                        <mat-option value="KIT-CUS">KIT-CUS (Shipment of a kit component to customer)</mat-option>
                        <mat-option value="MFG-CUS">MFG-CUS (Manufacturing receipt to customer)</mat-option>
                        <mat-option value="MFG-STK">MFG-STK (Manufacturing receipt to stock)</mat-option>
                        <mat-option value="PUR-DRP">PUR-DRP (Purchase receipt to drop shipment)</mat-option>
                        <mat-option value="PUR-STK">PUR-STK (Purchase receipt to stock)</mat-option>
                        <mat-option value="RMA-INS">RMA-INS (RMA receipt to inspection)</mat-option>
                        <mat-option value="STK-CUS">STK-CUS (Shipment of stock to customer)</mat-option>
                        <mat-option value="STK-STK">STK-STK (Stock to stock transfer)</mat-option>
                        <mat-option value="MAINT">MAINT (Maintenance)</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>Date type</mat-label>
                    <mat-select [(value)]="dateFilterField" fxFlex="180px" (selectionChange)="search()">
                        <mat-option value="">-</mat-option>
                        <mat-option value="eventTimestamp">Event timestamp</mat-option>
                        <mat-option value="systemTimestamp">System timestamp</mat-option>
                        <mat-option value="version.modified.timestamp">Updated</mat-option>
                    </mat-select>
                </mat-form-field>
                <war-datepicker [(ngModel)]="dateFilterStart" [label]="'Start date'" (changeEvent)="search()"></war-datepicker>
                <war-datepicker [(ngModel)]="dateFilterEnd" [label]="'End date'" [endOfDay]="true" (changeEvent)="search()"></war-datepicker>
                <div fxFlex></div>
            </div>
        </div>

        <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40px">
                <mat-checkbox [(ngModel)]="allEventsChecked" (change)="selectAllEvents(allEventsChecked)">
                </mat-checkbox>
            </div>
            <div fxFlex="80px">
                <small (click)="sort('serialNumber')">Serial number</small>
                <mat-icon *ngIf="sortParams.key === 'serialNumber'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="80px">
                <small (click)="sort('eventTimestamp')">Event time</small>
                <mat-icon *ngIf="sortParams.key === 'eventTimestamp'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="200px">
                <small (click)="sort('eventType')">Type</small>
                <mat-icon *ngIf="sortParams.key === 'eventType'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="130px">
                <small (click)="sort('systemTimestamp')">System time</small>
                <mat-icon *ngIf="sortParams.key === 'systemTimestamp'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="150px">
                <small (click)="sort('eventSource')">Source</small>
                <mat-icon *ngIf="sortParams.key === 'eventSource'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="80px">
                <small (click)="sort('data.ERP_TRANSACTION.transactionId')">TransactionId</small>
                <mat-icon *ngIf="sortParams.key === 'data.ERP_TRANSACTION.transactionId'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex>
                <small (click)="sort('eventDescription')">Description</small>
                <mat-icon *ngIf="sortParams.key === 'eventDescription'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="80px">
                <small (click)="sort('version.modified.timestamp')">Updated</small>
                <mat-icon *ngIf="sortParams.key === 'version.modified.timestamp'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="80px">
                <small (click)="sort('active')">Active</small>
                <mat-icon *ngIf="sortParams.key === 'active'" class="sort-arrow">
                    {{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngFor="let mEvent of machineEvents">
                <div class="list-row" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="40px" class="select-checkbox">
                        <mat-checkbox [(ngModel)]="selectedEvents[mEvent.id]"
                            (change)="checkSelectedEvents()"></mat-checkbox>
                    </div>
                    <div fxFlex (click)="openMachineEvent(mEvent.id)">
                        <div fxFlex="80px">
                            {{mEvent.serialNumber}}
                        </div>
                        <div fxFlex="80px">
                            {{mEvent.eventTimestamp | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxFlex="200px">
                            {{mEvent.eventType}}
                        </div>
                        <div fxFlex="130px" class="date">
                            {{mEvent.systemTimestamp | date:'dd.MM.yyyy HH:mm:ss'}}
                        </div>
                        <div fxFlex="150px">
                            {{mEvent.eventSource}}
                        </div>
                        <div fxFlex="80px">
                            <ng-container *ngIf="mEvent.data && mEvent.data.ERP_TRANSACTION">
                                {{mEvent.data.ERP_TRANSACTION.transactionId}}
                            </ng-container>
                        </div>
                        <div fxFlex>
                            {{mEvent.eventDescription}}
                        </div>
                        <div fxFlex="80px" class="date">
                            {{mEvent.version?.modified.timestamp | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxFlex="80px">
                            {{mEvent.active ? 'true' : 'NOT ACTIVE'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div fxLayout="row" class="width100">
                <div fxFlex>
                    <small><i>{{ 'common.results' | translate }}: {{resultHits}} / {{totalHits}}</i></small>
                </div>
                <div fxFlex fxLayoutAlign="center center">
                    <mat-progress-spinner matSuffix *ngIf="eventsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                    <button *ngIf="!eventsLoading && totalHits > resultHits" mat-button color="primary" (click)="loadMore()">
                        <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'common.load_more' | translate }}
                    </button>
                </div>
                <div fxFlex></div>
            </div>
        </div>
    </div>

    <!--
    <pre>
        {{ customersList | json }}
    </pre>
    -->
</div>

<ks-actionbar [hasCancel]="false" [hasSave]="false">
    <!--
    <ng-container *ngIf="authUserScope === 'global'">
        <button mat-button mat-raised-button color="primary" routerLinkActive="active" routerLink="." [queryParams]="{latest: true}">
            <mat-icon>update</mat-icon> Latest updates
        </button>
    </ng-container>
    -->

    <ng-container *ngIf="authUserRole === 'sys_admin'">
        <button mat-button color="warn" (click)="toggleAdmin()">
            <mat-icon class="kemppi-symbols">s</mat-icon>
        </button>

        <ng-container *ngIf="showAdminTools">
            <button *ngIf="anyEventSelected" mat-button mat-raised-button color="accent" (click)="recalculateSelectedMachines()">
                <mat-icon>fast_forward</mat-icon> Recalculate machine-infos
            </button>

            <button mat-button mat-raised-button color="warn" routerLinkActive="active" routerLink="/machine-event/new">
                <mat-icon>add</mat-icon> Create machine-event
            </button>

            <button *ngIf="anyEventSelected" mat-button mat-raised-button color="warn" (click)="setSelectedEventActive(true)">
                <mat-icon>check_circle_outline</mat-icon> SET ACTIVE = true
            </button>

            <button *ngIf="anyEventSelected" mat-button mat-raised-button color="warn" (click)="setSelectedEventActive(false)">
                <mat-icon>block</mat-icon> SET ACTIVE = NOT ACTIVE
            </button>
        </ng-container>
    </ng-container>

    <div fxFlex></div>

    <button mat-button mat-raised-button color="accent" class="export-to-xlsx-button" (click)="exportSelectedToXLSX()" [disabled]="!anyEventSelected">
        <mat-icon>open_in_new</mat-icon> XLSX
    </button>

    <div fxFlex></div>
</ks-actionbar>