export enum X8ErrorType {
    WARNING = 1,
    LOW = 2,
    NORMAL = 3,
    HIGH = 4,
    FATAL = 5
};

export enum X8ErrorCode {
    ERROR_PS_NOTCALIBRATED = 1,
    ERROR_PS_UNDERVOLTAGE = 2,
    ERROR_PS_OVERVOLTAGE = 3,
    ERROR_PS_OVERHEAT = 4,
    ERROR_PS_LOWAUXVOLTAGE = 5,
    ERROR_PS_INTERNALWIRING = 6,
    ERROR_PS_NOMIGUNIT = 7,
    ERROR_PS_FPGACONFIG = 8,
    ERROR_PS_MEASUREMENTCABLE = 9,
    ERROR_PS_ILLEGALPROCESS = 10,
    ERROR_PS_CUTTINGSWITCH = 11,
    ERROR_PS_WELDINGCABLES = 12,
    ERROR_PS_IGBTOVERCURRENT = 13,
    ERROR_PS_IGBTOVERHEAT = 14,
    ERROR_PS_DIXOVERHEAT = 15,
    ERROR_PS_TRANSFORMERSOVERHEAT = 16,
    ERROR_PS_SUPPLYPHASEMISSING = 17,
    ERROR_PS_INTERMEDIATECAPASITORS = 18,
    ERROR_PS_PRIMARYCURRENTS = 19,
    ERROR_PS_COOLINGFAILURE = 20,
    ERROR_WC_NOTCALIBRATED = 21,
    ERROR_WC_UNDERPRESSURE = 22,
    ERROR_WC_OVERPRESSURE = 23,
    ERROR_WC_OVERHEAT = 24,
    ERROR_WC_LOWLEVEL = 25,
    ERROR_WC_NOFLOW = 26,
    ERROR_WC_GENERALFAILURE = 27,
    ERROR_PS_VRD = 40,
    ERROR_MU_NOTCALIBRATED = 41,
    ERROR_MU_WFMOTORHIGHCURRENT = 42,
    ERROR_MU_WFMOTOROVERCURRENT = 43,
    ERROR_MU_TACHOSIGNALMISSING = 44,
    ERROR_MU_LOWGASPRESSURE = 45,
    ERROR_MU_GUNOVERHEAT = 46,
    ERROR_MU_DOOROPEN = 47,
    ERROR_MU_NOCONFIGURATION = 48,
    ERROR_MU_WRONGLICENSECODE = 49,
    ERROR_MU_NOLICENSE = 50,
    ERROR_MU_REARWHEELSLOOSE = 51,
    ERROR_MU_FRONTWHEELSLOOSE = 52,
    ERROR_MU_COLLISIONDETECTED = 53,
    ERROR_MU_BLUETOOTH = 54,
    ERROR_MU_DIXOVERHEAT = 55,
    ERROR_ML_STARTBLOCKED = 61,
    ERROR_ML_NOPOWERSOURCE = 62,
    ERROR_ML_NOWATERCOOLER = 63,
    ERROR_ML_NOROBOTINTERFACE = 64,
    ERROR_ML_SUBFEEDERNOTALLOWED = 65,
    ERROR_CA_NOWELDINGPROGRAM = 81,
    ERROR_CA_ILLEGALPARAMETER = 82,
    ERROR_CA_UNKNOWNPARAMETER = 83,
    ERROR_CA_ILLEGALCURVE = 84,
    ERROR_UI_PMTGUNBLOCKED = 101,
    ERROR_UI_TOOHIGHCURRENT = 102,
    ERROR_UI_EMPTYMEMORYCHANNEL = 103,
    ERROR_RI_FIELDBUSINITFAILED = 131,
    ERROR_RI_ROBOTNOTRESPONDING = 132,
    ERROR_RI_EMERGENCYSTOP = 133,
    ERROR_RI_DOORSWITCH = 134,
    ERROR_RI_LOGBOOK_NO_MEDIA = 135,
    ERROR_RI_LOGBOOK_MEDIA_BROKEN = 136,
    ERROR_RI_LOGBOOK_UNFORMATTED = 137,
    ERROR_RI_NOLICENSE = 138,
    ERROR_RI_NOWIREFEEDER = 139,
    ERROR_RI_LOGBOOK_HARDWARE = 140,
    ERROR_IO_DIN1_FAILURE = 201,
    ERROR_IO_DIN2_FAILURE = 202,
    ERROR_IO_DIN3_FAILURE = 203,
    ERROR_IO_DIN4_FAILURE = 204,
    ERROR_IO_DIN5_FAILURE = 205,
    ERROR_IO_DIN6_FAILURE = 206,
    ERROR_IO_DIN7_FAILURE = 207,
    ERROR_IO_DIN8_FAILURE = 208,
    ERROR_IO_DIN1_WARNING = 209,
    ERROR_IO_DIN2_WARNING = 210,
    ERROR_IO_DIN3_WARNING = 211,
    ERROR_IO_DIN4_WARNING = 212,
    ERROR_IO_DIN5_WARNING = 213,
    ERROR_IO_DIN6_WARNING = 214,
    ERROR_IO_DIN7_WARNING = 215,
    ERROR_IO_DIN8_WARNING = 216,
    ERROR_CP_BATTERY_1 = 221,
    ERROR_CP_BATTERY_2 = 222,
    ERROR_CP_WLAN = 223,
    ERROR_CP_ACCELEROMETER = 224,
    ERROR_CP_BARCODEREADER = 225,
    ERROR_CP_NFC = 226,
    ERROR_CP_TEMPERATURE = 227,
    ERROR_CP_BLUETOOTH = 228,
    ERROR_CP_CTRLCPU = 229,
    ERROR_ICT_NOFRAMESERIALNUMBER = 238,
    ERROR_ICT_BLUETOOTH = 239,
    ERROR_ICT_WLAN = 240,
    ERROR_SYS_SYSTEMBUS = 241,
    ERROR_SYS_REMOTEBUS = 242,
    ERROR_SYS_WIRELESSBUS = 243,
    ERROR_SYS_FILESYSTEM = 244,
    ERROR_SYS_TRIALTIMEWARNING = 245,
    ERROR_SYS_TRIALTIMEFINISHED = 246,
    ERROR_SYS_NODATABASEMEMORY = 250,
    ERROR_SYS_SAMEDEVICEID = 251,
    ERROR_SYS_SAMEDATAMASK = 252,
    ERROR_SYS_WRONGSYSTEM = 253,
    ERROR_SYS_NORESPONSE = 254,
    ERROR_SYS_WRONGSOFTWARE = 255
};
