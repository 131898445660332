import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable()
export class CurrenciesService {

    BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
        this.token = authService.accessToken;
    }

    getCurrencyInfo(currencyCode, targetDate) {
        console.log('getCurrencyInfo', currencyCode, targetDate);
        return this.http.get(`${this.BACKENDURL}/currencies/${currencyCode}/${targetDate}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }
}

export interface ICurrencyInfo {
    id: string;
    sourceCurrCode: string;
    effectiveDate: string;
    currentRate: number;
    targetCurrCode: string;
}
