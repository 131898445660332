import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

import { PartsPageComponent } from './components/parts-page/parts-page.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
        MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        FormsModule, ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule
    ],
    declarations: [
        PartsPageComponent
    ],
    exports: [
        PartsPageComponent
    ]
})
export class PartsModule {
}
