
<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon>memory</mat-icon>
            <div fxLayout="column">
                <h2>Test series</h2>
                <div>Search test series linked serial number and warranty claims</div>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Search</h2>
            <div class="full" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">

                <!--
                <form class="full">
                    <mat-form-field class="full" fxFlex.gt-xs="380px">
                        <mat-label>{{ 'common.product_name' | translate }}</mat-label>
                        <input type="text" matInput [formControl]="partsFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPart" (keyup)="searchParts()">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changePart($event.option)">
                        <mat-option *ngFor="let part of partsList" [value]="part">
                            <small fxFlex>{{part.partDescription}}</small>
                            <small fxFlex="10px"></small>
                            <small fxFlex="100px"><b>{{ part.id }}</b></small>
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
                -->

                <mat-form-field>
                    <mat-label>{{ 'common.test_serie' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchTestSerieId" (keydown.enter)="getTestSerie()">
                </mat-form-field>
                <div fxFlex.gt-xs="100px">
                    <button mat-raised-button *ngIf="!loading" color="accent" (click)="getTestSerie()">{{ 'common.search' | translate }}</button>
                    <mat-progress-spinner matSuffix *ngIf="loading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                </div>
                <div fxFlex></div>
            </div>
        </div>

        <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40px">
                <mat-checkbox [(ngModel)]="allItemsChecked" (change)="selectAllItems(allItemsChecked)"></mat-checkbox>
            </div>
            <div fxFlex="80px">
                <small>Serial number</small>
            </div>
            <div fxFlex="100px">
                <small>Product number</small>
            </div>
            <div fxFlex="80px">
                <small>Test serie</small>
            </div>
            <div fxFlex="80px">
                <small>Date</small>
            </div>
            <div fxFlex="360px">
                <small>Warranty claims</small>
            </div>
        </div>
        <div *ngIf="testSeries" fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngFor="let testSerie of testSeries">
                <div class="list-row" fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="40px" class="select-checkbox">
                            <mat-checkbox [(ngModel)]="selectedItems[testSerie.id]" (change)="checkSelectedItems()"></mat-checkbox>
                        </div>
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            <a [routerLink]="['/machines', testSerie.serialNumber]">{{testSerie.serialNumber}}</a>
                        </div>
                        <div fxFlex="100px" fxLayoutAlign="start center">
                            {{testSerie.productNumber}}
                        </div>
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            {{testSerie.testSerie}}
                        </div>
                        <div fxFlex="80px" class="date" fxLayoutAlign="start center">
                            {{testSerie.timestamp | date:'dd.MM.yyyy'}}
                        </div>
                        <div fxFlex="40px" *ngIf="testSerie.warrantyClaims">
                            {{ testSerie.warrantyClaims.length }}
                        </div>
                    </div>
                    <div fxLayout="row" *ngFor="let warrantyClaim of testSerie.warrantyClaims" fxLayoutAlign="start center">
                        <div fxFlex="40px"></div>
                        <div fxFlex="80px"></div>
                        <div fxFlex="100px"></div>
                        <div fxFlex="80px"></div>
                        <div fxFlex="80px"></div>
                        <div fxFlex="40px"></div>
                        <div fxFlex="100px" fxLayoutAlign="start center">
                            <a [href]="EWARRANTY_FRONTEND_URL + '/claims/' + warrantyClaim.company + '/' + warrantyClaim.partnerId + '/' + warrantyClaim.id" target="_blank">
                                {{ warrantyClaim.id }}
                            </a>
                        </div>
                        <div fxFlex="60px" fxLayoutAlign="start center">
                            {{ warrantyClaim.company }}
                        </div>
                        <div fxFlex="80px" fxLayoutAlign="start center">
                            {{ warrantyClaim.partnerId }}
                        </div>
                        <div fxFlex>
                            {{ warrantyClaim.partnerName }}
                        </div>
                        <div fxFlex="80px" class="date" fxLayoutAlign="start center">
                            {{warrantyClaim.data.repairDate | date:'dd.MM.yyyy'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div fxLayout="row" class="width100">
                <div fxFlex>
                    <small><i>{{ 'common.results' | translate }}: {{testSeries.length}}</i></small>
                </div>
            </div>

        </div>


        <!--
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>Product info</legend>
                <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                    <mat-form-field fxFlex.gt-xs="380px">
                        <input matInput placeholder="Product name" [(ngModel)]="partInfo.partDescription"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex.gt-xs="180px">
                        <input matInput placeholder="Product number" [(ngModel)]="partInfo.partNumber" [readonly]="true">
                    </mat-form-field>
                </div>
                <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Prod code" [(ngModel)]="partInfo.prodCode" [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Class ID" [(ngModel)]="partInfo.classID" [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Sales UM" [(ngModel)]="partInfo.salesUM" [readonly]="true">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Category code" [(ngModel)]="partInfo.categoryCode"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Family code" [(ngModel)]="partInfo.familyCode"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Sales ABC" [(ngModel)]="partInfo.salesABC" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Identification" [(ngModel)]="partInfo.identification" [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Repair time" [(ngModel)]="partInfo.repairTime" [readonly]="true">
                            <span matSuffix>min</span>
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Warranty code" [(ngModel)]="partInfo.warrantyCode" [readonly]="true">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Inactive" [(ngModel)]="partInfo.inactive" [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Is software" [(ngModel)]="partInfo.isSoftware" [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                            <input matInput placeholder="Run out" [(ngModel)]="partInfo.runOut" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="partInfo && partInfo.partDescriptions" class="full" fxLayout="row" class="weak small">
                    <div fxLayout="column">
                        <div fxLayout="row">
                            <div fxFlex="50px">DAN</div>{{partInfo.partDescriptions.dan}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">DEU</div>{{partInfo.partDescriptions.deu}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">ENU</div>{{partInfo.partDescriptions.enu}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">FIN</div>{{partInfo.partDescriptions.fin}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">FRA</div>{{partInfo.partDescriptions.fra}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">NLD</div>{{partInfo.partDescriptions.nld}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">NOR</div>{{partInfo.partDescriptions.nor}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">PLK</div>{{partInfo.partDescriptions.plk}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">RUS</div>{{partInfo.partDescriptions.rus}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">SCH</div>{{partInfo.partDescriptions.sch}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">SVE</div>{{partInfo.partDescriptions.sve}}
                        </div>
                    </div>
                </div>

                <div *ngIf="partInfo">
                    <small class="weak"><i>(This information is maintained in ERP and replicated to Machinery)</i></small><br>
                    <small class="weak"><i>Updated: {{partInfo.updated | date:'dd.MM.yyyy HH:mm'}}</i></small>
                </div>
                <div *ngIf="!partInfo">
                    <small class="weak"><i>Find part by name or enter part id</i></small>
                </div>
            </fieldset>
        </div>
        -->
    </div>

    <!--
    <pre>
        testSeries:
        {{ testSeries | json }}
    </pre>
    -->
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
    <div fxFlex></div>

    <div>
        <button mat-button mat-raised-button color="accent" class="export-to-xlsx-button" (click)="exportSelectedItemsToXLSX()" [disabled]="!anyItemSelected">
            <mat-icon>open_in_new</mat-icon> XLSX
        </button>
    </div>

    <div fxFlex></div>
</ks-actionbar>
