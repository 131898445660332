/**
 * Machine event
 * version 1.0
 * Machinery machine info is collection of machine events.
 * Events are collected from ERP, Machinery, eWarranty, WeldEye, MyFleet.
 * Every event effects some way to the current machine info and state,
 * and when new event is occurred new machine info is processed.
 *
 * For new events add them to eventType list, and data object.
 * Generate new interface for event data.
 * (Easier to create new event publisher and handler from it)
 */
export interface IMachineEvent {

    /**
     * Id = Autogenerated identifier of event.
     * [serialNumber]-[eventTimestamp(yyyyMMddHHmmss)]-[eventType]-[systemTimestamp(yyyyMMddHHmmss)]
     * 12345678-20181015120000-ERP_TRANSACTION-20181015120000
     * Customized id pattern allows us to overwrite already existing events if needed. (Migration runs from other services)
     */
    id?: string;

    /**
     * Active flag.
     * Default event is always active
     * By admins the event can be set to not active state when it is not effecting to machine info generation
     */
    active?: boolean;

    /**
     * Event type
     * Every event type is handled different way and is effecting to different attributes of machine.
     * Every event type have to have same named object inside data
     *
     * eventType: 'ERP_TRANSACTION'
     * data: {
     *   ERP_TRANSACTION {
     *     transactionId: ''
     *   }
     * }
     *
     */
    eventType: 'ERP_TRANSACTION'
            | 'PARTNER_REGISTRATION'
            | 'END_CUSTOMER_REGISTRATION'
            | 'ATTACH_TO_ORGANIZATION'
            | 'STOLEN'
            | 'DESTROYED'
            | 'ASSEMBLY'
            | 'WARRANTY_CLAIM'
            | 'VALIDATION'
    ;

    /**
     * Event source = Application where the event is coming from.
     */
    eventSource: 'MACHINERY' | 'MACHINEREGISTRY'
            | 'EPICOR' | 'IFS' | 'Z'
            | 'FACTORY'
            | 'MANUAL' | 'FIX' | 'TEST'
            | 'EWARRANTY' | 'MYFLEET' | 'WELDEYE' | 'ARCVALIDATOR';

    /**
     * Event description
     * Leave empty to get auto filled default description
     * Shown in MachineRegistry UI events lists
     */
    eventDescription?: string;

    /**
     * Event timestamp = When the actual event happened, since when it is effective
     * For example Epicor SNTran.TranDate (accurated by date) or current timestamp from other sources
     * UTC ISOSTRING FORMAT!
     * '2018-10-05T00:00:00.000Z' 'yyyy-MM-ddTHH:mm:ss.zzzZ'
     * Event times are always stored as in utc
     * application that gets data back should handle converting it to local time based on users timezone
     */
    eventTimestamp: string;

    /**
     * Serial number of machine
     * EXTERNAL SERIAL
     * (Same that is generated by Epicor and is engraved to rating plate)
     */
    serialNumber: string;

    /**
     * System timestamp = source application event creation timestamp
     * UTC ISOSTRING FORMAT!
     * '2018-10-05T00:00:00.000Z' 'yyyy-MM-ddTHH:mm:ss.zzzZ'
     * Example. Epicor erp transaction SystemTime or other application version.created.timestamp
     * If not there is no any other system time or leaved null, fallback to eventTimestamp
     */
    systemTimestamp: string;

    /**
     * User info, event sender user information
     * (Is appented to version info)
     *
     * User id that is used by source application, can be:
     * Auth0 ID (WeldEye / MyFleet)
     * Salesforce ID (eWarranty / other dealer application)
     * script id (ETL scripts)
     * User name that triggered new event, can also be script or process name.
     */
    user?: {
        id?: string,
        name?: string
    };

    /**
     * Actual event data
     * Contains own object for each eventType, data object of current type is handled and others are ignored.
     * Every event type is handled different way and is effecting to different attributes of machine.
     */
    data: {
        ERP_TRANSACTION?: IEventTypeErpTransaction;
        PARTNER_REGISTRATION?: IEventTypePartnerRegistration;
        END_CUSTOMER_REGISTRATION?: IEventTypeEndCustomerRegistration;
        ATTACH_TO_ORGANIZATION?: IEventTypeAttachToOrganization;
        STOLEN?: IEventTypeStolen;
        DESTROYED?: IEventTypeDestroyed;
    };

    /**
     * Schema type and version of current document
     */
    schema: {
        type: 'machine-event';
        version: '1.0';
    };

    /**
     * Version info
     * Automatically updated,
     * created  = systemTimestamp and user info
     * modified = handler info, machine registry user or script that modifies the event
     */
    version?: {
        created?: {
            timestamp: string;
            user?: {
                id?: string,
                name?: string
            }
        },
        modified?: {
            timestamp: string;
            user?: {
                id?: string,
                name?: string
            }
        }
    };
}

/**
 * Get correct transfer type with transactionId and serialNumberStatus
 */
export const EpicorTransactionTypes: Array<IEpicorTransactionType> = [
    { transactionId: 'DRP-CUS', serialNumberStatus: 'SHIPPED',    description: 'Drop shipment to a customer',             active: true,  manufactured: false, delivered: true,  purchased: false },
    { transactionId: 'INS-REJ', serialNumberStatus: 'DMR',        description: 'Inspection to reject',                    active: true,  manufactured: false, delivered: false, purchased: false },
    { transactionId: 'KIT-CUS', serialNumberStatus: 'SHIPPED',    description: 'Shipment of a kit component to customer', active: true,  manufactured: false, delivered: true,  purchased: false },
    { transactionId: 'MFG-CUS', serialNumberStatus: 'SHIPPED',    description: 'Manufacturing receipt to customer',       active: true,  manufactured: true,  delivered: true,  purchased: false },
    { transactionId: 'MFG-STK', serialNumberStatus: 'INVENTORY',  description: 'Manufacturing receipt to stock',          active: true,  manufactured: true,  delivered: false, purchased: false },
    { transactionId: 'PUR-DRP', serialNumberStatus: '',           description: 'Purchase receipt to drop shipment',       active: true,  manufactured: false, delivered: false, purchased: false },
    { transactionId: 'PUR-STK', serialNumberStatus: 'INVENTORY',  description: 'Purchase receipt to stock',               active: true,  manufactured: false, delivered: false, purchased: true  },
    { transactionId: 'RMA-INS', serialNumberStatus: 'INSPECTION', description: 'RMA receipt to inspection',               active: true,  manufactured: false, delivered: false, purchased: false },
    { transactionId: 'STK-CUS', serialNumberStatus: 'SHIPPED',    description: 'Shipment of stock to customer',           active: true,  manufactured: false, delivered: true,  purchased: false },
    { transactionId: 'STK-STK', serialNumberStatus: 'INVENTORY',  description: 'Stock to stock transfer',                 active: false, manufactured: false, delivered: false, purchased: false },
    { transactionId: 'MAINT',   serialNumberStatus: 'INVENTORY',  description: 'Maintenance, moved to stock',             active: true,  manufactured: false, delivered: false, purchased: false },
    { transactionId: 'MAINT',   serialNumberStatus: 'SHIPPED',    description: 'Maintenance, shipped',                    active: true,  manufactured: false, delivered: true,  purchased: false }
];
export interface IEpicorTransactionType {
    transactionId: string;
    serialNumberStatus: string;
    description: string;
    active: boolean;
    manufactured: boolean;
    delivered: boolean;
    purchased: boolean;
}

/**
 * ERP Transaction
 * ETL script is automatically moving these from ERP to Machinery
 * machinery-erp-transactions, Epicor.SNTran table
 * From: EPICOR | IFS | Z
 */
export interface IEventTypeErpTransaction {
    productId: string;
    productName: string;
    company: string;
    partnerId: string;
    partnerName: string;
    transactionId: string;
    serialNumberStatus: string;
    secureId: string;
    orderNumber: string;
    shipNumber: string;
    jobNumber: string;
    stock: string;
    reference?: string;
    sysRowId: string;
}

/**
 * Partner registration
 * Adds registeredCompany and registeredPartnerId, used for listing own registered machines
 * From: EWARRANTY
 */
export interface IEventTypePartnerRegistration {
    company: string;
    partnerId: string;
    partnerName: string;
    salesDate: string;
    endCustomerName?: string;
    partner?: {
        name: string;
        streetAddress: string;
        postalCode: string;
        city: string;
        country: string;
    };
    customer?: {
        name: string;
        streetAddress: string;
        postalCode: string;
        city: string;
        country: string;
    };

    /**
     * Appended to event in MACHINERY
     * Used when salesDate is year after delivery and warranty expire date is hitting maximum 36 months from delivery
     */
    salesDateApproved?: {
        timestamp: string;
        user?: {
            id?: string,
            name?: string
        };
    };
}

/**
 * End customer registration
 * Adds registeredOrganizationId to machine, used for listing own registered machines
 * From: MYFLEET
 */
export interface IEventTypeEndCustomerRegistration {
    organizationId: string;
    organizationName: string;
    customer?: {
        name: string;
        streetAddress: string;
        postalCode: string;
        city: string;
        country: string;
    };
}

/**
 * Attach to organization
 * From: MYFLEET | WELDEYE
 */
export interface IEventTypeAttachToOrganization {
    organizationId: string;
    organizationName: string;
}

/**
 * Stolen, machine is reported to be stolen
 * From: MACHINERY
 */
export interface IEventTypeStolen {
    reportedBy: string;
    description: string;
}

/**
 * Destroyed, machine is reported to be destroyed
 * From: DESTROYED
 */
export interface IEventTypeDestroyed {
    reportedBy: string;
    description: string;
}
