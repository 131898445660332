<div class="main">
    <div *ngIf="machine && machine.serialNumber" class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">-</mat-icon>
            <div fxLayout="row wrap" fxFlex>
                <h2 fxFlex="200px">Machine info</h2>
                <div fxFlex></div>
                <div fxLayout="column">
                    <h3><b>{{ machine.serialNumber }}</b></h3>
                    <h4>{{ machine.productInfo?.partDescription }}</h4>
                </div>
                <div fxFlex></div>
                <div fxLayoutAlign="end center">
                    <button mat-button color="accent" (click)="openInLegacyMachineRegistry()" target="_blank" style="line-height: 16px;">Open in legacy<br>MachineRegistry</button>
                </div>
            </div>
        </div>

        <!-- GENERAL -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex fxLayout="column">
                <legend class="mat-subhead">General</legend>
                <div class="full" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex="120px" [attr.readonly]="true">
                        <mat-label>Serial number</mat-label>
                        <input matInput [(ngModel)]="machine.serialNumber" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="120px" [attr.readonly]="true">
                        <mat-label>Product number</mat-label>
                        <input matInput [(ngModel)]="machine.productId" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Secure ID</mat-label>
                        <input matInput [(ngModel)]="machine.secureId" [readonly]="true">
                    </mat-form-field>
                    <div fxFlex></div>
                    <mat-form-field fxFlex="140px" [attr.readonly]="true">
                        <mat-label>Manufacture company</mat-label>
                        <input matInput [(ngModel)]="machine.manufactureCompany" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="140px" [attr.readonly]="true">
                        <mat-label>ERP Status</mat-label>
                        <input matInput [(ngModel)]="machine.serialNumberStatus" [readonly]="true">
                        <mat-hint *ngIf="machine.deliveryDateFromParent === true" class="warn">( PARENT SERIAL )</mat-hint>
                    </mat-form-field>
                    <div>
                        <h2 *ngIf="machine.testSerie" class="warn-stamp" matTooltip="Test serie"><a [routerLink]="['/testseries', machine.testSerie]">{{ machine.testSerie }}</a></h2>
                        <h2 *ngIf="machine.stolen" class="warn-stamp" matTooltip="Reported to stolen, see machine-events">STOLEN</h2>
                        <h2 *ngIf="machine.destroyed" class="warn-stamp" matTooltip="Reported to destroyed, see machine-events">DESTROYED</h2>
                    </div>
                </div>
                <fieldset *ngIf="machine.parentSerials && machine.parentSerials.length > 0" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">Parent serial</legend>
                    <div class="full" fxLayout="row" *ngFor="let parent of machine.parentSerials">
                        <div fxFlex="160px"><a [routerLink]="['/machines', parent.parentSerialNo]">{{ parent.parentSerialNo }}</a></div>
                        <div fxFlex="120px">{{ parent.parentPartNum }}</div>
                        <div fxFlex="120px" class="weak">{{ parent.dateMatched | date:'dd.MM.yyyy' }}</div>
                    </div>
                </fieldset>
                <fieldset *ngIf="machine.childSerials && machine.childSerials.length > 0" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">Child serials</legend>
                    <div class="full" fxLayout="row" *ngFor="let child of machine.childSerials">
                        <div fxFlex="160px"><a [routerLink]="['/machines', child.childSerialNo]">{{ child.childSerialNo }}</a></div>
                        <div fxFlex="120px">{{ child.childPartNum }}</div>
                        <div fxFlex="120px" class="weak">{{ child.dateMatched | date:'dd.MM.yyyy' }}</div>
                    </div>
                </fieldset>
                <fieldset *ngIf="machine.linkedSerials && machine.linkedSerials.length > 0" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">Linked serials</legend>
                    <div class="full" fxLayout="row" *ngFor="let linked of machine.linkedSerials">
                        <div fxFlex="160px"><a routerLinkActive="active" routerLink="/machines/{{linked.internalSerialNumber}}">{{ linked.internalSerialNumber }}</a></div>
                        <div fxFlex="120px" class="weak">{{ linked.timestamp | date:'dd.MM.yyyy' }}</div>
                        <div fxFlex="120px" class="weak">{{ linked.deviceType }}</div>
                        <div fxFlex="150px" class="weak" *ngIf="showDetails">{{ linked.categories }}</div>
                        <div fxFlex="220px" class="weak" *ngIf="showDetails">{{ linked.source }}</div>
                    </div>
                </fieldset>

                <fieldset fxFlex class="sub-fieldset">
                    <legend class="mat-subhead">Dates</legend>
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label>Manufacture date</mat-label>
                            <input matInput [value]="machine.manufactureDate | date:'dd.MM.yyyy'"
                                [readonly]="true">
                            <mat-hint *ngIf="showDetails">{{machine.manufactureDate}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label>Delivery date</mat-label>
                            <input matInput [value]="machine.deliveryDate | date:'dd.MM.yyyy'"
                                [readonly]="true">
                            <mat-hint *ngIf="machine.deliveryDateFromParent === true" class="warn">( PARENT SERIAL )</mat-hint>
                            <mat-hint *ngIf="showDetails">{{machine.deliveryDate}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label>Sales date</mat-label>
                            <input matInput [value]="machine.salesDate | date:'dd.MM.yyyy'"
                                [readonly]="true">
                            <mat-hint *ngIf="machine.salesDateApproved === true" class="warn">Approved by Kemppi</mat-hint>
                            <mat-hint *ngIf="showDetails">{{machine.salesDate}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label>Partner registration date</mat-label>
                            <input matInput [value]="machine.partnerRegistrationDate | date:'dd.MM.yyyy'"
                                [readonly]="true">
                            <mat-hint *ngIf="showDetails">{{machine.partnerRegistrationDate}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label>End customer registration date</mat-label>
                            <input matInput [value]="machine.endCustomerRegistrationDate | date:'dd.MM.yyyy'"
                                [readonly]="true">
                            <mat-hint *ngIf="showDetails">{{machine.endCustomerRegistrationDate}}</mat-hint>
                        </mat-form-field>
                    </div>
                </fieldset>
                <fieldset fxFlex class="sub-fieldset">
                    <legend class="mat-subhead">Warranty</legend>
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="170px" [attr.readonly]="true">
                            <mat-label [ngClass]="{'warn': machine.warrantyExpireDate < now}">Warranty expire date</mat-label>
                            <input matInput [value]="machine.warrantyExpireDate | date:'dd.MM.yyyy'"
                                [readonly]="true" [ngClass]="{'warn': machine.warrantyExpireDate < now}">
                            <mat-hint *ngIf="showDetails">{{machine.warrantyExpireDate}}</mat-hint>
                        </mat-form-field>
                        <mat-form-field *ngIf="warrantyTime !== null" fxFlex="170px" [attr.readonly]="true">
                            <mat-label [ngClass]="{ 'warn': warrantyTime !== 24 && warrantyTime !== 36 }">Warranty time (months)</mat-label>
                            <input matInput [value]="warrantyTime"
                                [readonly]="true" [ngClass]="{ 'warn': warrantyTime !== 24 && warrantyTime !== 36 }">
                        </mat-form-field>
                        <div fxFlex></div>

                        <!--
                        <div fxFlex="33%">
                            <button mat-button color="accent" (click)="changeWarrantyExpireDate()">
                                <mat-icon>lock_open</mat-icon>
                                Change warranty expire date
                            </button>
                            <button mat-button color="accent" *ngIf="machine.warrantyState === 'MANUAL'" (click)="removeWarrantyManual()">
                                RESET
                            </button>
                        </div>
                        -->
                    </div>
                    <div fxLayout="row">
                        <small class="weak" *ngIf="machine.warrantyExpireDate"><i>Warranty: {{machine.warrantyExpireCalculation ? 'Automatically calculated' : 'Manually set' }} - {{machine.warrantyDescription}}</i></small>
                    </div>
                </fieldset>
            </fieldset>
        </div>

        <!-- PRODUCT INFO -->
        <div *ngIf="machine.productInfo" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Product info</legend>
                <div class="full" fxLayout="row">
                    <mat-form-field fxFlex="320px" [attr.readonly]="true">
                        <mat-label>Product name</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.partDescription"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Prod code</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.prodCode" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Class ID</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.classID" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Sales UM</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.salesUM" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Category code</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.categoryCode"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Family code</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.familyCode"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Sales ABC</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.salesABC" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Is software</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.isSoftware"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px" [attr.readonly]="true">
                        <mat-label>Warranty code</mat-label>
                        <input matInput [(ngModel)]="machine.productInfo.warrantyCode"
                            [readonly]="true">
                        <mat-hint *ngIf="!machine.productInfo.warrantyCode" class="warn">No warranty!</mat-hint>
                    </mat-form-field>
                </div>
                <div class="full" fxLayout="row" class="weak small" *ngIf="showDetails && machine.productInfo.partDescriptions">
                    <div fxLayout="column">
                        <div fxLayout="row">
                            <div fxFlex="50px">DAN</div>{{machine.productInfo.partDescriptions.dan}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">DEU</div>{{machine.productInfo.partDescriptions.deu}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">ENU</div>{{machine.productInfo.partDescriptions.enu}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">FIN</div>{{machine.productInfo.partDescriptions.fin}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">FRA</div>{{machine.productInfo.partDescriptions.fra}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">NLD</div>{{machine.productInfo.partDescriptions.nld}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">NOR</div>{{machine.productInfo.partDescriptions.nor}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">PLK</div>{{machine.productInfo.partDescriptions.plk}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">RUS</div>{{machine.productInfo.partDescriptions.rus}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">SCH</div>{{machine.productInfo.partDescriptions.sch}}
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="50px">SVE</div>{{machine.productInfo.partDescriptions.sve}}
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- PARTNER INFO -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">
                    Partner info
                    <span *ngIf="machine.deliveryDateFromParent === true" class="warn">( PARENT SERIAL )</span>
                </legend>
                <div *ngIf="partnerInfo" class="full" fxLayout="row">
                    <mat-form-field fxFlex="320px" [attr.readonly]="true">
                        <mat-label>Partner name</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.name" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px" [attr.readonly]="true">
                        <mat-label>Partner ID</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.custId" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Company</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.company" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Group code</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.groupCode" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="200px" [attr.readonly]="true">
                        <mat-label>Group description</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.groupDesc" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Lang</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.langNameId" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="80px" [attr.readonly]="true">
                        <mat-label>Currency</mat-label>
                        <input matInput [(ngModel)]="partnerInfo.currencyCode" [readonly]="true">
                    </mat-form-field>
                </div>
                <div *ngIf="!partnerInfo && authUserScope !== 'global' && userCompany !== machine.company">
                    <small class="weak"><i>Not authorized to load partner information from different company</i></small>
                </div>
            </fieldset>
        </div>

        <!-- FACTORY INSTALLED MCU -->
        <div *ngIf="machine.mcuInfo" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Factory installed MCU</legend>
                <div class="full" fxLayout="column">
                    <div class="full" *ngFor="let mcuType of mcuTypes">
                        <div *ngIf="machine.mcuInfo[mcuType]" fxLayout="column">
                            <div>{{ mcuDescriptions[mcuType] }} <small><i>({{mcuType}})</i></small></div>
                            <div *ngIf="machine.mcuInfo[mcuType].devicename" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Device name</div>
                                <div>{{ machine.mcuInfo[mcuType].devicename }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].familyname" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Family name</div>
                                <div>{{ machine.mcuInfo[mcuType].familyname }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].serialnumber" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Serial number</div>
                                <div>{{ machine.mcuInfo[mcuType].serialnumber }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].serial" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Serial</div>
                                <div>{{ machine.mcuInfo[mcuType].serial }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].version" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Version</div>
                                <div>{{ machine.mcuInfo[mcuType].version }}</div>
                            </div>

                            <div *ngIf="machine.mcuInfo[mcuType].bootinfoversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Boot info version</div>
                                <div>{{ machine.mcuInfo[mcuType].bootinfoversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].codeinfoversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Code info version</div>
                                <div>{{ machine.mcuInfo[mcuType].codeinfoversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].licensesnversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Licenses sn version</div>
                                <div>{{ machine.mcuInfo[mcuType].licensesnversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].bootswversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Boot SW version</div>
                                <div>{{ machine.mcuInfo[mcuType].bootswversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].switemnumber" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">SW item number</div>
                                <div>{{ machine.mcuInfo[mcuType].switemnumber }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].licensestring" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">License string</div>
                                <div>{{ machine.mcuInfo[mcuType].licensestring }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].swversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">SW version</div>
                                <div>{{ machine.mcuInfo[mcuType].swversion }}</div>
                            </div>

                            <div *ngIf="machine.mcuInfo[mcuType].systemswversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">System SW version</div>
                                <div>{{ machine.mcuInfo[mcuType].systemswversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].applicationswversion" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Application SW version</div>
                                <div>{{ machine.mcuInfo[mcuType].applicationswversion }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].applicationswversion2" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Application 2 SW version</div>
                                <div>{{ machine.mcuInfo[mcuType].applicationswversion2 }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].bluetoothaddress" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Bluetooth address</div>
                                <div>{{ machine.mcuInfo[mcuType].bluetoothaddress }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].lanaddress" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">LAN address</div>
                                <div>{{ machine.mcuInfo[mcuType].lanaddress }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].wlanaddress" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">WLAN address</div>
                                <div>{{ machine.mcuInfo[mcuType].wlanaddress }}</div>
                            </div>

                            <!-- Data collector -->
                            <div *ngIf="machine.mcuInfo[mcuType].boardid" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Board ID</div>
                                <div>{{ machine.mcuInfo[mcuType].boardid }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].devicekey" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Device key</div>
                                <div>{{ machine.mcuInfo[mcuType].devicekey }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].hardwarerevision" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">Hardware version</div>
                                <div>{{ machine.mcuInfo[mcuType].hardwarerevision }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].lanmac" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">LAN MAC</div>
                                <div>{{ machine.mcuInfo[mcuType].lanmac }}</div>
                            </div>
                            <div *ngIf="machine.mcuInfo[mcuType].wlanmac" fxLayout="row" class="event-attribute">
                                <div class="event-attribute-label">WLAN MAC</div>
                                <div>{{ machine.mcuInfo[mcuType].wlanmac }}</div>
                            </div>

                        </div>
                    </div>
                    <small class="weak"><i>Production manufacture test results: {{ machine.mcuInfo.timestamp | date:'dd.MM.yyyy' }}</i></small>
                    <small class="weak"><pre *ngIf="showDetails">{{ machine.mcuInfo | json }}</pre></small>
                </div>
            </fieldset>
        </div>

        <!-- COLLECTED DEVICE LOGS -->
        <div *ngIf="deviceLogs && deviceLogs.items && deviceLogs.items[0]" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Collected device logs <small><i>({{ deviceLogs.items.length }} / {{ deviceLogs.totalCount }})</i></small></legend>
                <div fxLayout="row wrap">
                    <div fxFlex="160px" fxLayoutAlign="start start">
                        <div *ngFor="let deviceLog of deviceLogs.items; let deviceLogIndex = index">
                            <a *ngIf="selectedDeviceLogIndex !== deviceLogIndex" (click)="selectedDeviceLogIndex = deviceLogIndex">{{ deviceLog.updateTimestamp | date:'dd.MM.yyyy HH:mm:ss' }}</a>
                            <b *ngIf="selectedDeviceLogIndex === deviceLogIndex">{{ deviceLog.updateTimestamp | date:'dd.MM.yyyy HH:mm:ss' }}</b>
                        </div>
                        <div fxFlex fxLayoutAlign="start center">
                            <mat-progress-spinner matSuffix *ngIf="deviceLogsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                            <button *ngIf="!deviceLogsLoading && deviceLogs.totalCount > deviceLogs.items.length" mat-button color="primary" (click)="getDeviceLogs()">
                                <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'common.load_more' | translate }}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="selectedDeviceLogIndex >= 0 && deviceLogs.items[selectedDeviceLogIndex]" fxFlex>
                        <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].updateTimestamp">{{ deviceLogs.items[selectedDeviceLogIndex].updateTimestamp | date:'dd.MM.yyyy HH:mm:ss' }}</div>

                        <!-- STATISTICS -->
                        <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].statistics" class="full" fxLayout="column">
                            <ng-container *ngFor="let hwType of deviceLogs.items[selectedDeviceLogIndex].statistics | keyvalue">
                                <div class="full" *ngIf="hwType.value && (hwType.value.startingCounter || hwType.value.operateTime || hwType.value.weldCounter || hwType.value.weldingTime)">
                                    <div fxlayout="row wrap">
                                        <!-- Hardware -->
                                        <fieldset fxLayout="column" class="sub-fieldset">
                                            <legend class="mat-subhead">Statistics</legend>
                                            <div *ngIf="hwType.value.startingCounter" fxLayout="row" class="event-attribute">
                                                <div class="event-attribute-label">Device start count</div>
                                                <div>{{ hwType.value.startingCounter }}</div>
                                            </div>
                                            <div *ngIf="hwType.value.operateTime" fxLayout="row" class="event-attribute">
                                                <div class="event-attribute-label">Power on</div>
                                                <div>{{ hwType.value.operateTime }} h</div>
                                            </div>
                                            <div *ngIf="hwType.value.weldCounter" fxLayout="row" class="event-attribute">
                                                <div class="event-attribute-label">Weld count</div>
                                                <div>{{ hwType.value.weldCounter }}</div>
                                            </div>
                                            <div *ngIf="hwType.value.weldingTime" fxLayout="row" class="event-attribute">
                                                <div class="event-attribute-label">Welding time</div>
                                                <div>{{ timeConvertFromSecondsToString(hwType.value.weldingTime) }}</div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- HARDWARE -->
                        <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].hardware" class="full" fxLayout="column">
                            <fieldset fxLayout="column" class="sub-fieldset">
                                <legend class="mat-subhead">Hardware</legend>

                                <ng-container *ngFor="let hwType of deviceLogs.items[selectedDeviceLogIndex].hardware | keyvalue">
                                    <div class="full" *ngFor="let hw of hwType.value">
                                        <div fxLayoutAlign="start center" fxLayoutGap="10px">
                                            <span fxFlex="24px">
                                                <mat-icon *ngIf="hwType.key === 'controlpad'" class="kemppi-symbols">»</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'powerSource'" class="kemppi-symbols">ĉ</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'wireFeeder'" class="kemppi-symbols">ċ</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'connectivityCard'" class="kemppi-symbols">Â</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'cooler'" class="kemppi-symbols">Ċ</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'database'" class="kemppi-symbols">Ŭ</mat-icon>
                                                <mat-icon *ngIf="hwType.key === 'dataCollectionDevice'" class="kemppi-symbols">(</mat-icon>
                                            </span>
                                            <span>
                                                {{ _.startCase(hwType.key) }}
                                            </span>
                                            <small><i>({{ hw.deviceName }})</i></small>
                                        </div>
                                        <div fxlayout="row wrap">
                                            <!-- Hardware -->
                                            <div fxFlex="400px" fxLayout="column">
                                                <div *ngIf="hw.deviceSerialNumber" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Device serial number</div>
                                                    <div>{{ hw.deviceSerialNumber }}</div>
                                                </div>
                                                <div *ngIf="hw.frameSerialNumber" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Frame serial number</div>
                                                    <div>{{ hw.frameSerialNumber }}</div>
                                                </div>
                                                <div *ngIf="hw.productionDate" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Production date</div>
                                                    <div>{{ hw.productionDate }}</div>
                                                </div>
                                                <!-- Versions -->
                                                <div *ngIf="hw.swApp" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Application SW version</div>
                                                    <div>{{ hw.swApp }}</div>
                                                </div>
                                                <div *ngIf="hw.swApp2" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Application 2 SW version</div>
                                                    <div>{{ hw.swApp2 }}</div>
                                                </div>
                                                <div *ngIf="hw.swBoot" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Boot SW version</div>
                                                    <div>{{ hw.swBoot }}</div>
                                                </div>
                                                <div *ngIf="hw.swSystem" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">System SW version</div>
                                                    <div>{{ hw.swSystem }}</div>
                                                </div>
                                                <!-- MAC's -->
                                                <div *ngIf="hw.macBT" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Bluetooth MAC address</div>
                                                    <div>{{ hw.macBT }}</div>
                                                </div>
                                                <div *ngIf="hw.macETH" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">Ethernet MAC address</div>
                                                    <div>{{ hw.macETH }}</div>
                                                </div>
                                                <div *ngIf="hw.macWLAN" fxLayout="row" class="event-attribute">
                                                    <div class="event-attribute-label">WLAN MAC address</div>
                                                    <div>{{ hw.macWLAN }}</div>
                                                </div>
                                            </div>
                                            <!-- Statistics -->
                                            <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].statistics && deviceLogs.items[selectedDeviceLogIndex].statistics[hwType.key]" fxFlex="600px" fxLayout="column">
                                                <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].statistics[hwType.key].errorCodes && deviceLogs.items[selectedDeviceLogIndex].statistics[hwType.key].errorCodes.length > 0">
                                                    <div class="event-attribute event-attribute-label">Error codes</div>
                                                    <div *ngFor="let errorCode of deviceLogs.items[selectedDeviceLogIndex].statistics[hwType.key].errorCodes" fxLayout="row" class="event-attribute">
                                                        <div class="event-attribute-label">{{ _.split(errorCode, ' ')[0] }}</div>
                                                        <div fxFlex="40px">{{ _.split(errorCode, ' ')[1] }}</div>
                                                        <div fxFlex="70px" class="weak">{{ X8ErrorType[_.split(_.split(errorCode, ' ')[1], '-')[0]] }}</div>
                                                        <div class="weak">{{ X8ErrorCode[_.split(_.split(errorCode, ' ')[1], '-')[1]] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </fieldset>
                        </div>

                        <!-- SOFTWARE -->
                        <div *ngIf="deviceLogs.items[selectedDeviceLogIndex].software" class="full" fxLayout="column">
                            <fieldset fxLayout="column" class="sub-fieldset">
                                <legend class="mat-subhead">Software</legend>
                                <ng-container *ngIf="deviceLogs.items[selectedDeviceLogIndex].software.curves && deviceLogs.items[selectedDeviceLogIndex].software.curves.length > 0">
                                    <div>Curves</div>
                                    <div *ngFor="let curveId of deviceLogs.items[selectedDeviceLogIndex].software.curves">
                                        <div fxLayout="row" class="event-attribute">
                                            <div class="event-attribute-label">{{ (curveId | lowercase) }}</div>
                                            <div *ngIf="Curves[(curveId | lowercase)]" fxFlex="200px">{{ Curves[(curveId | lowercase)]?.name }}</div>
                                            <div *ngIf="Curves[(curveId | lowercase)]" fxFlex="200px">{{ Curves[(curveId | lowercase)]?.desc }}</div>
                                            <div *ngIf="Curves[(curveId | lowercase)]" fxFlex="50px">{{ Curves[(curveId | lowercase)]?.ver }}</div>
                                            <div *ngIf="!Curves[(curveId | lowercase)]">Not recognized</div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="deviceLogs.items[selectedDeviceLogIndex].software.features && deviceLogs.items[selectedDeviceLogIndex].software.features.length > 0">
                                    <div>Features</div>
                                    <div *ngFor="let feature of deviceLogs.items[selectedDeviceLogIndex].software.features">
                                        <div fxLayout="row" class="event-attribute">
                                            <div class="event-attribute-label"></div>
                                            <div fxFlex="200px">{{ feature }}</div>
                                        </div>
                                    </div>
                                </ng-container>
                            </fieldset>
                        </div>

                        <small class="weak"><pre *ngIf="showDetails">{{ deviceLogs.items[selectedDeviceLogIndex] | json }}</pre></small>
                    </div>
                </div>

            </fieldset>
        </div>


        <!-- VALIDATIONS -->
        <!--
        <div class="full future-feature" fxLayout="row">
            <fieldset fxFlex>
                <legend class="future-feature">Validations</legend>
                <div *ngIf="machine.validations" class="full" fxLayout="column">
                    <div class="full" fxLayout="row" class="text-list-header-row">
                        <div fxFlex="150px">Id</div>
                        <div fxFlex="120px">Validation date</div>
                        <div fxFlex="60px">Company</div>
                        <div fxFlex="80px">Partner id</div>
                        <div fxFlex>Partner name</div>
                    </div>
                    <div class="full" fxLayout="row" *ngFor="let validation of machine.validations">
                        <div fxFlex="150px">
                            <a [href]="EWARRANTY_FRONTEND_URL + '/validation/' + validation.id" target="_blank">
                            {{ validation.id }}
                            </a>
                        </div>
                        <div fxFlex="120px">{{ validation.data.date | date:'dd.MM.yyyy' }}</div>
                        <div fxFlex="60px">{{ validation.company }}</div>
                        <div fxFlex="80px">{{ validation.partnerId }}</div>
                        <div fxFlex>{{ validation.partnerName }}</div>
                    </div>
                </div>

                <div fxLayoutAlign="end center">
                    <mat-icon class="kemppi-symbols">è</mat-icon> ArcValidator
                </div>
            </fieldset>
        </div>
        -->

        <!-- WELDING -->
        <!--
        <div class="full future-feature" fxLayout="row">
            <fieldset fxFlex>
                <legend class="future-feature">Welding</legend>
                <h3>2019</h3>
                <div fxLayout="row">
                    <img src="assets/images/example-calendar.png" width="500px" height="auto" />
                </div>
                <div>
                    <img src="assets/images/example-calendar-legend.png" width="200px" height="auto" />
                </div>

                <div fxLayoutAlign="end center">
                    <mat-icon class="kemppi-symbols">Ÿ</mat-icon> Weldeye / MyFleet
                </div>
            </fieldset>
        </div>
        -->

        <!-- REGISTRATIONS -->
        <div *ngIf="(authUserScope === 'global' || userCompany === machine.company)" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Registrations</legend>
                <fieldset *ngIf="machine.registeredPartnerId" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">Partner registration (eWarranty)</legend>
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>End customer name</mat-label>
                            <input matInput [(ngModel)]="machine.registeredEndCustomerName"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>Registered partner name</mat-label>
                            <input matInput [(ngModel)]="machine.registeredPartnerName"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="100px" [attr.readonly]="true">
                            <mat-label>Partner ID</mat-label>
                            <input matInput [(ngModel)]="machine.registeredPartnerId"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px" [attr.readonly]="true">
                            <mat-label>Company</mat-label>
                            <input matInput [(ngModel)]="machine.registeredCompany"
                                [readonly]="true">
                        </mat-form-field>
                    </div>
                </fieldset>
                <fieldset *ngIf="machine.registeredOrganizationId" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">End customer registration (MyFleet)</legend>
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>Organization name</mat-label>
                            <input matInput [(ngModel)]="machine.registeredOrganizationName"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>Organization ID</mat-label>
                            <input matInput [(ngModel)]="machine.registeredOrganizationId"
                                [readonly]="true">
                        </mat-form-field>
                    </div>
                </fieldset>
                <fieldset *ngIf="machine.attachedOrganizationId" fxLayout="column" class="sub-fieldset">
                    <legend class="mat-subhead">Attached organization (WeldEye / MyFleet)</legend>
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>Organization name</mat-label>
                            <input matInput [(ngModel)]="machine.attachedOrganizationName"
                                [readonly]="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="320px" [attr.readonly]="true">
                            <mat-label>Organization ID</mat-label>
                            <input matInput [(ngModel)]="machine.attachedOrganizationId"
                                [readonly]="true">
                        </mat-form-field>
                    </div>
                </fieldset>

                <div *ngIf="!partnerInfo && authUserScope !== 'global' && userCompany !== machine.company">
                    <small class="weak"><i>Not authorized to load partner information from different company</i></small>
                </div>

            </fieldset>
        </div>

        <!-- SOFTWARE LICENSES -->

        <div *ngIf="(authUserScope === 'global' || userCompany === machine.company)" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Software Licences</legend>
                
                <div *ngIf="softwareLicenses?.length" class="full" fxLayout="column">
                    <div class="full list-header-row">
                        <div fxFlex="70px">Company</div>
                        <div fxFlex="70px">Partner id</div>
                        <div fxFlex="130px">Partner name</div>
                        <div fxFlex="130px">Owner</div>
                        <div fxFlex="150px">Order Number</div>
                        <div fxFlex="190px">Order Date</div>
                        <div fxFlex="190px">Product Number</div>
                        <div fxFlex>Product Name</div>
                    </div>
                    <div class="full" *ngFor="let event of softwareLicenses; let i = index">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="70px"> <b>{{ event.company }}</b> </div>
                            <div fxFlex="70px"> <b>{{ event.partnerId }}</b> </div>
                            <div fxFlex="130px"> <b>{{ event.data?.partnerName }}</b> </div>
                            <div fxFlex="130px"> {{ event.owner }} </div>
                            <div fxFlex="150px"> {{ event.orderNumber }} </div>
                            <div fxFlex="190px"> {{ event.orderDate | date:'dd.MM.yyyy hh:mm:ss' }}</div>
                            <div fxFlex="190px"> {{ event.productNumber }} </div>
                            <div fxFlex> {{ event.data?.productName }} </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <!--  WARRANTY CLAIMS -->
        <div *ngIf="machine.warrantyClaims && machine.warrantyClaims.length > 0" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Warranty claims (eWarranty)</legend>
                <div class="full" fxLayout="column">
                    <div class="full" fxLayout="row" class="text-list-header-row">
                        <div fxFlex="160px">Id</div>
                        <div fxFlex="120px">Repair date</div>
                        <div fxFlex="60px">Company</div>
                        <div fxFlex="80px">Partner id</div>
                        <div fxFlex>Partner name</div>
                    </div>
                    <div class="full" fxLayout="row" *ngFor="let warrantyClaim of machine.warrantyClaims">
                        <ng-container *ngIf="warrantyClaim && warrantyClaim.data">
                            <div fxFlex="160px">
                                <a [href]="EWARRANTY_FRONTEND_URL + '/claims/' + warrantyClaim.company + '/' + warrantyClaim.partnerId + '/' + warrantyClaim.id" target="_blank">
                                {{ warrantyClaim.id }}
                                </a>
                            </div>
                            <div fxFlex="120px">{{ warrantyClaim.data.repairDate | date:'dd.MM.yyyy' }}</div>
                            <div fxFlex="60px">{{ warrantyClaim.company }}</div>
                            <div fxFlex="80px">{{ warrantyClaim.partnerId }}</div>
                            <div fxFlex>{{ warrantyClaim.partnerName }}</div>
                        </ng-container>
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend class="mat-subhead">Machine events <small><i>({{ machine.machineEvents.length }})</i></small></legend>
                <div class="full" fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="320px">
                        <mat-label>Event type</mat-label>
                        <mat-select [(value)]="eventsTypeFilter">
                            <mat-option value="">ALL</mat-option>
                            <mat-option value="ATTACH_TO_ORGANIZATION">ATTACH TO ORGANIZATION</mat-option>
                            <mat-option value="DESTROYED">DESTROYED</mat-option>
                            <mat-option value="END_CUSTOMER_REGISTRATION">END CUSTOMER REGISTRATION</mat-option>
                            <mat-option value="ERP_TRANSACTION">ERP TRANSACTION</mat-option>
                            <!-- <mat-option value="FIRMWARE_UPDATE">FIRMWARE UPDATE</mat-option> -->
                            <!-- <mat-option value="HARDWARE_UPDATE">HARDWARE UPDATE</mat-option> -->
                            <!-- <mat-option value="MANUFACTURING">MANUFACTURING</mat-option> -->
                            <mat-option value="PARTNER_REGISTRATION">PARTNER REGISTRATION</mat-option>
                            <!-- <mat-option value="SET_WARRANTY_EXPIRE_MANUAL">SET WARRANTY EXPIRE MANUAL</mat-option> -->
                            <mat-option value="STOLEN">STOLEN</mat-option>
                            <!-- <mat-option value="WARRANTY_CLAIM">WARRANTY CLAIM</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="320px">
                        <mat-label>Event source</mat-label>
                        <mat-select [(value)]="eventsSourceFilter">
                            <mat-option value="">ALL</mat-option>
                            <mat-option value="EPICOR">EPICOR</mat-option>
                            <mat-option value="EWARRANTY">EWARRANTY</mat-option>
                            <mat-option value="MACHINERY">MACHINERY</mat-option>
                            <mat-option value="MACHINEREGISTRY">MACHINEREGISTRY</mat-option>
                            <mat-option value="MANUAL">MANUAL</mat-option>
                            <!-- <mat-option value="MANUFACTURE">MANUFACTURE</mat-option> -->
                            <mat-option value="MOBILEMAINTENANCE">MOBILEMAINTENANCE</mat-option>
                            <mat-option value="MYFLEET">MYFLEET</mat-option>
                            <mat-option value="WELDEYE">WELDEYE</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="authUserScope === 'global'">
                        <mat-checkbox [(ngModel)]="showActivesOnly">Show actives only</mat-checkbox>
                    </ng-container>
                </div>
                <div class="full" fxLayout="column">
                    <div class="full list-header-row">
                        <div fxFlex="45px"></div>
                        <div fxFlex="45px"></div>
                        <div fxFlex="210px">Event type and source</div>
                        <div fxFlex="100px">Date</div>
                        <div fxFlex="300px">Description</div>
                        <div fxFlex="60px">Company</div>
                        <div fxFlex="90px">Partner id</div>
                        <div fxFlex="80px">Order num</div>
                        <div fxFlex="80px">Job num</div>
                    </div>
                    <div class="full" *ngFor="let event of machine.machineEvents; let i = index">
                        <ng-container *ngIf="(eventsTypeFilter === '' || event.eventType === eventsTypeFilter) && (eventsSourceFilter === '' || event.eventSource === eventsSourceFilter)">
                            <div *ngIf="!showActivesOnly || (showActivesOnly && event.active)" fxLayout="row" class="event-row" fxLayoutAlign="start center" [ngClass]="{'inactive': !event.active}">
                                <div fxFlex="45px">
                                    <h2 class="mat-subhead" *ngIf="i === 0 || machine.machineEvents[i-1].eventTimestamp.substr(0, 4) !== event.eventTimestamp.substr(0, 4)">{{ event.eventTimestamp | date:'yyyy' }}</h2>
                                </div>
                                <div fxFlex="45px">
                                    <mat-icon *ngIf="event.eventType === 'ASSEMBLY'" class="kemppi-symbols big-icon">ŷ</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'VALIDATION'" class="kemppi-symbols big-icon">ŷ</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'ERP_TRANSACTION'" class="kemppi-symbols big-icon">Ģ</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'WARRANTY_CLAIM'" class="kemppi-symbols big-icon">s</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'PARTNER_REGISTRATION' || event.eventType === 'END_CUSTOMER_REGISTRATION'" class="kemppi-symbols big-icon">{{ '\{' }}</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'ATTACH_TO_ORGANIZATION'" class="kemppi-symbols big-icon">Ÿ</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'STOLEN'" class="kemppi-symbols big-icon">p</mat-icon>
                                    <mat-icon *ngIf="event.eventType === 'DESTROYED'" class="kemppi-symbols big-icon">p</mat-icon>
                                </div>
                                <div fxFlex="210px">
                                    <small>
                                        {{ event.eventType | replace:'_':' ' }}<br>
                                        <i class="weak">{{ event.eventSource }}</i>
                                    </small>
                                </div>
                                <div fxFlex="100px" matTooltip="{{ event.eventTimestamp | date:'dd.MM.yyyy hh:mm:ss' }}">
                                    {{ event.eventTimestamp | date:'dd.MM.yyyy' }}<br>
                                </div>
                                <div fxFlex="300px">
                                    {{ event.eventDescription }}
                                </div>
                                <ng-container *ngIf="event.data && event.data[event.eventType]">
                                <div fxFlex="60px">
                                    <b>{{ event.data[event.eventType].company }}</b>
                                </div>
                                <div fxFlex="90px">
                                    <b>{{ event.data[event.eventType].partnerId }}</b>
                                </div>
                                <div fxFlex="80px">
                                    {{ event.data[event.eventType].orderNumber }}
                                </div>
                                <div fxFlex="80px">
                                    {{ event.data[event.eventType].jobNumber }}
                                </div>
                                <div fxFlex="120px" *ngIf="userRole === 'sys_admin' || userRole === 'koy_user' || userRole === 'sub_user'">
                                    <button mat-button *ngIf="event.active === false && (
                                            event.eventType === 'PARTNER_REGISTRATION'
                                            || event.eventType === 'END_CUSTOMER_REGISTRATION'
                                            || event.eventType === 'ATTACH_TO_ORGANIZATION'
                                            || event.eventType === 'STOLEN'
                                            || event.eventType === 'DESTROYED'
                                        )"
                                        color="accent" (click)="setEventActive(event.id, true)"
                                        matTooltip="Return event to active.">
                                        Activate
                                    </button>
                                    <button mat-button *ngIf="event.active === true && (
                                            event.eventType === 'PARTNER_REGISTRATION'
                                            || event.eventType === 'END_CUSTOMER_REGISTRATION'
                                            || event.eventType === 'ATTACH_TO_ORGANIZATION'
                                            || event.eventType === 'STOLEN'
                                            || event.eventType === 'DESTROYED'
                                        )"
                                        color="warn" (click)="setEventActive(event.id, false)"
                                        matTooltip="Cancel event by inactivating it.">
                                        Inactivate
                                    </button>
                                </div>
                                <div *ngIf="userRole === 'sys_admin'">
                                    <button mat-button color="accent" routerLinkActive="active" routerLink="/machine-event/{{event.id}}">edit</button>
                                </div>
                                </ng-container>
                            </div>

                            <div *ngIf="showDetails && (!showActivesOnly || (showActivesOnly && event.active)) && event.data && event.data[event.eventType]"
                                fxLayout="column">
                                <div *ngIf="event.data[event.eventType].transactionId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Transaction ID</div>
                                    <div>{{ event.data[event.eventType].transactionId }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].serialNumberStatus" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Serial number status</div>
                                    <div>{{ event.data[event.eventType].serialNumberStatus }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].secureId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Secure ID</div>
                                    <div>{{ event.data[event.eventType].secureId }}</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].customer?.name" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Customer name</div>
                                    <div>{{ event.data[event.eventType].customer.name }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].partnerName" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Partner name</div>
                                    <div>{{ event.data[event.eventType].partnerName }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].partnerId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Partner ID</div>
                                    <div>{{ event.data[event.eventType].partnerId }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].company" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Company</div>
                                    <div>{{ event.data[event.eventType].company }}</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].orderNumber" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Order number</div>
                                    <div>{{ event.data[event.eventType].orderNumber }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].shipNumber" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Ship number</div>
                                    <div>{{ event.data[event.eventType].shipNumber }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].jobNumber" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Job number</div>
                                    <div>{{ event.data[event.eventType].jobNumber }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].stock" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Stock</div>
                                    <div>{{ event.data[event.eventType].stock }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].customerGroupCode" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Customer group code</div>
                                    <div>{{ event.data[event.eventType].customerGroupCode }} ({{
                                        event.data[event.eventType].customerGroupCode === 'E' ? 'Subsidiary' : ''
                                        }})</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].salesDate" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Sales date</div>
                                    <div>{{ event.data[event.eventType].salesDate }}</div>
                                </div>
                                <ng-container *ngIf="event.data[event.eventType].salesDateApproved">
                                    <div *ngIf="event.data[event.eventType].salesDateApproved.timestamp" fxLayout="row" class="event-attribute">
                                        <div fxFlex="200px">Sales date approved</div>
                                        <div>{{ event.data[event.eventType].salesDateApproved.timestamp }}</div>
                                    </div>
                                    <div *ngIf="event.data[event.eventType].salesDateApproved.user.name" fxLayout="row" class="event-attribute">
                                        <div fxFlex="200px">Sales date approved by</div>
                                        <div>{{ event.data[event.eventType].salesDateApproved.user.name }}</div>
                                    </div>
                                </ng-container>

                                <div *ngIf="event.data[event.eventType].claimId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Claim ID</div>
                                    <div>{{ event.data[event.eventType].claimId }}</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].organizationName" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Organization name</div>
                                    <div>{{ event.data[event.eventType].organizationName }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].organizationId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Organization ID</div>
                                    <div>{{ event.data[event.eventType].organizationId }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].mcu" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">MCU</div>
                                    <div>{{ event.data[event.eventType].mcu | json }}</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].reportedBy" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Reported by</div>
                                    <div>{{ event.data[event.eventType].reportedBy }}</div>
                                </div>
                                <div *ngIf="event.data[event.eventType].description" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">Description</div>
                                    <div>{{ event.data[event.eventType].description }}</div>
                                </div>

                                <div *ngIf="event.data[event.eventType].sysRowId" fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">SysRowID (EPICOR)</div>
                                    <div>{{ event.data[event.eventType].sysRowId | uppercase }}</div>
                                </div>
                                <div fxLayout="row" class="event-attribute">
                                    <div fxFlex="200px">ID</div>
                                    <div class="event-id">{{event.id}}</div>
                                </div>
                            </div>

                            <!--
                            <pre class="tighten-row" fxLayout="row">
                                {{ event.data[event.eventType] | json }}
                            </pre>
                            -->
                        </ng-container>
                    </div>
                </div>
            </fieldset>
        </div>

        <div *ngIf="machine.version" class="full" fxLayout="row">
            <fieldset fxFlex class="limited-access">
                <legend class="mat-subhead">Meta</legend>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="machine.schema" class="user-stamp">
                        <b>Schema</b><br>
                        {{machine.schema.type}}<br>
                        {{machine.schema.version}}
                    </div>
                    <div *ngIf="machine.version.created" class="user-stamp">
                        <b>Created</b><br>
                        {{machine.version.created.timestamp | date:'dd.MM.yyyy HH:mm'}}<br>
                        {{machine.version.created.user?.name}}
                    </div>
                    <div *ngIf="machine.version.modified" class="user-stamp">
                        <b>Modified</b><br>
                        {{machine.version.modified.timestamp | date:'dd.MM.yyyy HH:mm'}}<br>
                        {{machine.version.modified.user?.name}}
                    </div>
                </div>
            </fieldset>
        </div>

        <!--
        <div class="full" fxLayout="column">
            <div class="full transaction-row" *ngFor="let transaction of machine.transactions">
                <div class="transaction-row-header" fxLayout="row">
                    <div fxFlex="20">
                        {{ transaction.transferDate | date:'dd.MM.yyyy HH:mm' }}
                    </div>
                    <div fxFlex="10">
                        {{ transaction.transferId }}
                    </div>
                    <div fxFlex>
                        {{ getTransactionDescription(transaction.transferId) }}
                    </div>
                    <div fxFlex="25">
                        {{ transaction.companyId }}
                        <span *ngIf="transaction.partnerId">
                            &gt; {{ transaction.partnerId }}
                        </span>
                    </div>
                </div>
                <div *ngIf="transaction.partnerName" fxLayout="row">
                    <div class="transaction-topic">Partner:</div>
                    <a href="">{{ transaction.partnerName }}
                    <span *ngIf="transaction.partnerId">
                        ({{ transaction.partnerId }})
                    </span>
                    </a>
                </div>
                <div *ngIf="transaction.orderNumber" fxLayout="row">
                    <div class="transaction-topic">Order:</div>
                    <a href="">{{ transaction.orderNumber }}</a>
                </div>
                <div *ngIf="transaction.shipNumber" fxLayout="row">
                    <div class="transaction-topic">Ship:</div>
                    <a href="">{{ transaction.shipNumber }}</a>
                </div>
                <div *ngIf="transaction.jobNumber" fxLayout="row">
                    <div class="transaction-topic">Job:</div>
                    <a href="">{{ transaction.jobNumber }}</a>
                </div>
                <div *ngIf="transaction.stock" fxLayout="row">
                    <div class="transaction-topic">Stock:</div>
                    {{ transaction.stock }}
                </div>

                <div class="tighten-row" fxLayout="row">
                    {{ transaction | json }}
                </div>
            </div>
        </div>
        -->

    </div>
    <div *ngIf="linkedSerials" class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full" fxLayout="row" fxLayoutAlign="center center">
            <h3>Linked serials</h3>
        </div>
        <div class="full" fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex fxLayoutAlign="start center">
                <h2>{{ id }}</h2>
            </div>
        </div>

        <div class="full" fxLayout="column" fxLayoutAlign="center center">
            <div *ngFor="let linkedSerial of linkedSerials" fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="row" fxLayoutGap="20px">
                    <h2>
                        <a routerLinkActive="active" routerLink="/machines/{{linkedSerial.serialNumber}}">{{linkedSerial.serialNumber}}</a>
                    </h2>
                    <h2>{{ linkedSerial.timestamp | date:'dd.MM.yyyy' }}</h2>
                </div>
                <!--
                <pre>
                    {{ linkedSerial | json }}
                </pre>
                -->
            </div>
        </div>
    </div>
    <div *ngIf="!state.busy && (!machine || !machine.serialNumber) && !linkedSerials" class="page-container" fxLayout="column"
        fxLayoutAlign="start start">
        <div class="full" fxLayout="row">
            <h1 fxFlex>Machine info - S/N {{ id }}</h1>
        </div>
        <div>
            <h2>Invalid serial number or failed to load serial number info.</h2>
        </div>
    </div>
</div>

<ks-actionbar [hasCancel]="true" [hasSave]="false" [isSaving]="state.busy" [canSave]="!state.busy" (save)="save()"
    (cancel)="cancel()">
    <div fxLayout="row" fxFlex>
        <ng-container *ngIf="userRole === 'sys_admin'">
            <button mat-button color="warn" (click)="toggleAdmin()">
                <mat-icon class="kemppi-symbols">s</mat-icon>
            </button>

            <ng-container *ngIf="showAdminTools">
                <button mat-button mat-raised-button color="accent" (click)="recalculateMachine()">
                    <mat-icon>fast_forward</mat-icon> Recalculate machine-info
                </button>

                <button mat-button mat-raised-button color="warn" (click)="reindexMachine()" fxShow.lt-sm="false" matTooltip="Clear machine-events from ES, reindex events from DynamoDB to ES, regenerate machine-info.">
                    <mat-icon class="kemppi-symbols">s</mat-icon> REINDEX MACHINE
                </button>
            </ng-container>
        </ng-container>

        <!-- SALES DATE APPROVAL -->
        <button mat-button *ngIf="showApproveSalesDateButton" color="accent" (click)="approveSalesDate()"
            matTooltip="Ignores maximum warranty time, 36 months from delivery.">
            <mat-icon>change_history</mat-icon> Approve sales date
        </button>
        <button mat-button *ngIf="showCancelSalesDateApproveButton" color="warn" (click)="approveSalesDate(true)"
            matTooltip="Returns maximum warranty time, 36 months from delivery.">
            <mat-icon>change_history</mat-icon> Cancel sales date approvement
        </button>

        <!-- STOLEN -->
        <button mat-button color="warn" (click)="reportToStolen()" matTooltip="Create new STOLEN machine-event">
            <mat-icon>change_history</mat-icon> Report to stolen
        </button>
        <ng-container *ngIf="authUserScope === 'global'">
            <button mat-button>
                <mat-checkbox [(ngModel)]="showDetails">Show details</mat-checkbox>
            </button>
        </ng-container>
        <div fxFlex></div>
    </div>
</ks-actionbar>