import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { ExcelService } from 'src/app/services/excel.service';
import { StateService } from 'src/app/services/state.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ITestSerie } from 'src/app/models/ITestSerie';

import * as _ from 'lodash';

@Component({
    selector: 'app-test-serie-page',
    templateUrl: './test-serie-page.component.html',
    styleUrls: ['./test-serie-page.component.scss']
})
export class TestSeriePageComponent implements OnInit {

    state: any;

    /**
     * Search parameter testSerieId
     */
    searchTestSerieId: string;

    testSeries: Array<ITestSerie>;

    loading: boolean;

    EWARRANTY_FRONTEND_URL: string;

    /**
     * All item rows selected
     */
    allItemsChecked: boolean;

    /**
     * Is any of list items selected
     */
    anyItemSelected: boolean;

    /**
     * Collection of items that are checked
     */
    selectedItems: object;

    public _: any = _;

    constructor(
        private router: Router,
        private appConfigService: AppConfigService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private reportsService: ReportsService,
        private excelService: ExcelService
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };
        this.searchTestSerieId = '';
        this.loading = false;

        this.EWARRANTY_FRONTEND_URL = this.appConfigService.config.warrantyFrontendUrl;

        // SELECTS
        this.allItemsChecked = false;
        this.anyItemSelected = false;
        this.selectedItems = {};
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;
            this.searchTestSerieId = id || '';
            if (this.searchTestSerieId !== '') {
                this.getTestSerie();
            }
        });
    }

    getTestSerie() {
        this.stateService.state.file = this.searchTestSerieId;
        this.loading = true;
        this.reportsService.getTestSerie(this.searchTestSerieId).subscribe((testSeries) => {
            if (testSeries) {
                this.testSeries = testSeries as Array<ITestSerie>;
            }
            this.loading = false;
            this.resetSelectedItems();
        }, (error) => {
            console.log('Error, getTestSerie');
            this.loading = false;
            this.resetSelectedItems();
        });
    }

    searchTestSeries() {
        /*
        if (this.searchPart.length > 3) {
            this.loadingInfo = true;
            clearTimeout(this.searchPartTimer);
            this.searchPartTimer = setTimeout(() => {
                console.log('searchParts');
                const params = [
                    { key: 'searchPartDescription', value: '*' + this.searchPart.toUpperCase() + '*' }
                ];
                this.partsService.searchParts(params).subscribe((results) => {
                    this.partsList = results as Array<any>;
                    console.log('partsList', this.partsList);
                    this.loadingInfo = false;
                }, (error) => {
                    console.log('Error, searchParts');
                    this.loadingInfo = false;
                });
            }, 1000); // Time in ms before calling the backend search
        }
        */
    }

    // SELECTIONS

    /**
     * Reset selections (on search)
     */
    resetSelectedItems() {
        this.selectedItems = {};
        for (const testSerie of this.testSeries) {
            this.selectedItems[testSerie.id] = false;
        }
        this.allItemsChecked = false;
        this.checkSelectedItems();
    }

    /**
     * Check or uncheck allMachinesChecked
     */
    selectAllItems(checked) {
        _.map(this.testSeries, (testSerie) => {
            this.selectedItems[testSerie.id] = checked;
        });
        this.checkSelectedItems();
    }

    /**
     * Check is there any selected machines. (Show buttons)
     */
    checkSelectedItems() {
        this.anyItemSelected = _.some(_.values(this.selectedItems), (value) => value === true);
    }

    // EXPORT TO EXCEL

    exportSelectedItemsToXLSX() {
        const data = this._getSelectedItemsForExport();
        this.excelService.exportAsExcelFile(data, 'testseries');
    }

    _getSelectedItemsForExport() {
        const items = _.map(
            _.filter(this.testSeries, (testSerie) => {
                return this.selectedItems[testSerie.id] ? true : false;
            }),
            (testSerie) => {
                return {
                    'Serial number': testSerie.serialNumber,
                    'Product number': testSerie.productNumber,
                    'Test serie': testSerie.testSerie,
                    'Date': testSerie.timestamp,
                    'Warranty claims': testSerie.warrantyClaims.length,
                    'Claim ids': _.map(testSerie.warrantyClaims, 'id').join(', ')
                };

            }
        );
        return items;
    }
}
