<div class="full" fxLayout="column">
    <ks-toolbar
        [appInfo]="appInfo"
        [hasMenu]="stateService.state.hasMenu"
        [hasBack]="stateService.state.hasBack"
        [file]="stateService.state.file"
        (back)="back()">
    </ks-toolbar>
    <!--<pq-document-page fxFlex></pq-document-page>-->
    <div *ngIf="stateService.state.loading" fxLayout="row" style="margin-top: 200px;">
        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'"
                            style="margin: 0 auto;"
                            *ngIf="stateService.state.loading"
                            fxFlex fxLayoutAlign="center center">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!stateService.state.loading" id="ks-content-area">
        <router-outlet></router-outlet>
    </div>
</div>
