import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import * as bodybuilder from 'bodybuilder';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

declare var require: any;

// Dummy Machines service
// Now calls straight local ES search endpoint.
// TODO: Move all this functionality to warranty backend REST endpoint

@Injectable()
export class MachinesService {

    BACKENDURL: string;
    APIROOT: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
        this.token = this.authService.accessToken;
    }

    getMachineInfo(serialNumber) {
        console.log('getMachineInfo', serialNumber);
        return this.http.get(`${this.BACKENDURL}/machines/${serialNumber}`, { headers: this._getHeaders() });
    }

    getMachineInfoDetailed(serialNumber) {
        console.log('getMachineInfoDetailed', serialNumber);
        return this.http.get(`${this.BACKENDURL}/machines/${serialNumber}/detailed`, { headers: this._getHeaders() });
    }

    searchMachines(searchParams, sortParams = null, size = 50, from = 0) {
        console.log('searchMachines', searchParams, sortParams);
        const params: any = [];
        params.size = size;
        params.from = from;
        if (searchParams && searchParams.length > 0) {
            params.search = this._getSearchString(searchParams);
        }
        if (sortParams && sortParams.length > 0) {
            params.sort = this._getSortString(sortParams);
        }
        return this.http.get(`${this.BACKENDURL}/machines`, {
            headers: this._getHeaders(),
            params: params
        });
    }

    /**
     * Machine-event by serial
     */
    getMachineEventsBySerial(serialNumber) {
        console.log('getMachineEventsBySerial', serialNumber);
        return this.http.get(`${this.BACKENDURL}/machines/${serialNumber}/events`, { headers: this._getHeaders() });
    }
    /**
     * Sofware-licenses by serial
     * @param serialNumber
     */
    getMachineSoftwareLicensesBySerial(serialNumber: string) {
        console.log('getMachineSoftwareLicensesBySerial', { serialNumber: serialNumber });
        const url = `${this.BACKENDURL}/machines/software-licenses/${encodeURIComponent(serialNumber)}`;
        return this.http.get(url, { headers: this._getHeaders() });
    }

    searchMachineEvents(searchParams, sortParams = null, size = 50, from = 0) {
        console.log('searchMachines', searchParams, sortParams);
        const params: any = [];
        params.size = size;
        params.from = from;
        if (searchParams && searchParams.length > 0) {
            params.search = this._getSearchString(searchParams);
        }
        if (sortParams && sortParams.length > 0) {
            params.sort = this._getSortString(sortParams);
        }
        return this.http.get(`${this.BACKENDURL}/machines/events`, {
            headers: this._getHeaders(),
            params: params
        });
    }

    getLinkedSerialsByInternalSerial(internalSerialNumber) {
        console.log('getLinkedSerialsByInternalSerial', internalSerialNumber);
        return this.http.get(`${this.BACKENDURL}/machines/linked/${internalSerialNumber}`, { headers: this._getHeaders() });
    }

    /**
     * Machinery get machine-event by id (admins only)
     */
    getMachineEvent(eventId) {
        console.log('getMachineEvent', eventId);
        return this.http.get(`${this.BACKENDURL}/machines/event/${eventId}`, { headers: this._getHeaders() });
    }
    saveMachineEvent(machineEvent) {
        console.log('saveMachineEvent', machineEvent);
        return this.http.post(`${this.BACKENDURL}/machines/event`, JSON.stringify(machineEvent), { headers: this._getHeaders() });
    }

    searchDeviceLogsBySerial(searchParams, sortParams = null, size = 10, from = 0) {
        console.log('searchDeviceLogsBySerial', searchParams, sortParams);
        const params: any = [];
        params.size = size;
        params.from = from;
        if (searchParams && searchParams.length > 0) {
            params.search = this._getSearchString(searchParams);
        }
        if (sortParams && sortParams.length > 0) {
            params.sort = this._getSortString(sortParams);
        }
        return this.http.get(`${this.BACKENDURL}/device-logs`, {
            headers: this._getHeaders(),
            params: params
        });
    }

    /**
     * REINDEX MACHINE - Admin tools
     */
    reindexMachine(serialNumber) {
        console.log('reindexMachine', serialNumber);
        return this.http.post(`${this.BACKENDURL}/machines/${serialNumber}/reindex`, JSON.stringify({}), { headers: this._getHeaders() });
    }

    postMachineInfoCalculationRequest(serialNumbers, useQueue = false) {
        console.log('postMachineInfoCalculationRequest', { serialNumbers, useQueue });
        return this.http.post(`${this.BACKENDURL}/machines/info`, JSON.stringify({ serialNumbers: serialNumbers, queue: useQueue }), { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

    // Returns search params ?search=
    // key=value&key2=value2,value22
    // key%3Dvalue%26key2%3Dvalue2%2Cvalue22
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + '=' + p.value;
        }).join('&');
        // const queryStringParams = _.map(params, (p) => {
        //     return p.key + '%3D' + p.value;
        // }).join('%26').replace(/,/g, '%2C').replace(/\#/g, '%23');
        // if (queryStringParams) {
        //     return `&search=${queryStringParams}`;
        // } else {
        //     return '';
        // }
        return queryStringParams;
    }

    // Returns sort params ?sort=key:direction;key2:direction2
    _getSortString(params: Array<{ key: string; direction: string }>) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.direction;
        }).join(',');
        return queryStringParams;
    }


    // LOCAL VERSIONS

    // searchMachines(params, fields = ['serialNumber', 'productId', 'manufactureDate'], size = 50) {
    //     console.log('searchMachines', params);
    //     const builder = bodybuilder();
    //     params.forEach(param => {
    //         builder.filter(param.type || 'match', param.key, param.value);
    //     });
    //     const body = builder
    //         .rawOption('_source', { includes: fields })
    //         .size(size)
    //         .sort('manufactureDate', 'desc')
    //         .build();
    //     console.log('body', JSON.stringify(body));

    //     return this.http.post(`${this.APIROOT}/_search`, body);
    // }

    getMachinesReport(options) {
        console.log('getMachinesReport', options);
        const builder = bodybuilder();
        if (options.fields) {
            builder.rawOption('_source', { includes: options.fields });
        }
        if (options.filters) {
            options.filters.forEach(filter => {
                builder.filter(filter.type || 'match', filter.key, filter.value);
            });
        }
        if (options.aggregations) {
            options.aggregations.forEach(aggregation => {
                builder.aggregation(aggregation.type, aggregation.field, aggregation.name, aggregation.options);
            });
        }
        if (options.sort) {
            builder.sort(options.sort.field, options.sort.direction || 'desc');
        }
        const body = builder.build();
        console.log('body', JSON.stringify(body));

        return this.http.post(`${this.APIROOT}/_search`, body);
    }

    getMachineTypes() {
        console.log('getMachineTypes');
        const builder = bodybuilder();
        const body = builder
            .size(0)
            .aggregation('terms', 'productId', {
                size: 10000
            }, agg => agg.aggregation('top_hits', undefined, { _source: { includes: ['productId', 'productGroup', 'productName'] }, size: 1 }, 'productName'))
            .build();

        console.log('body', JSON.stringify(body));

        return this.http.post(`${this.APIROOT}/_search`, body);
    }

    // getMachineInfo(serialNumber) {
    //     const body = bodybuilder().query('match', 'serialNumber', serialNumber).build();
    //     console.log('body', body);

    //     return this.http.post(`${this.APIROOT}/_search`, body);
    // }


}
