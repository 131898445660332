import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { MachinesService } from 'src/app/services/machines.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';

import { UserProfile } from 'src/app/models/user';
import { Machine } from 'src/app/models/machine';
import { IMachineInfo } from 'src/app/models/IMachineInfo';
import { IMachineEvent } from 'src/app/models/IMachineEvent';
import { ILinkedSerial } from 'src/app/models/ILinkedSerial';
import { ISoftwareLicense } from 'src/app/models/ISoftwareLicense';
import { X8ErrorType, X8ErrorCode } from './lib/X8-errors';
import { Curves } from './lib/curves';

const MACHINEREGISTRY_LEGACY_URL = 'http://mr/machine.aspx/details?machineno=';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-machine-page',
    templateUrl: './machine-page.component.html',
    styleUrls: ['./machine-page.component.scss']
})
export class MachinePageComponent implements OnInit, OnDestroy {

    machine: IMachineInfo;
    machineEvents: Array<IMachineEvent>;
    partnerInfo: ICustomerInfo;
    id: string;

    linkedSerials: Array<ILinkedSerial>;

    softwareLicenses: Array<ISoftwareLicense>;
    /**
     * Collected device info logs (For example from Mobile maintenance)
     */
    deviceLogs?: {
        items?: Array<any>;
        totalCount?: number;
    };
    selectedDeviceLogIndex: number;
    deviceLogsLoading: boolean;

    state: any;
    user: UserProfile;
    userRole: string;
    userCompany: string;

    eventsTypeFilter: string;
    eventsSourceFilter: string;
    showDetails: boolean;
    showActivesOnly: boolean;

    authUserScope = 'partner';
    authUserRole = '';

    MACHINEREGISTRY_LEGACY_URL = MACHINEREGISTRY_LEGACY_URL;
    EWARRANTY_FRONTEND_URL: string;

    mcuTypes = ['memchip', 'AI', 'AS', 'AV', 'CLCD', 'CON', 'DB', 'DC', 'GEP', 'IM', 'IO', 'MS', 'PS', 'RI', 'SC', 'SP', 'WF', 'WP'];

    mcuDescriptions = {
        memchip: 'Memchip',
        AI: 'Datacatch',
        AS: 'Arc SAW adapter',
        AV: 'Arc validation',
        CLCD: 'CLCD',
        CON: 'Connectivity card',
        DB: 'Database',
        DC: 'Data collector',
        GEP: 'GEP',
        IM: 'Indian mig welding panel',
        IO: 'IO',
        MS: 'Memory stick',
        PS: 'Power source',
        RI: 'Robot interface',
        SC: 'System card',
        SP: 'Setup panel',
        WF: 'Wire feeder',
        WP: 'Welding panel'
    };

    now = new Date().toISOString();

    warrantyTime: number;
    showApproveSalesDateButton: boolean;
    showCancelSalesDateApproveButton: boolean;

    showAdminTools = false;

    _: any;

    X8ErrorType = X8ErrorType;
    X8ErrorCode = X8ErrorCode;

    Curves = Curves;

    onDestroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        public stateService: StateService,
        private machinesService: MachinesService,
        private customersService: CustomersService
    ) {
        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e: any) => {
            // If it is a NavigationEnd event and claim id is changed re-initalise the component
            if (e instanceof NavigationEnd) {
                if (((this.machine && this.machine.serialNumber) || this.linkedSerials) && this.id !== this.activatedRoute.snapshot.params['id']) {
                    // Force reload page
                    this.ngOnInit();
                }
            }
        });
        // lodash
        this._ = _;
    }

    ngOnInit() {
        this.state = {
            busy: true
        };

        this.machine = null;
        this.linkedSerials = null;
        this.deviceLogs = null;
        this.deviceLogsLoading = false;
        this.eventsTypeFilter = '';
        this.eventsSourceFilter = '';
        this.showDetails = false;
        this.showActivesOnly = true;

        this.EWARRANTY_FRONTEND_URL = this.appConfigService.config.warrantyFrontendUrl;

        setTimeout(() => {
            this.stateService.state.hasBack = true;

            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;

            this.getMachineInfo(this.id);

            this.user = this.authService.userProfile;
            this.userRole = this.authService.userProfile.role;
            this.userCompany = this.authService.userProfile.company;
            this.authUserScope = this.authService.userProfile.permissions.scope;
            this.authUserRole = this.authService.userProfile.role;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.onDestroy$.unsubscribe();
    }

    getMachineInfo(serialNumber) {
        this.partnerInfo = null;
        // Get detailed machine info by serial number
        // Contains: machine-events, serial-links, mcu-info and warranty-claims
        this.machinesService.getMachineInfoDetailed(serialNumber)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((machineInfo: IMachineInfo) => {
                if (machineInfo) {
                    this.machine = machineInfo;

                    this.calculateWarrantyTimeInMonths();

                    this.createAdditionalMachineEvents();

                    // Get customer info
                    if (this.machine && this.machine.company && this.machine.partnerId) {
                        // Check permission to fetch customer info
                        // (Denied for subsidiaries from different company, so do not even try)
                        if (this.authUserScope === 'global' || (this.authUserScope === 'company' && this.userCompany === this.machine.company)) {
                            this.customersService.getCustomerInfo(this.machine.company, this.machine.partnerId)
                                .pipe(takeUntil(this.onDestroy$))
                                .subscribe((customerInfo: ICustomerInfo) => {
                                    this.partnerInfo = customerInfo;
                                });
                        }
                    }

                    // Get device logs
                    if (this.machine && this.machine.serialNumber) {
                        this.getDeviceLogs();
                        this.getSoftwareLicensesBySerial();
                    }
                    this.state.busy = false;
                } else {
                    this.getLinkedSerialByInternalSerial(serialNumber);
                }
            }, (error) => {
                this.state.busy = false;
            });
    }

    getDeviceLogs() {
        this.deviceLogsLoading = true;
        const deviceLogsSearchParams = [{ key: 'serialNumber', value: this.machine.serialNumber }];
        const deviceLogsSortParams = [{ key: 'updateTimestamp', direction: 'desc' }];
        const currentLength = _.get(this.deviceLogs, 'items.length');
        const size = currentLength ? 20 : 5; // ? (Load more) : (Default)
        const from = currentLength ? currentLength : 0;
        this.machinesService.searchDeviceLogsBySerial(deviceLogsSearchParams, deviceLogsSortParams, size, from)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((deviceLogs: any) => {
                console.log('deviceLogs', deviceLogs);
                if (this.deviceLogs !== 'undefined' && this.deviceLogs !== null) {
                    this.deviceLogs.items.push(...deviceLogs.items);
                    this.deviceLogs.totalCount = deviceLogs.totalCount;
                } else {
                    this.deviceLogs = deviceLogs;
                }

                // if (this.deviceLogs && this.deviceLogs.items && this.deviceLogs.items.length > 0) {
                //     this.selectedDeviceLogIndex = 0;
                // }
                this.deviceLogsLoading = false;
            }, (error) => {
                this.deviceLogsLoading = false;
            });
    }

    getLinkedSerialByInternalSerial(serialNumber) {
        this.machinesService.getLinkedSerialsByInternalSerial(serialNumber)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((linkedSerials: Array<ILinkedSerial>) => {
                if (linkedSerials && linkedSerials.length > 0) {
                    this.linkedSerials = linkedSerials;

                    // Single match found, redirect straight to correct serial
                    if (linkedSerials.length === 1) {
                        this.router.navigate(['machines', linkedSerials[0].serialNumber]);
                    }
                }
                this.state.busy = false;
            }, (error) => {
                this.state.busy = false;
            });
    }

    getSoftwareLicensesBySerial() {
        this.machinesService.getMachineSoftwareLicensesBySerial(this.machine.serialNumber)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((licenses: ISoftwareLicense[]) => {
                console.log('Software licenses for machine', { serialNumber: this.machine.serialNumber, licenses })
                // licenses sorted by order date
                this.softwareLicenses = licenses;
            });

    }

    calculateWarrantyTimeInMonths() {
        this.warrantyTime = null;
        // Warranty time is calculated from salesDate / partnerRegistrationDate / deliveryDate / manufactureDate to warrantyExpireDate
        // Should be 24months + extended 12months, but might be shorter if dealer registration is coming after the 12months of delivery.
        // More info from MachineRegistry documentation.
        if (this.machine.warrantyExpireDate && this.machine.warrantyStartDate) {
            this.warrantyTime = this.getWarrantyTimeInMonths(new Date(this.machine.warrantyStartDate), new Date(this.machine.warrantyExpireDate));
        }

        let machineWarrantyTime = 0;
        if (this.machine && this.machine.productInfo) {
            if (this.machine.productInfo.warrantyCode === 'L1YEAR') {
                machineWarrantyTime = 12;
            } else if (this.machine.productInfo.warrantyCode === 'L2YEAR'
                || this.machine.productInfo.warrantyCode === '2YEAR'
                || (this.machine.productInfo.warrantyCode === '2YEAR1E' && this.machine.endCustomerRegistrationDate === null)) {
                machineWarrantyTime = 24;
            } else if (this.machine.productInfo.warrantyCode === '2YEAR1E' && this.machine.endCustomerRegistrationDate !== null) {
                machineWarrantyTime = 36;
            }
        }

        this.showApproveSalesDateButton = false;
        if (
            (this.userRole === 'sys_admin' || this.userRole === 'koy_user')
            && this.machine.salesDate && this.warrantyTime !== null
            && this.warrantyTime !== machineWarrantyTime
        ) {
            this.showApproveSalesDateButton = true;
        }
        // Get partner registration event
        const partnerRegistrationEvent: IMachineEvent = _.find(this.machine.machineEvents, {
            eventType: 'PARTNER_REGISTRATION',
            data: {
                PARTNER_REGISTRATION: {
                    salesDate: this.machine.salesDate
                }
            }
        }) as IMachineEvent;

        this.showCancelSalesDateApproveButton = false;
        if (
            (this.userRole === 'sys_admin' || this.userRole === 'koy_user')
            && partnerRegistrationEvent
            && _.get(partnerRegistrationEvent, 'data.PARTNER_REGISTRATION.salesDateApproved.timestamp', null) !== null
        ) {
            this.showCancelSalesDateApproveButton = true;
        }
    }

    /**
     * Calculate warranty period in months
     * @deprecated
     */
    monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : Math.round(months);
    }

    /**
     * Calculate warranty period in months
     */
     getWarrantyTimeInMonths(startDate, endDate) {
        // Calculate the differences between the start and end dates
        let yearsDifference = endDate.getFullYear() - startDate.getFullYear();
        let monthsDifference = endDate.getMonth() - startDate.getMonth();
        let daysDifference = endDate.getDate() - startDate.getDate();

        let monthCorrection = 0;
        // If the day difference between the 2 months is negative, the last month is not a whole month.
        if (daysDifference < 0) {
            monthCorrection = -1;
        }
        const months = yearsDifference * 12 + monthsDifference + monthCorrection;

        return months <= 0 ? 0 : months;
    };

    /**
     * Create additinal machine events
     * Actual events are stored to macine-events. This creates additional machine events from data like:
     * machine.mcuInfo          = Factory test
     * machine.warrantyClaims[] = Warranty claim
     */
    createAdditionalMachineEvents() {
        if (this.machine.mcuInfo) {
            const machineEvent: IMachineEvent = {
                serialNumber: this.machine.serialNumber,
                eventTimestamp: this.machine.mcuInfo.timestamp,
                systemTimestamp: this.machine.mcuInfo.timestamp,
                eventSource: 'FACTORY',
                eventType: 'ASSEMBLY',
                eventDescription: 'Factory assembly test',
                active: true,
                data: {},
                schema: {
                    type: 'machine-event',
                    version: '1.0'
                }
            };
            this.machine.machineEvents.push(machineEvent);
        }

        if (this.machine.warrantyClaims && this.machine.warrantyClaims.length > 0) {
            for (const warrantyClaim of this.machine.warrantyClaims) {
                const machineEvent: IMachineEvent = {
                    serialNumber: this.machine.serialNumber,
                    eventTimestamp: _.get(warrantyClaim, 'data.repairDate', ''),
                    systemTimestamp: _.get(warrantyClaim, 'data.repairDate', ''),
                    eventSource: 'EWARRANTY',
                    eventType: 'WARRANTY_CLAIM',
                    eventDescription: 'Warranty claim',
                    active: true,
                    data: {},
                    schema: {
                        type: 'machine-event',
                        version: '1.0'
                    }
                };
                this.machine.machineEvents.push(machineEvent);
            }
        }

        // DEBUG / TEST / MOCK
        // this.machine.validations = [];
        // this.machine.validations.push({
        //     id: '160-000001',
        //     company: '160',
        //     partnerId: '160',
        //     partnerName: 'Kemppi Germany',
        //     data: {
        //         date: '2019-01-24T15:27:00.000Z'
        //     }
        // });
        // this.machine.validations.push({
        //     id: '160-000002',
        //     company: '160',
        //     partnerId: '160',
        //     partnerName: 'Kemppi Germany',
        //     data: {
        //         date: '2020-01-24T15:27:00.000Z'
        //     }
        // });

        // if (this.machine.validations && this.machine.validations.length > 0) {
        //     for (const validations of this.machine.validations) {
        //         const machineEvent: IMachineEvent = {
        //             serialNumber: this.machine.serialNumber,
        //             eventTimestamp: validations.data.date,
        //             systemTimestamp: validations.data.date,
        //             eventSource: 'ARCVALIDATOR',
        //             eventType: 'VALIDATION',
        //             eventDescription: 'Validation',
        //             active: true,
        //             data: {},
        //             schema: {
        //                 type: 'machine-event',
        //                 version: '1.0'
        //             }
        //         }
        //         this.machine.machineEvents.push(machineEvent);
        //     }
        // }
        this.machine.machineEvents = _.sortBy(this.machine.machineEvents, ['eventTimestamp']);
    }

    /**
     * Approve sales date
     * Modifies partner registration event by adding salesDateApproved { timestamp: '', user: { id: '', name: '' }}
     * This additional info effects to warranty expire calculation, it ignores the maximum 36 months from delivery rule.
     * This feature is used when machine has been too long time in stock and then sold to end customer.
     * Partner have to specially request Kemppi service to enable this for the machine.
     */
    approveSalesDate(cancel = false) {
        console.log('APPROVE salesDate');
        const salesDateApproved = cancel ? null : {
            timestamp: new Date().toISOString(),
            user: {
                id: this.user.id,
                name: this.user.name
            }
        };

        // Get partner registration event
        const partnerRegistrationEvent: IMachineEvent = _.find(this.machine.machineEvents, {
            eventType: 'PARTNER_REGISTRATION',
            data: {
                PARTNER_REGISTRATION: {
                    salesDate: this.machine.salesDate
                }
            }
        }) as IMachineEvent;
        if (partnerRegistrationEvent) {
            // Add approveSalesDate
            _.set(partnerRegistrationEvent, 'data.PARTNER_REGISTRATION.salesDateApproved', salesDateApproved);
            // Send to backend
            this.machinesService.saveMachineEvent(partnerRegistrationEvent)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((response) => {
                    console.log('saveMachineEvent response', response);
                    this.notify('Machine-event sent');
                    setTimeout(() => {
                        this.getMachineInfo(this.id);
                    }, 3000);
                }, (error) => {
                    console.log('Error saving machine-event', error);
                    this.showError(error);
                });
        }
        console.log('partnerRegistration', partnerRegistrationEvent);
    }

    setEventActive(eventId, active = true) {
        // Get partner registration event
        const event: IMachineEvent = _.find(this.machine.machineEvents, {
            id: eventId
        }) as IMachineEvent;
        if (event && (
            event.eventType === 'PARTNER_REGISTRATION'
            || event.eventType === 'END_CUSTOMER_REGISTRATION'
            || event.eventType === 'ATTACH_TO_ORGANIZATION'
            || event.eventType === 'STOLEN'
            || event.eventType === 'DESTROYED'
        )) {
            // Add approveSalesDate
            _.set(event, 'active', active);
            // Send to backend
            this.machinesService.saveMachineEvent(event)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((response) => {
                    console.log('saveMachineEvent response', response);
                    this.notify('Machine-event sent');
                    setTimeout(() => {
                        this.getMachineInfo(this.id);
                    }, 2000);
                }, (error) => {
                    console.log('Error saving machine-event');
                });
        }
    }

    save() {

    }
    cancel() {
        this.router.navigate(['machines']);
    }

    openRegistration() {
        // not implemented
    }

    openEndCustomerRegistration() {
        // not implemented
    }

    reportToStolen() {
        this.router.navigate(['machine-event', 'new'], {
            queryParams: {
                serial: this.machine.serialNumber,
                type: 'STOLEN'
            }
        });
    }

    /**
     * Call machine-info handler for serials.
     */
    recalculateMachine() {
        this.machinesService.postMachineInfoCalculationRequest([this.id], false)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((response) => {
                this.notify('Machine send to machine-info recalculation');
                setTimeout(() => {
                    this.getMachineInfo(this.id);
                }, 2000);
            });
    }

    /**
     * Clear machine events from ES and resend them from Dynamo to ES
     */
    reindexMachine() {
        this.machinesService.reindexMachine(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((response) => {
                console.log('REINDEX MACHINE', this.id, response);
                this.notify(this.id + ' events reindexed and send to machine-info calculation queue');
                setTimeout(() => {
                    this.getMachineInfo(this.id);
                }, 2000);
            });
    }

    openInLegacyMachineRegistry() {
        const url = this.MACHINEREGISTRY_LEGACY_URL + this.machine.serialNumber;
        window.open(url, '_blank');
    }

    /**
     * Toggle admin tools
     */
    toggleAdmin() {
        if (this.authUserRole === 'sys_admin') {
            this.showAdminTools = !this.showAdminTools;
        }
    }

    /**
     * Time convert seconds to days, hours, minutes and seconds
     * Helper method
     */
    timeConvertFromSecondsToString(totalseconds) {
        const days = (totalseconds / 60 / 60 / 24);
        const rdays = Math.floor(days);
        const hours = (days - rdays) * 24;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.floor(minutes);
        const seconds = (minutes - rminutes) * 60;
        const rseconds = Math.round(seconds);
        return rdays + 'd ' + rhours + 'h ' + rminutes + 'm ' + rseconds + 's';
    }

    /**
     * Show error snackbar notification
     */
    showError(error) {
        const message = _.get(error, 'message');
        const status = _.get(error, 'status');
        const type = _.get(error, 'type') || _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'reason') || _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        if (status === 401) {
            this.snackBar.open('Authorization error (no permission)', 'OK', {
                duration: 5000,
                panelClass: 'error-message'
            });
        } else {
            this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
                duration: 5000,
                panelClass: 'error-message'
            });
        }
    }

    notify(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 2000
        });
    }

}
