import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { PartsService } from 'src/app/services/parts.service';
import { IPart } from 'src/app/models/IPart';

import { Company, Companies } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';

import * as _ from 'lodash';

@Component({
    selector: 'app-parts-page',
    templateUrl: './parts-page.component.html',
    styleUrls: ['./parts-page.component.scss']
})
export class PartsPageComponent implements OnInit {

    state: any;

    /**
     * Loaded part info
     */
    partInfo: IPart;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    /**
     * Search parameter partnerId
     */
    searchPartId: string;

    /**
     * Current search bar value
     */
    searchText = '';

    /**
     * Current user role
     */
    authUserRole: string;

    /**
     * Current user company
     */
    authUserCompany: string;

    /**
     * Current user scope
     * 'global' | 'company' | 'partner'
     */
    authUserScope = 'partner';

    searchPart: string;
    searchPartTimer: any;
    partsList = [];
    partsFormControl = new FormControl();
    loadingInfo: boolean;

    public _: any = _;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private partsService: PartsService
    ) {
        this.companies = Companies;
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };
        this.searchPartId = '';
        this.searchPart = '';
        this.loadingInfo = false;
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;

            this.authUserRole = this.authService.userProfile.role;
            this.authUserCompany = this.authService.userProfile.company;
            this.authUserScope = this.authService.userProfile.permissions.scope;
        });
    }

    setFilter(key, value) {
        if (value === '') {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
        }
    }
    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        console.log('removeFilter', key, this.searchParams);
        if (index !== -1) {
            console.log('removeFilter', key, index);
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
    }

    getPart() {
        this.loadingInfo = true;
        const params = [
            { key: 'name', value: '*' + this.searchPart.toUpperCase() + '*' }
        ];
        this.partsService.getPartInfo(this.searchPartId).subscribe((partInfo) => {
            if (partInfo) {
                this.partInfo = partInfo as IPart;
                this.searchPart = this.partInfo.partDescription;
                console.log('partInfo', this.partInfo);
            }
            this.loadingInfo = false;
        }, (error) => {
            console.log('Error, getPart');
            this.loadingInfo = false;
        });
    }

    searchParts() {
        if (this.searchPart.length > 3) {
            this.loadingInfo = true;
            clearTimeout(this.searchPartTimer);
            this.searchPartTimer = setTimeout(() => {
                console.log('searchParts');
                const params = [
                    { key: 'searchPartDescription', value: '*' + this.searchPart.toUpperCase() + '*' }
                ];
                this.partsService.searchParts(params).subscribe((results) => {
                    this.partsList = results as Array<any>;
                    console.log('partsList', this.partsList);
                    this.loadingInfo = false;
                }, (error) => {
                    console.log('Error, searchParts');
                    this.loadingInfo = false;
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changePart(part) {
        if (part && part.value && part.value.partDescription) {
            console.log('changePart', part.value);
            this.searchPart = part.value.partDescription;
            this.searchPartId = part.value.id;
            this.getPart();
        }
    }

    getDebugData() {
        // return this.machineRegistryService.getLatest(); // REMOVE
        return null;
    }
}
