
<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">O</mat-icon>
            <div fxLayout="column">
                <h2>Parts</h2>
                <div>Get part details, find by product name or number</div>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">Search parts</h2>
            <div class="full" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">

            <form class="full">
                <mat-form-field class="full" fxFlex.gt-xs="380px">
                    <mat-label>{{ 'common.product_name' | translate }}</mat-label>
                    <input type="text" matInput [formControl]="partsFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPart" (keyup)="searchParts()">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changePart($event.option)">
                    <mat-option *ngFor="let part of partsList" [value]="part">
                        <small fxFlex>{{part.partDescription}}</small>
                        <small fxFlex="10px"></small>
                        <small fxFlex="100px"><b>{{ part.id }}</b></small>
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>

            <mat-form-field>
                <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                <input matInput [(ngModel)]="searchPartId" (keydown.enter)="getPart()">
            </mat-form-field>
            <div fxFlex.gt-xs="100px">
                <button mat-raised-button *ngIf="!loadingInfo" color="accent" (click)="getPart()" [disabled]="!searchPartId || searchPartId === ''">{{ 'common.search' | translate }}</button>
                <mat-progress-spinner matSuffix *ngIf="loadingInfo" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
            </div>
            <div fxFlex></div>
        </div>

        <div *ngIf="partInfo" class="full container">
            <h2 class="mat-subhead">Product info</h2>
            <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <mat-form-field fxFlex.gt-xs="380px">
                    <input matInput placeholder="Product name" [(ngModel)]="partInfo.partDescription"
                        [readonly]="true">
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="180px">
                    <input matInput placeholder="Product number" [(ngModel)]="partInfo.partNumber" [readonly]="true">
                </mat-form-field>
            </div>
            <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Prod code" [(ngModel)]="partInfo.prodCode" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Class ID" [(ngModel)]="partInfo.classID" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Sales UM" [(ngModel)]="partInfo.salesUM" [readonly]="true">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Category code" [(ngModel)]="partInfo.categoryCode"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Family code" [(ngModel)]="partInfo.familyCode"
                            [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Sales ABC" [(ngModel)]="partInfo.salesABC" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Identification" [(ngModel)]="partInfo.identification" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Repair time" [(ngModel)]="partInfo.repairTime" [readonly]="true">
                        <span matSuffix>min</span>
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Warranty code" [(ngModel)]="partInfo.warrantyCode" [readonly]="true">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Inactive" [(ngModel)]="partInfo.inactive" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Is software" [(ngModel)]="partInfo.isSoftware" [readonly]="true">
                    </mat-form-field>
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Run out" [(ngModel)]="partInfo.runOut" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="partInfo" class="full" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="100px">
                        <input matInput placeholder="Main group" [(ngModel)]="partInfo.mainGroup" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="partInfo && partInfo.partDescriptions" class="full" class="weak small">
                <h2 class="mat-subhead">Part descriptions</h2>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <div fxFlex="50px">DAN</div>{{partInfo.partDescriptions.dan}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">DEU</div>{{partInfo.partDescriptions.deu}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">ENU</div>{{partInfo.partDescriptions.enu}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">FIN</div>{{partInfo.partDescriptions.fin}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">FRA</div>{{partInfo.partDescriptions.fra}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">NLD</div>{{partInfo.partDescriptions.nld}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">NOR</div>{{partInfo.partDescriptions.nor}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">PLK</div>{{partInfo.partDescriptions.plk}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">RUS</div>{{partInfo.partDescriptions.rus}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">SCH</div>{{partInfo.partDescriptions.sch}}
                    </div>
                    <div fxLayout="row">
                        <div fxFlex="50px">SVE</div>{{partInfo.partDescriptions.sve}}
                    </div>
                </div>
            </div>

            <div>
                <h2 class="mat-subhead">Meta</h2>
                <small class="weak"><i>(This information is maintained in ERP and replicated to Machinery)</i></small><br>
                <small class="weak"><i>Updated: {{partInfo.updated | date:'dd.MM.yyyy HH:mm'}}</i></small>
            </div>
        </div>

    </div>

    <!--
    <pre>
        partInfo:
        {{ partInfo | json }}

        partsList:
        {{ partsList | json }}
    </pre>
    -->
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
</ks-actionbar>
