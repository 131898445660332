// Get latest from: https://swhub.kemppi.com/api/softwares/fileids
export const Curves = {
    "e0cc:1ca0": {
        "name": "A101 PMIG AlMg5",
        "desc": "Automation 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e0cc:1cc0": {
        "name": "A102 PMIG AlMg5",
        "desc": "Automation 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e0cc:1e00": {
        "name": "A112 PMIG AlSi5",
        "desc": "Automation 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e094:2ce0": {
        "name": "C103 1-MIG CuSi3",
        "desc": "Automation 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e0d4:2ce0": {
        "name": "C103 PMIG CuSi3",
        "desc": "Automation 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e094:2e20": {
        "name": "C113 1-MIG CuAl8",
        "desc": "Automation 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e0d4:2e20": {
        "name": "C113 PMIG CuAl8",
        "desc": "Automation 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "e084:3cc1": {
        "name": "F102 1-MIG Fe",
        "desc": "Automation 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e084:3ce1": {
        "name": "F103 1-MIG Fe",
        "desc": "Automation 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e0c4:3ce0": {
        "name": "F103 PMIG Fe",
        "desc": "Automation 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e084:3d00": {
        "name": "F104 1-MIG Fe",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c4:3d01": {
        "name": "F104 PMIG Fe",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e084:3f60": {
        "name": "F123 1-MIG Fe",
        "desc": "Automation 1.0mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "e084:3f80": {
        "name": "F124 1-MIG Fe",
        "desc": "Automation 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c4:4d00": {
        "name": "M104 PMIG Fe Metal",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c4:5d00": {
        "name": "R104 PMIG Fe Rutil",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6ca0": {
        "name": "S101 PMIG CrNiMo 19 12",
        "desc": "Automation 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6cc0": {
        "name": "S102 PMIG CrNiMo 19 12",
        "desc": "Automation 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6d00": {
        "name": "S104 PMIG CrNiMo 19 12",
        "desc": "Automation 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6d40": {
        "name": "S106 PMIG CrNiMo 19 12",
        "desc": "Automation 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6f40": {
        "name": "S122 PMIG G 19 9 Nb Si",
        "desc": "Automation 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6f80": {
        "name": "S124 PMIG CrNiMo 19 12",
        "desc": "Automation 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "e0c8:6fc0": {
        "name": "S126 PMIG CrNiMo 19 12",
        "desc": "Automation 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9294:2060": {
        "name": "C03 WThin CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9294:21a0": {
        "name": "C13 WThin CuAl8",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9244:3020": {
        "name": "F01 WRoot Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:3020": {
        "name": "F01 WThin Fe",
        "desc": "Standard 0,8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:3040": {
        "name": "F02 WRoot Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:3040": {
        "name": "F02 WThin Fe",
        "desc": "Standard 0,9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:3060": {
        "name": "F03 WRoot Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:3060": {
        "name": "F03 WThin Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:3080": {
        "name": "F04 WRoot Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:3081": {
        "name": "F04 WThin Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "9244:31a0": {
        "name": "F13 WRoot Fe",
        "desc": "Standard 1.0mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:31c0": {
        "name": "F14 WRoot Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:32a0": {
        "name": "F21 WThin Fe",
        "desc": "Standard 0,8mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:32c0": {
        "name": "F22 WRoot Fe",
        "desc": "Standard 0.9mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:32c0": {
        "name": "F22 WThin Fe",
        "desc": "Standard 0,9mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:32e0": {
        "name": "F23 WRoot Fe",
        "desc": "Standard 1.0mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:32e0": {
        "name": "F23 WThin Fe",
        "desc": "Standard 1.0mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:3300": {
        "name": "F24 WRoot Fe",
        "desc": "Standard 1,2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9284:3300": {
        "name": "F24 WThin Fe",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9244:4080": {
        "name": "M04 WRoot Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6020": {
        "name": "S01 WRoot CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9288:6020": {
        "name": "S01 WThin CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6040": {
        "name": "S02 WRoot CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9288:6040": {
        "name": "S02 WThin CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6060": {
        "name": "S03 WRoot CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9288:6060": {
        "name": "S03 WThin CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6080": {
        "name": "S04 WRoot CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9288:6080": {
        "name": "S04 WThin CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6180": {
        "name": "S12 WRoot CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "9248:61a0": {
        "name": "S13 WRoot CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "9248:61c0": {
        "name": "S14 WRoot CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "9248:62e0": {
        "name": "S23 WRoot CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6840": {
        "name": "S66 WRoot CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9248:6980": {
        "name": "S76 WRoot NiCr21Mo9Nb",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d084:5700": {
        "name": "R56 1-MIG Fe",
        "desc": "Standard 1,6mm Gasless",
        "rev": 0,
        "ver": "0"
    },
    "d084:5720": {
        "name": "R57 1-MIG Fe",
        "desc": "Standard 2mm Gasless",
        "rev": 0,
        "ver": "0"
    },
    "d084:5740": {
        "name": "R58 1-MIG Fe",
        "desc": "Standard 2,4mm Gasless",
        "rev": 0,
        "ver": "0"
    },
    "d394:2060": {
        "name": "C03 WiseThin+ CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3020": {
        "name": "F01 WiseRoot+ Fe",
        "desc": "Standard 0,8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:3020": {
        "name": "F01 WiseThin+ Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3040": {
        "name": "F02 WiseRoot+ Fe",
        "desc": "Standard 0,9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:3040": {
        "name": "F02 WiseThin+ Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3060": {
        "name": "F03 WiseRoot+ Fe",
        "desc": "Standard 1,0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:3060": {
        "name": "F03 WiseThin+ Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3080": {
        "name": "F04 WiseRoot+ Fe",
        "desc": "Standard 1,2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:3080": {
        "name": "F04 WiseThin+ Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:30e0": {
        "name": "F07 WiseRoot+ Fe",
        "desc": "Standard 1,14mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:32a0": {
        "name": "F21 WiseRoot+ Fe",
        "desc": "Standard 0,8mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:32a0": {
        "name": "F21 WiseThin+ Fe",
        "desc": "Standard 0.8mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:32c0": {
        "name": "F22 WiseRoot+ Fe",
        "desc": "Standard 0,9mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:32c0": {
        "name": "F22 WiseThin+ Fe",
        "desc": "Standard 0.9mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:32e0": {
        "name": "F23 WiseRoot+ Fe",
        "desc": "Standard 1,0mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:32e0": {
        "name": "F23 WiseThin+ Fe",
        "desc": "Standard 1.0mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3300": {
        "name": "F24 WiseRoot+ Fe",
        "desc": "Standard 1,2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d384:3300": {
        "name": "F24 WiseThin+ Fe",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:3360": {
        "name": "F27 WiseRoot+ Fe",
        "desc": "Standard 1,14mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:4080": {
        "name": "M04 WiseRoot+ Fe Metal",
        "desc": "Standard 1,2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c4:4300": {
        "name": "M24 WiseRoot+ Fe Metal",
        "desc": "Standard 1,2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6020": {
        "name": "S01 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 0,8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d388:6020": {
        "name": "S01 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6040": {
        "name": "S02 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 0,9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d388:6040": {
        "name": "S02 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6060": {
        "name": "S03 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 1,0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d388:6060": {
        "name": "S03 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6081": {
        "name": "S04 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 1,2mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "d388:6080": {
        "name": "S04 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6420": {
        "name": "S33 WiseRoot+ CrNi 23 12",
        "desc": "Dissimilar 1,0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6440": {
        "name": "S34 WiseRoot+ CrNi 23 12",
        "desc": "Dissimilar 1,2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6840": {
        "name": "S66 WiseRoot+ CrNiMo 22 9 3",
        "desc": "Standard 1,0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6860": {
        "name": "S67 WiseRoot+ CrNiMo 22 9 3",
        "desc": "Standard 1,2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6880": {
        "name": "S68 WiseRoot+ CrNiMo 25 9 4",
        "desc": "Standard 1,0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:68a0": {
        "name": "S69 WiseRoot+ CrNiMo 25 9 4",
        "desc": "Standard 1,2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:6980": {
        "name": "S76 WiseRoot+ NiCr21Mo9Nb",
        "desc": "Standard 1,0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "d2c8:69a0": {
        "name": "S77 WiseRoot+ NiCr21Mo9Nb",
        "desc": "Standard 1,2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:31c0": {
        "name": "F14 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1061": {
        "name": "A03 PMIG AlMg5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "1"
    },
    "908c:1160": {
        "name": "A11 1-MIG AlSi5",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1160": {
        "name": "A11 PMIG AlSi5",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:11a0": {
        "name": "A13 PMIG AlSi5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:12c0": {
        "name": "A22 PMIG Al",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:12e0": {
        "name": "A23 PMIG Al",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1420": {
        "name": "A33 PMIG AlMg5",
        "desc": "Standard 1.6mm Ar+50%He",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2020": {
        "name": "C01 PMIG CuSi3",
        "desc": "Standard 0.8mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9094:2160": {
        "name": "C11 1-MIG CuAl8",
        "desc": "Standard 0.8mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2160": {
        "name": "C11 PMIG CuAl8",
        "desc": "Standard 0.8mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9084:3020": {
        "name": "F01 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:3020": {
        "name": "F01 PMIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:30c1": {
        "name": "F06 1-MIG Fe",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c4:30c0": {
        "name": "F06 PMIG Fe",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3160": {
        "name": "F11 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:31a0": {
        "name": "F13 1-MIG Fe",
        "desc": "Standard 1.0mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:31a0": {
        "name": "F13 PMIG Fe",
        "desc": "Standard 1.0mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:31c0": {
        "name": "F14 PMIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:32a0": {
        "name": "F21 1-MIG Fe",
        "desc": "Standard 0.8mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3340": {
        "name": "F26 1-MIG Fe",
        "desc": "Standard 1.6mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:81c0": {
        "name": "L14 PMIG CrNiMo 22 9 3",
        "desc": "Cladding 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9084:4080": {
        "name": "M04 1-MIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:5060": {
        "name": "R03 1-MIG Fe Rutil",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:5080": {
        "name": "R04 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:50c0": {
        "name": "R06 1-MIG Fe Rutil",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:50c0": {
        "name": "R06 PMIG Fe Rutil",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:51c0": {
        "name": "R14 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:5340": {
        "name": "R26 1-MIG Fe Rutil",
        "desc": "Standard 1.6mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:67e0": {
        "name": "S63 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6800": {
        "name": "S64 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.2mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6840": {
        "name": "S66 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6860": {
        "name": "S67 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6880": {
        "name": "S68 PMIG CrNiMo 25 9 4",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:68a0": {
        "name": "S69 PMIG CrNiMo 25 9 4",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6a81": {
        "name": "S84 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "9088:6ae0": {
        "name": "S87 1-MIG MC-CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6ba0": {
        "name": "S93 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9084:55e0": {
        "name": "R47 1-MIG Fe",
        "desc": "Standard 2mm Gasless",
        "rev": 0,
        "ver": "0"
    },
    "90c8:94e1": {
        "name": "U39 PMIG CrNi 19 9",
        "desc": "Standard 1.2mm Ar+5%O2",
        "rev": 0,
        "ver": "1"
    },
    "908c:1000": {
        "name": "A00 1-MIG AlMg5",
        "desc": "Standard 0.8mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1000": {
        "name": "A00 PMIG AlMg5",
        "desc": "Standard 0.8mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "908c:1020": {
        "name": "A01 1-MIG AlMg5",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1020": {
        "name": "A01 PMIG AlMg5",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "908c:1040": {
        "name": "A02 1-MIG AlMg5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1041": {
        "name": "A02 PMIG AlMg5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "1"
    },
    "908c:1060": {
        "name": "A03 1-MIG AlMg5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:10c0": {
        "name": "A06 PMIG AlMg5",
        "desc": "Soft 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "908c:1180": {
        "name": "A12 1-MIG AlSi5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1180": {
        "name": "A12 PMIG AlSi5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "908c:11a0": {
        "name": "A13 1-MIG AlSi5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90cc:1400": {
        "name": "A32 PMIG AlMg5",
        "desc": "Standard 1.2mm Ar+50%He",
        "rev": 0,
        "ver": "0"
    },
    "9094:2060": {
        "name": "C03 1-MIG CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2060": {
        "name": "C03 PMIG CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2080": {
        "name": "C04 PMIG CuSi3",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9094:21a0": {
        "name": "C13 1-MIG CuAl8",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:21a0": {
        "name": "C13 PMIG CuAl8",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:21c0": {
        "name": "C14 PMIG CuAl8",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2301": {
        "name": "C24 PMIG CuSn",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "1"
    },
    "90d4:2340": {
        "name": "C26 PMIG CuSn",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:2440": {
        "name": "C34 PMig CuNi10Fe",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9040:3000": {
        "name": "F00 2-MIG",
        "desc": "General MIG/MAG",
        "rev": 0,
        "ver": "0"
    },
    "9084:3040": {
        "name": "F02 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:3040": {
        "name": "F02 PMIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3061": {
        "name": "F03 1-MIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c4:3060": {
        "name": "F03 PMIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3081": {
        "name": "F04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c4:3080": {
        "name": "F04 PMIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:30a0": {
        "name": "F05 1-MIG Fe",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:30a0": {
        "name": "F05 PMIG Fe",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3100": {
        "name": "F08 1-MIG Fe",
        "desc": "Rev 0  1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3120": {
        "name": "F09 1-MIG Fe",
        "desc": "Rev 0 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:3160": {
        "name": "F11 PMIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:3180": {
        "name": "F12 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:31e0": {
        "name": "F15 1-MIG Fe",
        "desc": "Standard 1.4mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:32c0": {
        "name": "F22 1-MIG Fe",
        "desc": "Standard 0.9mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:32e1": {
        "name": "F23 1-MIG Fe",
        "desc": "Standard 1.0mm CO2",
        "rev": 0,
        "ver": "1"
    },
    "9084:3301": {
        "name": "F24 1-MIG Fe",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "1"
    },
    "90e0:8081": {
        "name": "L04 PMIG Stelloy 6BC-G",
        "desc": "Cladding 1,2mm Ar",
        "rev": 0,
        "ver": "1"
    },
    "90e0:80c0": {
        "name": "L06 PMIG Stelloy 6BC-G",
        "desc": "Cladding 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90c4:4080": {
        "name": "M04 PMIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:40a0": {
        "name": "M05 1-MIG Fe Metal",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:40a0": {
        "name": "M05 PMIG Fe Metal",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:40c0": {
        "name": "M06 1-MIG Fe Metal",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:40c0": {
        "name": "M06 PMIG Fe Metal",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:41e0": {
        "name": "M15 1-MIG Fe Metal",
        "desc": "Standard 1.4mm Ar+8%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:4300": {
        "name": "M24 1-MIG Fe Metal",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:4320": {
        "name": "M25 1-MIG Fe Metal",
        "desc": "Standard 1.4mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:5080": {
        "name": "R04 PMIG Fe Rutil",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:50a0": {
        "name": "R05 1-MIG Fe Rutil",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:5580": {
        "name": "R44 1-MIG Fe Rutil",
        "desc": "Nittetsu SF-3A 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:56c0": {
        "name": "R54 1-MIG Fe Basic-",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:5800": {
        "name": "R64 PMIG Fe Basic",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:5940": {
        "name": "R74 PMIG Fe Basic",
        "desc": "Megafil 731B 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6020": {
        "name": "S01 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6020": {
        "name": "S01 PMIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6040": {
        "name": "S02 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6040": {
        "name": "S02 PMIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6061": {
        "name": "S03 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c8:6061": {
        "name": "S03 PMIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "9088:6080": {
        "name": "S04 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6081": {
        "name": "S04 PMIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "9088:60a0": {
        "name": "S05 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.6mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:60a0": {
        "name": "S05 PMIG CrNiMo 19 12",
        "desc": "Standard 1.6mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:60c1": {
        "name": "S06 PMIG CrNiMo 19 12",
        "desc": "Soft 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c8:60e0": {
        "name": "S07 PMIG CrNiMo 19 12",
        "desc": "Soft 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:62e0": {
        "name": "S23 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:62e0": {
        "name": "S23 PMIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6300": {
        "name": "S24 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6300": {
        "name": "S24 PMIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6340": {
        "name": "S26 PMIG CrNiMo 19 12",
        "desc": "Soft 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6560": {
        "name": "S43 PMIG CrNi 25 20",
        "desc": "Soft 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:65c0": {
        "name": "S46 1-MIG CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6680": {
        "name": "S52 PMIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:66a0": {
        "name": "S53 PMIG CrNi 21 10 N RE",
        "desc": "Soft 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90c8:66c0": {
        "name": "S54 PMig CrNiMo 27 31 4 Cu L",
        "desc": "Special 1.2mm Ar+30%He",
        "rev": 0,
        "ver": "0"
    },
    "90c8:66e1": {
        "name": "S55 PMig CrNiMo 27 31 4 Cu L",
        "desc": "Special 1,0mm Ar+30%He",
        "rev": 0,
        "ver": "1"
    },
    "9088:68c0": {
        "name": "S70 1-MIG FC-CrNiMo 25 9 4",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6920": {
        "name": "S73 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.0mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6940": {
        "name": "S74 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.2mm Ar+30%He+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6980": {
        "name": "S76 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:69a0": {
        "name": "S77 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:69c0": {
        "name": "S78 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90c8:69e0": {
        "name": "S79 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9088:6a00": {
        "name": "S80 1-MIG FC-NiCr21Mo9Nb",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6a40": {
        "name": "S82 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6a40": {
        "name": "S82 PMIG FC-CrNiMo 19 12",
        "desc": "Soft 0,9mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6a80": {
        "name": "S84 PMIG FC-CrNiMo 19 12",
        "desc": "Soft 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6aa0": {
        "name": "S85 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "0"
    },
    "9088:6ac1": {
        "name": "S86 1-MIG FC-CrNiMo 22 9 3",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c8:6be0": {
        "name": "S95 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.6mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6c00": {
        "name": "S96 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.6mm Ar+30%He",
        "rev": 0,
        "ver": "0"
    },
    "90c8:6c20": {
        "name": "S97 PMIG CrNiMo 19 12",
        "desc": "Cladding 1.6mm Ar+2%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:9060": {
        "name": "U03 1-MIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:9060": {
        "name": "U03 PMIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "9084:9081": {
        "name": "U04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c4:9081": {
        "name": "U04 PMIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "9094:91a0": {
        "name": "U13 1-Mig CuAl9Fe",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "90d4:91a0": {
        "name": "U13 PMig CuAl9Fe",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "9084:9444": {
        "name": "U34 1-MIG Fe Metal",
        "desc": "Nittetsu SM-3A 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "4"
    },
    "9084:9583": {
        "name": "U44 1-MIG Fe Rutil",
        "desc": "Nittetsu SF-3A 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "3"
    },
    "90cc:9b80": {
        "name": "U92 PMIG AlMg5",
        "desc": "Standard 1.2mm Ar+67%He",
        "rev": 0,
        "ver": "0"
    },
    "a08c:102a": {
        "name": "X8 A01 1-MIG AlMg5",
        "desc": "Standard 1mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:102a": {
        "name": "X8 A01 PMIG AlMg5",
        "desc": "Standard 1mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a08c:104a": {
        "name": "X8 A02 1-MIG AlMg5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:104b": {
        "name": "X8 A02 PMIG AlMg5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "11"
    },
    "a08c:106a": {
        "name": "X8 A03 1-MIG AlMg5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:106a": {
        "name": "X8 A03 PMIG AlMg5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:10ca": {
        "name": "X8 A06 PMIG AlMg5",
        "desc": "Soft 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:110a": {
        "name": "X8 A08 PMIG AlMg5",
        "desc": "Standard 1.6mm Ar+20%He",
        "rev": 0,
        "ver": "10"
    },
    "a08c:118a": {
        "name": "X8 A12 1-MIG AlSi5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:118b": {
        "name": "X8 A12 PMIG AlSi5",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "11"
    },
    "a08c:11aa": {
        "name": "X8 A13 1-MIG AlSi5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:11aa": {
        "name": "X8 A13 PMIG AlSi5",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:11ea": {
        "name": "X8 A15 PMIG AlSi5",
        "desc": "Standard 1.2mm Ar+50%He",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:136a": {
        "name": "X8 A27 PMIG Al",
        "desc": "Standard 1.2mm Ar+20%He",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:138a": {
        "name": "X8 A28 PMIG Al",
        "desc": "Standard 1.6mm Ar+20%He",
        "rev": 0,
        "ver": "10"
    },
    "a0cc:140a": {
        "name": "X8 A32 PMIG AlMg5",
        "desc": "Standard 1.2mm Ar+50%He",
        "rev": 0,
        "ver": "10"
    },
    "a094:206a": {
        "name": "X8 C03 1-MIG CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0d4:206a": {
        "name": "X8 C03 PMIG CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a394:206a": {
        "name": "X8 C03 WiseThin+ CuSi3",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0d4:21aa": {
        "name": "X8 C13 PMIG CuAl8",
        "desc": "Standard 1.0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0d4:230a": {
        "name": "X8 C24 PMIG CuSn",
        "desc": "Standard 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0d4:234a": {
        "name": "X8 C26 PMIG CuSn",
        "desc": "Standard 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a084:302a": {
        "name": "X8 F01 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:302a": {
        "name": "X8 F01 PMIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a384:302a": {
        "name": "X8 F01 WiseThin+ Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:304a": {
        "name": "X8 F02 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:304a": {
        "name": "X8 F02 PMIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:306a": {
        "name": "X8 F03 1-MIG Fe",
        "desc": "Standard 1mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:306a": {
        "name": "X8 F03 PMIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c4:306a": {
        "name": "X8 F03 WiseRoot+ Fe",
        "desc": "Standard 1mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a384:306a": {
        "name": "X8 F03 WiseThin+ Fe",
        "desc": "Standard 1mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:308a": {
        "name": "X8 F04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:308a": {
        "name": "X8 F04 PMIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c4:308a": {
        "name": "X8 F04 WiseRoot+ Fe",
        "desc": "Standard 1,2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a384:308a": {
        "name": "X8 F04 WiseThin+ Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:30aa": {
        "name": "X8 F05 1-MIG Fe",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:30ca": {
        "name": "X8 F06 1-MIG Fe",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:30ca": {
        "name": "X8 F06 PMIG Fe",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:316a": {
        "name": "X8 F11 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:316a": {
        "name": "X8 F11 PMIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:31aa": {
        "name": "X8 F13 1-MIG Fe",
        "desc": "Standard 1mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:31aa": {
        "name": "X8 F13 PMIG Fe",
        "desc": "Standard 1.0mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:31ca": {
        "name": "X8 F14 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:31ca": {
        "name": "X8 F14 PMIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:31ea": {
        "name": "X8 F15 1-MIG Fe",
        "desc": "Standard 1.4mm Ar+8%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:32ca": {
        "name": "X8 F22 1-MIG Fe",
        "desc": "Standard 0,9mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:32ea": {
        "name": "X8 F23 1-MIG Fe",
        "desc": "Standard 1mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c4:32ea": {
        "name": "X8 F23 WiseRoot+ Fe",
        "desc": "Standard 1mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a384:32ea": {
        "name": "X8 F23 WiseThin+ Fe",
        "desc": "Standard 1mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:330a": {
        "name": "X8 F24 1-MIG Fe",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c4:330a": {
        "name": "X8 F24 WiseRoot+ Fe",
        "desc": "Standard 1,2mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:706a": {
        "name": "X8 H03 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c8:706a": {
        "name": "X8 H03 WiseRoot+ CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a388:606a": {
        "name": "X8 S03 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:708a": {
        "name": "X8 H04 PMIG CrNiMo 22 9 3",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:738a": {
        "name": "X8 H28 PMIG CrNiMo 25 9 4",
        "desc": "Standard 1.0mm Ar+30%He+N2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:76aa": {
        "name": "X8 H53 PMIG CrNi 21 10 N RE",
        "desc": "Soft 1.0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:76ca": {
        "name": "X8 H54 PMIG CrNi 21 10 N RE",
        "desc": "Soft 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:77ea": {
        "name": "X8 H63 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:780a": {
        "name": "X8 H64 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.2mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:7a6a": {
        "name": "X8 H83 PMIG NiCr21Mo9Nb",
        "desc": "Standard 1.0mm Ar+30%He",
        "rev": 0,
        "ver": "10"
    },
    "a088:7aca": {
        "name": "X8 H86 1-MIG FC-CrNiMo 22 9 3",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:8bca": {
        "name": "X8 L94 PMIG NiCr21Mo9Nb",
        "desc": "Soft 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:8bea": {
        "name": "X8 L95 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.6mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "a084:408a": {
        "name": "X8 M04 1-MIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c4:408a": {
        "name": "X8 M04 PMIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c4:408a": {
        "name": "X8 M04 WiseRoot+ Fe Metal",
        "desc": "Standard 1,2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:40aa": {
        "name": "X8 M05 1-MIG Fe Metal",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:430a": {
        "name": "X8 M24 1-MIG Fe Metal",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:444a": {
        "name": "X8 M34 1-MIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:506a": {
        "name": "X8 R03 1-MIG Fe Rutil",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:508a": {
        "name": "X8 R04 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:51ca": {
        "name": "X8 R14 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:602a": {
        "name": "X8 S01 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:602a": {
        "name": "X8 S01 PMIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a388:602a": {
        "name": "X8 S01 WiseThin + CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:604a": {
        "name": "X8 S02 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:604a": {
        "name": "X8 S02 PMIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:606a": {
        "name": "X8 S03 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:606a": {
        "name": "X8 S03 PMIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c8:606a": {
        "name": "X8 S03 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 1mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:608a": {
        "name": "X8 S04 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:608a": {
        "name": "X8 S04 PMIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a2c8:608a": {
        "name": "X8 S04 WiseRoot+ CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a388:608a": {
        "name": "X8 S04 WiseThin+ CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:60ca": {
        "name": "X8 S06 PMIG CrNiMo 19 12",
        "desc": "Soft 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:60ea": {
        "name": "X8 S07 PMIG CrNiMo 19 12",
        "desc": "Soft 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:6a8a": {
        "name": "X8 S84 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:6b0a": {
        "name": "X8 S88 1-MIG MC-TS 410NiMo-M",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:f08a": {
        "name": "X8 t04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "9084:3062": {
        "name": "F03 1-MIG Fe",
        "desc": "Standard 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "2"
    },
    "9084:3082": {
        "name": "F04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "2"
    },
    "0:": {
        "name": "22 1-MIG Fe Rutil",
        "desc": "",
        "rev": 0,
        "ver": ""
    },
    "e084:3d01": {
        "name": "F104 1-MIG Fe",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e0c4:3ce1": {
        "name": "F103 PMIG Fe",
        "desc": "Automation 1.0mm Ar+18%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e0c4:3d02": {
        "name": "F104 PMIG Fe",
        "desc": "Automation 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "2"
    },
    "e0c8:6d41": {
        "name": "S106 PMIG CrNiMo 19 12",
        "desc": "Automation 1.0mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "e0c8:6d02": {
        "name": "S104 PMIG CrNiMo 19 12",
        "desc": "Automation 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "2"
    },
    "e0cc:1cc1": {
        "name": "A102 PMIG AlMg5",
        "desc": "Automation 1.2mm Ar",
        "rev": 0,
        "ver": "1"
    },
    "a0d4:22ea": {
        "name": "X8 C23 PMIG CuSn",
        "desc": "Standard 1,0mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "9084:9420": {
        "name": "U33 1-MIG Fe",
        "desc": "Standard 1.0mm Ar+3%CO2+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "90c4:9420": {
        "name": "U33 PMIG Fe",
        "desc": "Standard 1.0mm Ar+3%CO2+1%O2",
        "rev": 0,
        "ver": "0"
    },
    "a088:706a": {
        "name": "X8 H03 1-MIG CrNiMo 22 9 3",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:40ca": {
        "name": "X8 M06 1-MIG Fe Metal",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a088:60aa": {
        "name": "X8 S05 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.6mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:60aa": {
        "name": "X8 S05 PMIG CrNiMo 19 12",
        "desc": "Standard 1.6mm Ar+2%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:410a": {
        "name": "X8 M08 1-MIG Fe Metal-",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a0c8:756a": {
        "name": "X8 H43 PMIG CrNiMo 27 31 4 Cu L",
        "desc": "Standard 1.0mm Ar+50%He",
        "rev": 0,
        "ver": "10"
    },
    "a088:756a": {
        "name": "X8 H43 1-MIG CrNiMo 27 31 4 Cu L",
        "desc": "Standard 1.0mm Ar+50%He",
        "rev": 0,
        "ver": "10"
    },
    "8086:3022": {
        "name": "F01 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:3062": {
        "name": "F03 1-MIG Fe",
        "desc": "Standard 1mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:3082": {
        "name": "F04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:3162": {
        "name": "F11 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:31a2": {
        "name": "F13 1-MIG Fe",
        "desc": "Standard 1mm Ar+8%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:31c2": {
        "name": "F14 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:4082": {
        "name": "M04 1-MIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:5082": {
        "name": "R04 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "808a:6022": {
        "name": "S01 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 2,
        "ver": "2"
    },
    "808a:6062": {
        "name": "S03 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 2,
        "ver": "2"
    },
    "808a:6082": {
        "name": "S04 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 2,
        "ver": "2"
    },
    "808a:6a82": {
        "name": "S84 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:3042": {
        "name": "F02 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 2,
        "ver": "2"
    },
    "8086:3182": {
        "name": "F12 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+8%CO2",
        "rev": 2,
        "ver": "2"
    },
    "808a:6042": {
        "name": "S02 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 2,
        "ver": "2"
    },
    "90c8:91c1": {
        "name": "U14 PMIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 0,
        "ver": "1"
    },
    "90c8:6102": {
        "name": "S08 PMIG CrNiMo 19 12",
        "desc": "Soft 0.9mm Ar+2%CO2",
        "rev": 0,
        "ver": "2"
    },
    "808e:1042": {
        "name": "A02 1-MIG AlMg5",
        "desc": "Standard 1.2mm Ar",
        "rev": 1,
        "ver": "2"
    },
    "808e:1182": {
        "name": "A12 1-MIG AlSi5",
        "desc": "Standard 1.2mm Ar",
        "rev": 1,
        "ver": "2"
    },
    "a084:586a": {
        "name": "X8 R67 1-MIG Fe Rutil",
        "desc": "690-H 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:588a": {
        "name": "X8 R68 1-MIG Fe Rutil",
        "desc": "PZ6138 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "8086:40a0": {
        "name": "M05 1-MIG Fe Metal",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:50a0": {
        "name": "R05 1-MIG Fe Rutil",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "": {
        "name": "X5 S823 1-MIG FC-CrNiMo 19 12",
        "desc": "",
        "rev": 0,
        "ver": ""
    },
    "808a:6a42": {
        "name": "S82 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 3,
        "ver": "2"
    },
    "8086:4102": {
        "name": "M08 1-MIG Fe Metal-",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "2"
    },
    "8086:50c0": {
        "name": "R06 1-MIG Fe Rutil",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:30c0": {
        "name": "F06 1-MIG Fe",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "a084:58aa": {
        "name": "X8 R69 1-MIG Fe Rutil",
        "desc": "SF-80A 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:486a": {
        "name": "X8 M67 1-MIG Fe Metal",
        "desc": "SM-3A 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "a084:908a": {
        "name": "X8 U04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 0,
        "ver": "10"
    },
    "8086:40c0": {
        "name": "M06 1-MIG Fe Metal",
        "desc": "Standard 1.6mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8096:2060": {
        "name": "C03 1-MIG CuSi3",
        "desc": "Standard:Brazing 1.0mm Ar",
        "rev": 1,
        "ver": "0"
    },
    "8096:21a0": {
        "name": "C13 1-MIG CuAl8",
        "desc": "Standard: Brazing 1.0mm Ar",
        "rev": 1,
        "ver": "0"
    },
    "8096:2160": {
        "name": "C11 1-MIG CuAl8",
        "desc": "Standard:Brazing 0.8mm Ar",
        "rev": 1,
        "ver": "0"
    },
    "8086:51c0": {
        "name": "R14 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:5440": {
        "name": "R34 1-MIG Fe Rutil",
        "desc": "Coppered 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:4442": {
        "name": "M34 1-MIG Fe Metal",
        "desc": "Coppered 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "2"
    },
    "a0e0:808a": {
        "name": "X8 L04 PMIG Stelloy 6BC-G",
        "desc": "Cladding 1.2mm Ar",
        "rev": 0,
        "ver": "10"
    },
    "8096:2020": {
        "name": "C01 1-MIG CuSi3",
        "desc": "Standard:Brazing 0.8mm Ar",
        "rev": 1,
        "ver": "0"
    },
    "8086:5580": {
        "name": "R44 1-MIG Fe Rutil",
        "desc": "Nittetsu SF-3A 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:4580": {
        "name": "M44 1-MIG Fe Metal",
        "desc": "Nittetsu SM-3A 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:32e2": {
        "name": "F23 1-MIG Fe",
        "desc": "Standard 1mm CO2",
        "rev": 1,
        "ver": "2"
    },
    "8086:3302": {
        "name": "F24 1-MIG Fe",
        "desc": "Standard 1.2mm CO2",
        "rev": 1,
        "ver": "2"
    },
    "8086:30a0": {
        "name": "F05 1-MIG Fe",
        "desc": "Standard 1.4mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:3340": {
        "name": "F26 1-MIG Fe",
        "desc": "Standard 1.6mm CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:8bc0": {
        "name": "L94 1-MIG NiCr21Mo9Nb",
        "desc": "Cladding 1.2mm Ar",
        "rev": 1,
        "ver": "0"
    },
    "a088:6a4a": {
        "name": "S82 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 1,
        "ver": "10"
    },
    "a2c8:71aa": {
        "name": "H13 WRoot+ CrNiMo 25 9 4",
        "desc": "Standard 1.0mm Ar+30%He+2%CO2",
        "rev": 1,
        "ver": "10"
    },
    "90c8:9ba0": {
        "name": "U93 PMIG NiCr21Mo9Nb",
        "desc": "Cladding 1.14mm Ar",
        "rev": 0,
        "ver": "0"
    },
    "a094:202a": {
        "name": "C01 1-MIG CuSi3",
        "desc": "Standard 0.8mm Ar",
        "rev": 1,
        "ver": "10"
    },
    "a0d4:202a": {
        "name": "C01 PMIG CuSi3",
        "desc": "Standard 0.8mm Ar",
        "rev": 1,
        "ver": "10"
    },
    "808a:66a0": {
        "name": "S53 1-MIG CrNiMn 18 8 ",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 1,
        "ver": "0"
    },
    "a0cc:116a": {
        "name": "A11 PMIG AlSi5",
        "desc": "Standard 1mm Ar",
        "rev": 1,
        "ver": "10"
    },
    "8086:32a0": {
        "name": "F21 1-MIG Fe",
        "desc": "Standard 0.8mm CO2",
        "rev": 1,
        "ver": "0"
    },
    "d2c4:4440": {
        "name": "M34 WiseRoot+ Fe Metal",
        "desc": "Coppered 1,2mm Ar+18%CO2",
        "rev": 0,
        "ver": "0"
    },
    "a2c8:604a": {
        "name": "S02 WRoot+ CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 1,
        "ver": "10"
    },
    "a2c4:304a": {
        "name": "F02 WRoot+ Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 1,
        "ver": "10"
    },
    "8086:5081": {
        "name": "R04 1-MIG Fe Rutil",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:31c0": {
        "name": "F14 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+8%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:3061": {
        "name": "F03 1-MIG Fe",
        "desc": "Standard 1mm Ar+18%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:4081": {
        "name": "M04 1-MIG Fe Metal",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:3021": {
        "name": "F01 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+18%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:3160": {
        "name": "F11 1-MIG Fe",
        "desc": "Standard 0.8mm Ar+8%CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:6a80": {
        "name": "S84 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:6080": {
        "name": "S04 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.2mm Ar+2%CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:6020": {
        "name": "S01 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.8mm Ar+2%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:3040": {
        "name": "F02 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:6a40": {
        "name": "S82 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 1,
        "ver": "0"
    },
    "808a:6a41": {
        "name": "S82 1-MIG FC-CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+18%CO2",
        "rev": 2,
        "ver": "1"
    },
    "808a:6061": {
        "name": "S03 1-MIG CrNiMo 19 12",
        "desc": "Standard 1.0mm Ar+2%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:3081": {
        "name": "F04 1-MIG Fe",
        "desc": "Standard 1.2mm Ar+18%CO2",
        "rev": 1,
        "ver": "1"
    },
    "8086:3180": {
        "name": "F12 1-MIG Fe",
        "desc": "Standard 0.9mm Ar+8%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:31a0": {
        "name": "F13 1-MIG Fe",
        "desc": "Standard 1mm Ar+8%CO2",
        "rev": 1,
        "ver": "0"
    },
    "8086:32e1": {
        "name": "F23 1-MIG Fe",
        "desc": "Standard 1mm CO2",
        "rev": 1,
        "ver": "1"
    },
    "808a:6040": {
        "name": "S02 1-MIG CrNiMo 19 12",
        "desc": "Standard 0.9mm Ar+2%CO2",
        "rev": 1,
        "ver": "0"
    }
}