<div class="main">
    <div class="page-container full" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <h1>{{ 'landing.welcome_to_kemppi_machinery' | translate }}</h1>

        <div fxLayout="column">
            <div *ngIf="allowedModules" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                <button mat-button *ngIf="allowedModules.machines" routerLinkActive="active" routerLink="/machines" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">-</mat-icon>
                        </div>
                        <div>{{ 'menu.machines' | translate | uppercase }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules.parts" routerLinkActive="active" routerLink="/parts" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">O</mat-icon>
                        </div>
                        <div>{{ 'menu.parts' | translate | uppercase }}</div>
                    </div>
                </button>
            </div>
            <div *ngIf="allowedModules" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                <button mat-button *ngIf="allowedModules.customers" routerLinkActive="active" routerLink="/customers" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">Ź</mat-icon>
                        </div>
                        <div>{{ 'menu.customers' | translate | uppercase }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules.reports" routerLinkActive="active" routerLink="/dashboard" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">Ë</mat-icon>
                        </div>
                        <div>{{ 'menu.dashboard' | translate | uppercase }}</div>
                    </div>
                </button>
            </div>

            <div *ngIf="!allowedModules || (!allowedModules.machines && !allowedModules.customers && !allowedModules.parts && !allowedModules.reports)">
                {{ 'landing.no_permission_to_any_module' | translate }}
            </div>
        </div>

        <div *ngIf="allowedModules && allowedModules.machines" class="quick-search">
            <h2 class="mat-subhead">Open machine info</h2>
            <div class="" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="serialNumber" (keydown.enter)="openSerialInfo(serialNumber)">
                </mat-form-field>
                <div>
                    <button mat-raised-button color="accent" (click)="openSerialInfo(serialNumber)">{{ 'common.open' | translate }}</button>
                </div>
            </div>
        </div>

    </div>
    <div class="footer full" fxLayout="column">
        <div fxLayout="row">
            <div fxFlex></div>
            <div fxFlex="500px" class="footer-text">
                <h2>Kemppi Machinery</h2>
                <p>{{ 'landing.kemppi_machinery_description' | translate }}</p>
            </div>
            <div fxFlex></div>
            <div fxFlex="300px" class="footer-text">
                <h3>{{ 'landing.quick_links' | translate }}</h3>
                <h3><a href="https://kemppi.force.com/iam">My Kemppi portal</a></h3>
                <h3><a href="https://kemppi.force.com/support">Kemppi Support</a></h3>
            </div>
            <div fxFlex></div>
        </div>
        <div fxLayout="row" class="footer-text" fxLayoutAlign="center center">Copyright &copy; 2020 Kemppi Oy.</div>
    </div>

</div>
