import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { LoginService } from 'src/app/services/login.service';

/**
 * Select login page
 * Salesforce login / MyKemppi login
 */
@Component({
    selector: 'app-login-select-page',
    templateUrl: './login-select-page.component.html',
    styleUrls: ['./login-select-page.component.scss']
})
export class LoginSelectPageComponent implements OnInit {
    public loading: boolean;
    public loginError: any;
    private BACKENDURL: string;
    public publicLogin: boolean;

    public companyCodes: Array<string>;
    public roleTypes: Array<string>;

    constructor(
        private route: ActivatedRoute,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        public stateService: StateService,
        private http: HttpClient,
        private loginService: LoginService
    ) {
        this.loading = false;
        this.BACKENDURL = this.appConfigService.config.machineryBackendUrl;
    }

    ngOnInit() {
        setTimeout(async () => {
            this.stateService.state.hasMenu = false;
        });
    }

    loginWithSalesforce() {
        const kemppiIamPortal = this.appConfigService.config.kemppiIamUrl;
        const clientId = this.appConfigService.config.kemppiIamClientId;
        const redirectUri = this.appConfigService.config.frontendUrl + '/callback';
        window.location.replace(`${kemppiIamPortal}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`);
    }

    loginWithAuth0() {
        const auth0Url = `https://${this.appConfigService.config.oauth2Domain}`;
        const clientId = this.appConfigService.config.oauth2ClientId;
        const redirectUri = this.appConfigService.config.frontendUrl + '/callback';
        window.location.replace(`${auth0Url}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20profile%20email`);
    }

}
