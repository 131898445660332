<mat-form-field class="war-datepicker" [attr.readonly]="readonly">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput
        [(ngModel)]="value"
        [matDatepicker]="picker"
        (dateInput)="changeDate($event)"
        [ngClass]="{ 'warn': hint && hintclass === 'warn' }"
        [disabled]="innnerDisabled"
        [readonly]="true"
        [required]="required"
        (change)="change()"
        [matDatepicker]="picker" (focus)="!readonly ? picker.open() : null">
    <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="innnerDisabled || readonly"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint *ngIf="hint" [class]="hintclass">{{hint}}</mat-hint>
</mat-form-field>
