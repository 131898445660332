import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';

import { Company, Companies } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';

import * as _ from 'lodash';

@Component({
    selector: 'app-customers-page',
    templateUrl: './customers-page.component.html',
    styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit {

    state: any;

    /**
     * Loaded customer info
     */
    customerInfo: ICustomerInfo;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    /**
     * Search parameter partnerId
     */
    searchPartnerId: string;

    /**
     * Search parameter company
     */
    searchCompany: string;

    /**
     * Current search bar value
     */
    searchText = '';

    /**
     * Current user role
     */
    authUserRole: string;

    /**
     * Current user company
     */
    authUserCompany: string;

    /**
     * Current user scope
     * 'global' | 'company' | 'partner'
     */
    authUserScope = 'partner';

    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();
    loadingInfo: boolean;

    public _: any = _;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private customersService: CustomersService
    ) {
        this.companies = Companies;
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };
        this.searchPartnerId = '';
        this.searchCompany = '';
        this.searchPartner = '';
        this.loadingInfo = false;
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;

            this.authUserRole = this.authService.userProfile.role;
            this.authUserCompany = this.authService.userProfile.company;
            this.authUserScope = this.authService.userProfile.permissions.scope;

            if (this.authUserScope === 'global') {

            } else if (this.authUserScope === 'company') {
                this.searchCompany = this.authService.userProfile.company;
                this.setFilter('company', this.searchCompany);
            }
        });
    }

    setFilter(key, value) {
        if (value === '') {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
        }
    }
    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        console.log('removeFilter', key, this.searchParams);
        if (index !== -1) {
            console.log('removeFilter', key, index);
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
    }

    getCustomer() {
        this.loadingInfo = true;
        const params = [
            { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
        ];
        this.customersService.getCustomerInfo(this.searchCompany, this.searchPartnerId).subscribe((customerInfo) => {
            if (customerInfo) {
                this.customerInfo = customerInfo as ICustomerInfo;
                this.searchPartner = this.customerInfo.name;
                console.log('customerInfo', this.customerInfo);
            }
            this.loadingInfo = false;
        }, (error) => {
            console.log('Error, getCustomer');
            this.loadingInfo = false;
        });
    }

    searchCustomers() {
        if (this.searchPartner.length > 3) {
            this.loadingInfo = true;
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                console.log('searchCustomers');
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
                ];
                if (this.authUserScope === 'company') {
                    params.push({ key: 'company', value: this.authService.userProfile.company });
                }

                this.customersService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                    console.log('customersList', this.customersList);
                    this.loadingInfo = false;
                }, (error) => {
                    console.log('Error, searchCustomers');
                    this.loadingInfo = false;
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.searchPartnerId = customer.value.custId;
            this.searchCompany = customer.value.company;
            this.getCustomer();
        }
    }

    getDebugData() {
        // return this.machineRegistryService.getLatest(); // REMOVE
        return null;
    }
}
