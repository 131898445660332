import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/services/auth.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MachinesService } from 'src/app/services/machines.service';
import { StateService } from 'src/app/services/state.service';

import { Company, Companies } from 'src/app/models/company';
import { IMachineInfo } from 'src/app/models/IMachineInfo';

import * as _ from 'lodash';

@Component({
    selector: 'app-list-machines-page',
    templateUrl: './list-machines-page.component.html',
    styleUrls: ['./list-machines-page.component.scss']
})
export class ListMachinesPageComponent implements OnInit {

    state: any;

    /**
     * Machines
     */
    machines: Array<IMachineInfo> = [];

    /**
     * Claims count
     */
    resultHits: number;

    /**
     * Claims total count
     */
    totalHits: number;

    serialNumber: string;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    searchPartnerId: string;
    searchCompany: string;

    searchSerialNumber: string;
    searchProductId: string;
    searchOrderNumber: string;
    searchJobNumber: string;

    searchSize: number;
    searchFrom: number;
    searchType: string;

    sortParams: any = {
        key: 'version.modified.timestamp',
        direction: 'desc'
    };

    /**
     * Current search bar value
     */
    searchText = '';

    authUserScope = 'partner';
    authUserRole = '';

    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    showAdvancedFilters = true;

    dateFilterField: string;
    dateFilterStart: string;
    dateFilterEnd: string;

    machinesLoading: boolean;

    now: string;

    /**
     * All machine rows selected
     */
    allMachinesChecked: boolean;

    /**
     * Is any of machines selected
     */
    anyMachineSelected: boolean;

    /**
     * Collection of machines serial numbers that are checked
     */
    selectedMachines: object;

    showAdminTools = false;


    public _: any = _;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private machinesService: MachinesService,
        private customersService: CustomersService,
        private snackBar: MatSnackBar,
        private excelService: ExcelService
    ) {
        this.companies = Companies;
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };

        this.now = new Date().toISOString();

        this.searchPartnerId = '';
        this.searchCompany = '';
        this.searchSerialNumber = '';
        this.searchProductId = '';
        this.searchOrderNumber = '';
        this.searchJobNumber = '';
        this.searchPartner = '';
        this.dateFilterField = '';
        this.dateFilterStart = '';
        this.dateFilterEnd = '';

        this.searchSize = 50;
        this.searchFrom = 0;

        this.allMachinesChecked = false;
        this.anyMachineSelected = false;
        this.selectedMachines = {};
    }

    ngOnInit() {
        setTimeout(() => {
            const id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = id;

            this.authUserScope = this.authService.userProfile.permissions.scope;
            this.authUserRole = this.authService.userProfile.role;

            // Reload filters
            // Filters are stored to browser local storage and reloaded here.
            this.searchParams = this.searchParamsInStorage || [];
            this.reloadFilters();
            this.search();
        });

        this.searchType = 'serial';
    }

    reloadFilters() {
        if (this.authUserScope === 'global') {
            this.searchCompany = this.getSearchParamByKey('company');
        } else if (this.authUserScope === 'company') {
            this.searchCompany = this.authService.userProfile.company;
            this.setFilter('company', this.searchCompany);
        }
        this.searchPartnerId = this.getSearchParamByKey('partnerId');
        this.searchSerialNumber = this.getSearchParamByKey('id');
        this.searchProductId = this.getSearchParamByKey('productId');
        this.searchOrderNumber = this.getSearchParamByKey('orderNumber');
        this.searchJobNumber = this.getSearchParamByKey('jobNumber');

        // this.dateFilterField = this.getSearchParamByKey('dateFilterField');
        // Date range 2019-01-01T00:00:00.000Z#2019-01-01T23:59:59.999Z
        const dateFilter = this.getSearchParamObjByKey('manufactureDate')
            || this.getSearchParamObjByKey('deliveryDate')
            || this.getSearchParamObjByKey('partnerRegistrationDate')
            || this.getSearchParamObjByKey('salesDate')
            || this.getSearchParamObjByKey('endCustomerRegistrationDate')
            || this.getSearchParamObjByKey('warrantyExpireDate')
            || '';
        if (dateFilter && dateFilter !== '') {
            this.dateFilterField = dateFilter.key;
            const dateFilterRange = dateFilter ? dateFilter.value.split('#') : null;
            this.dateFilterStart = dateFilterRange ? dateFilterRange[0] : '';
            this.dateFilterEnd = dateFilterRange ? dateFilterRange[1] : '';
        }

        // Show advanced filters opened?
        if (this.searchSerialNumber !== '' || this.searchProductId !== '' || this.searchOrderNumber !== '' || this.searchJobNumber !== '' || this.dateFilterField !== '') {
            this.showAdvancedFilters = true;
        }
    }

    getSearchParamByKey(key) {
        const searchParamObj = _.find(this.searchParams, { key: key });
        return searchParamObj ? searchParamObj.value.replace(/[*]/g, '') : '';
    }

    getSearchParamObjByKey(key) {
        return _.find(this.searchParams, { key: key });
    }

    openSerialInfo(serialNumber) {
        this.router.navigate([`/machines/${serialNumber.trim()}`]);
    }

    searchMachines() {
        this.machinesLoading = true;
        console.log('search machines', this.searchParams, this.sortParams);
        this.machinesService.searchMachines(this.searchParams, [this.sortParams], this.searchSize, this.searchFrom).subscribe((response: any) => {
            if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                this.machines.push(...response.items);
                this.totalHits = response.totalCount;
            } else {
                this.machines = response;
            }
            this.resultHits = this.machines.length;

            this.machinesLoading = false;
            this.resetSelectedMachines();
        }, (error) => {
            console.log('Error, searchMachines');
            this.machinesLoading = false;
            this.resetSelectedMachines();
        });
    }

    loadMore() {
        this.searchFrom = this.searchFrom + this.searchSize;
        this.searchSize = 50;
        this.searchMachines();
    }

    search() {
        // Reset hits and start new searching from begining
        this.totalHits = 0;
        this.resultHits = 0;
        this.machines = [] as Array<IMachineInfo>;
        this.searchSize = 50;
        this.searchFrom = 0;

        // Partner id
        if (this.searchPartnerId === '') {
            this.removeFilter('partnerId');
        } else {
            this.searchPartnerId = this.searchPartnerId.toLocaleUpperCase();
            this.setFilter('partnerId', '*' + this.searchPartnerId + '*');
        }
        // Company
        this.setFilter('company', this.searchCompany);
        // Serial number
        if (this.searchSerialNumber === '') {
            this.removeFilter('id');
        } else {
            this.searchSerialNumber = this.searchSerialNumber.toLocaleUpperCase();
            this.setFilter('id', '*' + this.searchSerialNumber + '*');
        }
        // Product number
        if (this.searchProductId === '') {
            this.removeFilter('productId');
        } else {
            this.searchProductId = this.searchProductId.toLocaleUpperCase();
            this.setFilter('productId', '*' + this.searchProductId + '*');
        }
        // Order number
        if (this.searchOrderNumber === '') {
            this.removeFilter('orderNumber');
        } else {
            this.searchOrderNumber = this.searchOrderNumber.toLocaleUpperCase();
            this.setFilter('orderNumber', '*' + this.searchOrderNumber + '*');
        }
        // Job number
        if (this.searchJobNumber === '') {
            this.removeFilter('jobNumber');
        } else {
            this.searchJobNumber = this.searchJobNumber.toLocaleUpperCase();
            this.setFilter('jobNumber', '*' + this.searchJobNumber + '*');
        }

        this.removeFilter('manufactureDate');
        this.removeFilter('deliveryDate');
        this.removeFilter('partnerRegistrationDate');
        this.removeFilter('salesDate');
        this.removeFilter('endCustomerRegistrationDate');
        this.removeFilter('warrantyExpireDate');
        if (this.dateFilterField !== '' && this.dateFilterStart !== '' && this.dateFilterEnd !== '') {
            // Date range in format 2019-01-01T00:00:00.000Z#2019-01-02T23:59:59.999Z
            this.setFilter(this.dateFilterField, `${this.dateFilterStart}#${this.dateFilterEnd}`);
        }

        if (this.searchCompany !== '' && this.searchPartnerId !== '') {
            this.getCustomer(this.searchCompany, this.searchPartnerId);
        }

        this.searchMachines();
    }

    setFilter(key, value) {
        if (value === '') {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
            // this.searchMachines();
        }
        this.searchParamsInStorage = this.searchParams;
    }
    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        if (index !== -1) {
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
        this.searchParamsInStorage = this.searchParams;
    }

    resetSearchParams() {
        this.searchPartner = '';
        this.searchPartnerId = '';
        this.searchCompany = '';
        if (this.authUserScope === 'company') {
            this.searchCompany = this.authService.userProfile.company;
        }
        this.searchSerialNumber = '';
        this.searchProductId = '';
        this.searchOrderNumber = '';
        this.searchJobNumber = '';
        this.searchPartner = '';
        this.dateFilterField = '';
        this.dateFilterStart = '';
        this.dateFilterEnd = '';
        this.searchParams = [];
        this.searchParamsInStorage = [];
        this.search();
    }

    get searchParamsInStorage(): object {
        try {
            const paramsString = localStorage.getItem('machines_search_params');
            console.log('searchParamsInStorage', paramsString);
            return JSON.parse(paramsString);
        } catch (e) {
            return [];
        }
    }

    set searchParamsInStorage(searchParams: object) {
        localStorage.setItem('machines_search_params', JSON.stringify(searchParams));
    }

    sort(sortKey) {
        console.log('sort', sortKey);
        const currentSortObj = this.sortParams || null;
        let sortDirection = 'desc';
        if (currentSortObj && currentSortObj.key && currentSortObj.key === sortKey) {
            sortDirection = this.sortParams.direction === 'desc' ? 'asc' : 'desc';
        }
        this.sortParams = {
            key: sortKey,
            direction: sortDirection
        };
        this.search();
    }

    openMachine(serialNumber) {
        this.router.navigate(['machines', serialNumber]);
    }

    searchCustomers() {
        if (this.searchPartner.length > 3) {
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
                ];
                this.customersService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                }, (error) => {
                    console.log('Error, searchCustomers', error);
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.searchPartnerId = customer.value.custId;
            this.searchCompany = customer.value.company;
            this.search();
        }
    }

    getCustomer(company, partnerId) {
        this.customersService.getCustomerInfo(company, partnerId).subscribe((customerInfo: ICustomerInfo) => {
            if (customerInfo) {
                this.searchPartner = customerInfo.name;
            } else {
                this.searchPartner = '';
            }
        });
    }

    toggleAdvancedFilters() {
        console.log('toggleAdvancedFilters');
        this.showAdvancedFilters = !this.showAdvancedFilters;
    }

    // SELECTIONS
    /**
     * Reset selections (on search)
     */
    resetSelectedMachines() {
        this.selectedMachines = {};
        for (const machine of this.machines) {
            this.selectedMachines[machine.serialNumber] = false;
        }
        this.allMachinesChecked = false;
        this.checkSelectedMachines();
    }

    /**
     * Check or uncheck allMachinesChecked
     */
    selectAllMachines(checked) {
        _.map(this.machines, (machine) => {
            this.selectedMachines[machine.serialNumber] = checked;
        });
        this.checkSelectedMachines();
    }

    /**
     * Check is there any selected machines. (Show buttons)
     */
    checkSelectedMachines() {
        this.anyMachineSelected = _.some(_.values(this.selectedMachines), (value) => value === true);
    }

    /**
     * Call machine-info handler for serials.
     */
    recalculateSelectedMachines() {
        const serialNumbers = [];
        for (const serialNumber in this.selectedMachines) {
            if (this.selectedMachines.hasOwnProperty(serialNumber)) {
                if (this.selectedMachines[serialNumber] === true) {
                    serialNumbers.push(serialNumber);
                }
            }
        }
        // Use SQS queue!
        this.machinesService.postMachineInfoCalculationRequest(serialNumbers, true).subscribe((response) => {
            this.notify('Selected machines send to machine-info recalculation');
            this.resetSelectedMachines();
        });
    }

    exportSelectedMachinesToXLSX() {
        const data = this._getSelectedMachinesForExport();
        this.excelService.exportAsExcelFile(data, 'machines');
    }

    _getSelectedMachinesForExport() {
        const items = _.map(
            _.filter(this.machines, (machine) => {
                return this.selectedMachines[machine.serialNumber] ? true : false;
            }),
            (machine) => {
                return {
                    'Serial number': machine.serialNumber,
                    'Secure id': machine.secureId,
                    'Company': machine.company,
                    'Partner id': machine.partnerId,
                    'Partner name': machine.partnerName,
                    'Product number': machine.productId,
                    'Product name': _.get(machine, 'productInfo.partDescription'),
                    'Manufactured': machine.manufactureDate,
                    'Delivered': machine.deliveryDate,
                    'Registered (partner)': machine.partnerRegistrationDate,
                    'Registered (end customer)': machine.endCustomerRegistrationDate,
                    'Warranty expires': machine.warrantyExpireDate,
                    'Updated': _.get(machine, 'version.modified.timestamp')
                };

            }
        );
        return items;
    }

    /**
     * Toggle admin tools
     */
    toggleAdmin() {
        if (this.authUserRole === 'sys_admin') {
            this.showAdminTools = !this.showAdminTools;
        }
    }

    notify(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 2000
        });
    }

}
